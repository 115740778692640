import {create} from 'zustand';

export interface File {
    id: string
    createOn: Date
    imageSource: string
    isChecked: boolean
    isHovered: boolean
}

interface FilesState {
    files: File[],

}

interface FilesActions {
    setFiles: (files: File[]) => void
    resetFiles: () => void
    checkFile: (fileId: string, isChecked: boolean) => void
    hoverFile: (fileId: string, isHovered: boolean) => void
    restoreFiles: () => void
}

const useFilesStore = create<FilesState & FilesActions>(
    (set, get) => ({
        files: [],

        restoreFiles: () => {
            const currentFiles = useFilesStore.getState().files.map(
                (file: File) => {
                    const updatedFile: File = {
                        ...file,
                        isChecked: false
                    }
                    return updatedFile

                }
            )

            set(
                (state) => (
                    {
                        files: currentFiles
                    }
                )
            )
        },

        checkFile: (fileId: string, isChecked: boolean) => {
            const currentFiles = useFilesStore.getState().files;
            const updatedFiles = currentFiles.map(
                (file, index, array) => {
                    if (file.id === fileId) {
                        const newFile: File = {
                            id: file.id,
                            createOn: file.createOn,
                            isHovered: file.isHovered,
                            imageSource: file.imageSource,
                            isChecked: isChecked
                        }
                        return newFile
                    } else {
                        return file
                    }
                }
            );

            set(
                (state) => (
                    {
                        files: updatedFiles //.sort((a, b) => a.createOn.getDate() - b.createOn.getDate())
                    }
                )
            )
        },

        hoverFile: (fileId: string, isHovered: boolean) => {
            const currentFiles = useFilesStore.getState().files;
            const updatedFiles = currentFiles.map(
                (file, index, array) => {
                    if (file.id === fileId) {
                        const newFile: File = {
                            id: file.id,
                            createOn: file.createOn,
                            isHovered: isHovered,
                            imageSource: file.imageSource,
                            isChecked: file.isChecked
                        }
                        return newFile
                    } else {
                        return file
                    }
                }
            );


            set(
                (state) => (
                    {
                        files: updatedFiles
                    }
                )
            )
        },

        setFiles: (files: File[]) => {
            set(
                (state) => (
                    {
                        files: files//.sort((a, b) => a.createOn.getDate() - b.createOn.getDate())
                    }
                )
            )
        },

        resetFiles: () => {
            set(
                (state) => (
                    {
                        files: []
                    }
                )
            )
        }
    }),
)

export default useFilesStore;