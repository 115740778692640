import {Order} from "utils/types/primary/Order";

export function getOrderEstimate(order: Order) {
    const transactions = order.transactions
    const estimate = transactions.reduce(
        (sum, transaction) => {
            return sum + transaction.amount
        }, 0
    )
    return estimate
}