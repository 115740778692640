import {TSMap} from "typescript-map";
import {create} from 'zustand'

interface State {
    orders: string[]
}

interface Actions {
    setOrders: (orders: (string|null)[]) => void
    resetOrders: () => void
}

const useOrdersMap = create<State & Actions>(
    (set, get) => ({
        orders: [],

        setOrders: (orders: (string|null)[]) => {
            const uniqueOrders = new TSMap<string, string>();

            orders.forEach(el => {
                if (typeof el === 'string') {
                    uniqueOrders.set(el, el)
                }
            })

            set(
                (state) => (
                    {
                        orders: uniqueOrders.values(),
                    }
                )
            )
        },

        resetOrders: () => {
            set(
                (state) => (
                    {
                        orders: [],
                    }
                )
            )
        }
    })
)

export default useOrdersMap;