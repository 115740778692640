import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";

type Token = {
    token: string
}

export default async function POSTLoginCredentials(username: string, password: string) {
    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/auth/login`), {
            method: 'POST',
            body: JSON.stringify({username: username, password: password}),
            headers: {
                "content-type": "application/json",
            }
        })

        if (res.ok) {
            const data: Token = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}