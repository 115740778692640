import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import useTokenStore from "utils/zustand/useTokenStore";

const fetcher = async (url: string) => {
    const token = useTokenStore.getState().token;

    const res: Response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json"
        }
    })

    if (res.ok) {
        return res.json()
    } else {
        const definedError = await getDefinedError(res)
        throw definedError
    }
}
export default fetcher