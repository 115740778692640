import React from "react";
import { ApiHost } from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import { Order } from "utils/types/primary/Order";
import useTokenStore from "utils/zustand/useTokenStore";

export async function addImageOnClick(e: React.ChangeEvent<HTMLInputElement>, file: File, order: Order) {
    const token = useTokenStore.getState().token;

    const formData = new FormData();
    formData.append('files', file);


    try {
        const res: Response = await fetch(ApiHost + encodeURI(`api/v1/file/${order?.id}/`), {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            return 200
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}