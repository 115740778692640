import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {ScrollArea} from "cn/components/ui/scroll-area";
import {Sheet, SheetContent,} from "cn/components/ui/sheet";
import CategoryCreate from "cn/custom/sheet/sheetContent/category/categoryCreate";
import CategoryEdit from "cn/custom/sheet/sheetContent/category/categoryEdit";
import CustomerEdit from "cn/custom/sheet/sheetContent/customerEdit/customerEdit";
import EditDestinationAddress from "cn/custom/sheet/sheetContent/editOrder/address/editDestinationAddress";
import EditPickupAddress from "cn/custom/sheet/sheetContent/editOrder/address/editPickupAddress";
import ChangeOrderStatus from "cn/custom/sheet/sheetContent/editOrder/changeOrderStatus/changeOrderStatus";
import EditCompany from "cn/custom/sheet/sheetContent/editOrder/editCompany";
import EditDateAndTime from "cn/custom/sheet/sheetContent/editOrder/editDateAndTime";
import EditDriver from "cn/custom/sheet/sheetContent/editOrder/editDriver";
import InventoryCover from "cn/custom/sheet/sheetContent/editOrder/editInventory/inventoryCover";
import EditNote from "cn/custom/sheet/sheetContent/editOrder/editNote";
import EditServices from "cn/custom/sheet/sheetContent/editOrder/editServices";
import ItemCreate from "cn/custom/sheet/sheetContent/item/itemCreate";
import ItemEdit from "cn/custom/sheet/sheetContent/item/itemEdit";
import MemberCreate from "cn/custom/sheet/sheetContent/member/memberCreate";
import MemberEdit from "cn/custom/sheet/sheetContent/member/memberEdit";
import RateEdit from "cn/custom/sheet/sheetContent/rate/rateEdit";
import {X} from "lucide-react";
import {useEffect, useState} from "react";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {Category} from "utils/types/primary/Category";
import {Customer} from "utils/types/primary/Customer";
import {Item} from "utils/types/primary/Item";
import {Member} from "utils/types/primary/Member";
import {Order} from "utils/types/primary/Order";
import {Rate} from "utils/types/primary/Rate";
import useSheet, {ElementType} from "utils/zustand/useSheet";

export default function SheetWrapper() {

    const {isOpen, value, type, close, title, mutateSource} = useSheet();
    const [sheetContent, setSheetContent] = useState<ReactJSXElement>(<></>);

    useEffect(() => {
        switch (type) {
            case ElementType.rate: {
                if (isInstanceOf<Rate>(value, 'zipcode')){
                    setSheetContent(<RateEdit rate={value}/>)
                }
                break
            }

            case ElementType.category: {
                if (value === null) {
                    setSheetContent(<CategoryCreate/>)
                } else {
                    if (isInstanceOf<Category>(value, 'name')){
                        setSheetContent(<CategoryEdit category={value}/>)
                    }
                }
                break
            }

            case ElementType.item: {
                if (value === null) {
                    setSheetContent(<ItemCreate/>)
                } else {
                    if (isInstanceOf<Item>(value, 'name')){
                        setSheetContent(<ItemEdit item={value}/>)
                    }
                }
                break
            }

            case ElementType.member: {
                if (value === null) {
                    setSheetContent(<MemberCreate/>)
                } else {
                    if (isInstanceOf<Member>(value, 'companyId')){
                        setSheetContent(<MemberEdit member={value}/>)
                    }
                }
                break
            }

            case ElementType.customer: {
                    if (isInstanceOf<Customer>(value, 'firebaseId')){
                        setSheetContent(<CustomerEdit customer={value}/>)
                    }
                break
            }

            case ElementType.notes: {
                if (isInstanceOf<Order>(value, 'appointmentDate')){
                    setSheetContent(<EditNote order={value}/>)
                }
                break
            }

            case ElementType.PickupAddress: {
                if (isInstanceOf<Order>(value, 'appointmentDate')){
                    setSheetContent(<EditPickupAddress order={value}/>)
                }
                break
            }

            case ElementType.DestinationAddress: {
                if (isInstanceOf<Order>(value, 'appointmentDate')){
                    setSheetContent(<EditDestinationAddress order={value}/>)
                }
                break
            }

            case ElementType.Inventory: {
                if (isInstanceOf<Order>(value, 'appointmentDate')){
                    setSheetContent(<InventoryCover order={value}/>)
                }
                break
            }

            case ElementType.Services: {
                if (isInstanceOf<Order>(value, 'appointmentDate')){
                    setSheetContent(<EditServices order={value}/>)
                }
                break
            }

            case ElementType.DateAndTime: {
                if (isInstanceOf<Order>(value, 'appointmentDate')){
                    setSheetContent(<EditDateAndTime order={value}/>)
                }
                break
            }

            case ElementType.Company: {
                if (isInstanceOf<Order>(value, 'appointmentDate')){
                    setSheetContent(<EditCompany order={value}/>)
                }
                break
            }

            case ElementType.Driver: {
                if (isInstanceOf<Order>(value, 'appointmentDate')){
                    setSheetContent(<EditDriver order={value}/>)
                }
                break
            }

            case ElementType.orderDetailsChangeOrderStatus: {
                if (isInstanceOf<Order>(value, 'appointmentDate')){
                    setSheetContent(<ChangeOrderStatus order={value}/>)
                }
                break
            }
        }
    }, [value, type]);

    return <Sheet open={isOpen}>
        <SheetContent className={`py-0 px-0 ${type === ElementType.DateAndTime && 'w-fit'}`} side={'right'}>
            <section className={'px-4 py-4 flex justify-between place-items-center'}>
                <h1 className={'font-semibold'}>{title}</h1>
                <X className={'xDefault'} onClick={() => close()}/>
            </section>

            <ScrollArea className={'h-[calc(100dvh-4rem)] w-full pr-2'}>
                <div className={`pt-1 px-4 pb-4`}>{sheetContent}</div>
            </ScrollArea>
        </SheetContent>
    </Sheet>
}