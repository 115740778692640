import routes from "utils/config/routes";

export type MenuBar = {
    title: string
    route: string
    isActive: boolean
    isSecondary: boolean
}
const menuBar: MenuBar[] = [
    {title: 'Home', route: routes.statisticsBoard, isActive: false, isSecondary: false},
    {title: 'Orders', route: routes.orders, isActive: false, isSecondary: false},
    {title: 'Drafts', route: routes.drafts, isActive: false, isSecondary: true},
    {title: 'Quotes', route: routes.quotes, isActive: false, isSecondary: true},
    {title: 'Customers', route: routes.customers, isActive: false, isSecondary: false},
    {title: 'Applications', route: routes.applications, isActive: false, isSecondary: false},
    {title: 'Companies', route: routes.companies, isActive: false, isSecondary: false},
    {title: 'Members', route: routes.members, isActive: false, isSecondary: true},
    {title: 'Finances', route: routes.finances, isActive: false, isSecondary: false},
    {title: 'Refunds', route: routes.refunds, isActive: false, isSecondary: true},
    {title: 'Payouts', route: routes.payouts, isActive: false, isSecondary: true},
    {title: 'Inventory', route: routes.inventory, isActive: false, isSecondary: false},
    {title: 'Categories', route: routes.categories, isActive: false, isSecondary: true},
    {title: 'Presets', route: routes.presets, isActive: false, isSecondary: true},
    {title: 'Rates', route: routes.rates, isActive: false, isSecondary: false},
]

export default menuBar