import {Button} from "cn/components/ui/button";
import {Checkbox} from "cn/components/ui/checkbox";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "cn/components/ui/dropdown-menu";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import Pagination from "cn/custom/pagination";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import {ArrowDownWideNarrow, ArrowUpNarrowWide} from "lucide-react";
import CategoryStatusFilter from "pages/main/tables/inventory/categoryStatusFilter";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import getCategories from "utils/API/getCategories";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import getFixedTwoNumber from "utils/stringHandlers/getFixedTwoNumber";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {IData} from "utils/types/primary/IData";
import {Item} from "utils/types/primary/Item";
import useCategoriesMap from "utils/zustand/primary/useCategoriesMap";
import useItems from "utils/zustand/tables/useItems";
import useSheet, {ElementType} from "utils/zustand/useSheet";

export default function Inventory() {
    const navigate = useNavigate();

    const {
        itemsList,
        setItemsList,

        textFilter,

        pageIndex,
        setPageIndex,

        searchBar,
        setSearchBar,

        sort,
        setSort,

        pageSize,
        setPageSize,
        resetPagination,

        isAnySelected,
        checkAllRows,
        resetAllRows,
        currentSelectedRows,

        checkRow,
        isRowChecked
    } = useItems()

    const {
        data: itemsSource,
        isLoading,
        error,
        mutate: mutateItemsSource
    }

        = useSWR<IData<Item>>(ApiHost + encodeURI(`api/v1/item` +
        `?filter={${textFilter ? `${textFilter}, q:"${searchBar}"` : `q:"${searchBar}"`}}
        &sort=[${sort}]
        &range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]`),
        fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate('/'))
        }
    }, [error]);

    useEffect(() => {
        if (!error && itemsSource) {
                setItemsList(itemsSource.content);
        }
    }, [itemsSource]);

    const {categoriesMap, updateCategoriesMap} = useCategoriesMap()

    const {open} = useSheet();

    async function getCategoriesMap() {
        const res = await getCategories();
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate('/'))
        } else {
            updateCategoriesMap(res.content);
        }
    }

    function getCategoryName(item: Item) {
        if (item.categories.length > 0) {
            return <ul>
                {item.categories.map(el => <li key={el}>{categoriesMap.has(el) ? `${categoriesMap.get(el).name}` : ''}</li>)}
            </ul>

        } else return ''
    }

    useEffect(() => {
        getCategoriesMap();
    }, []);

    return <div className={'flex flex-col gap-4 h-full'}>
        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Items</h1>

        <section className={'flex-none text-sm flex justify-between place-items-center'}>
            <div className={'flex grow gap-4 place-items-center'}>
                <Input
                    className={"px-3 py-2 w-[17.25rem] h-8 relative bg-[#ffffff] rounded-lg border border-solid font-subtle font-[number:var(--subtle-font-weight)] text-[#717171] text-[length:var(--subtle-font-size)] tracking-[var(--subtle-letter-spacing)] leading-[var(--subtle-line-height)] whitespace-nowrap [font-style:var(--subtle-font-style)] caret-black"}
                    defaultValue={searchBar}
                    placeholder={`Search item...`}
                    onChange={
                        (event) => {
                            setSearchBar(event.currentTarget.value)
                        }
                    }/>

                <CategoryStatusFilter/>
            </div>
            <Button className={'flex-none'} size={'sm'} onClick={() => open(ElementType.item, null, 'Create item', mutateItemsSource)}>Add new item</Button>
        </section>

        <div className={'flex-1 grow'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-11">
                            <Checkbox checked={isAnySelected()}
                                      onCheckedChange={() => checkAllRows(itemsList)}/>
                        </TableHead>

                        <TableHead className={'tableCellHover w-60'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Name</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`name,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`name,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-60'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Description</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`description,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`description,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-44'}>Category</TableHead>

                        <TableHead className={'tableCellHover w-32'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Width (in.)</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`width,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`width,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-32'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Depth (in.)</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`length,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`length,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-32'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Height (in.)</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`height,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`height,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-32'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Volume (qu.ft)</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`volume,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`volume,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-32'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Weight (lb)</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`weight,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`weight,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>


                    </TableRow>
                </TableHeader>
                <TableBody className={`${isLoading || itemsSource?.content.length === 0 && 'h-[31.3rem]'}`}>
                    {!isLoading
                        ? itemsList.map((item) => (
                            <TableRow
                                key={item.id}
                                onClick={() => open(ElementType.item, item, 'Edit item', mutateItemsSource)}
                                className={'cursor-pointer'}
                            >
                                <TableCell>
                                    <Checkbox checked={isRowChecked(item)}
                                              onCheckedChange={() => checkRow(item)}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                              }}
                                    />
                                </TableCell>

                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{getCategoryName(item)}</TableCell>
                                <TableCell>{getFixedTwoNumber(item.width)}</TableCell>
                                <TableCell>{getFixedTwoNumber(item.length)}</TableCell>
                                <TableCell>{getFixedTwoNumber(item.height)}</TableCell>
                                <TableCell>{getFixedTwoNumber(item.volume)}</TableCell>
                                <TableCell>{getFixedTwoNumber(item.weight)}</TableCell>
                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(pageSize)} tableRowsCount={8}/>}
                </TableBody>
            </Table>
        </div>

        {!isLoading && <section className={'flex-none flex justify-between'}>
            <p className={'text-sm text-marcoGrayTextColor'}> {currentSelectedRows.length} of {itemsSource?.totalElements} selected</p>
            <Pagination data={itemsSource} pageSize={pageSize} pageIndex={pageIndex} setPageSize={setPageSize}
                        setPageIndex={setPageIndex}/>
        </section>}
    </div>
}