import {Button} from "cn/components/ui/button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "cn/components/ui/command";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "cn/components/ui/tabs";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import { produce } from "immer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import {TSMap} from "typescript-map";
import fetcher from "utils/API/fetcher";
import updateOrder from "utils/API/updateOrder";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Company} from "utils/types/primary/Company";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Member} from "utils/types/primary/Member";
import {Order} from "utils/types/primary/Order"
import useSheet from "utils/zustand/useSheet";

interface Props {
    order: Order
}

export default function EditDriver({order}: Props) {
    const navigate = useNavigate();
    const {mutateSource, close} = useSheet();

    const [isLoading, setLoading] = useState<boolean>(false);
    const {
        data: company,
        isLoading: companyLoading,
        mutate: mutateCompanySource,
        error: companySourceError,
    } = useSWR<Company>((order && order.carrierCompanyId) ? ApiHost + encodeURI(`api/v1/company/${order.carrierCompanyId}`) : null, fetcher);


    const [drivers, setDrivers] = useState<TSMap<string, Member>>(new TSMap<string, Member>())

    const [assignedDriver, setAssignedDriver] = useState<Member | null>(null)
    const [driverInput, setDriverInput] = useState<string>('')

    useEffect(() => {
        if (companySourceError) {
            handleResponseError(companySourceError, () => navigate('/'))
        }

        if (company) {
            const driversMap: TSMap<string, Member> = new TSMap<string, Member>()
            company.members.forEach(el => {
                driversMap.set(getString([
                    {element: el.firstName, tag: ElementTag.name},
                    {element: el.lastName, tag: ElementTag.name},
                ]), el)
            })

            setDrivers(driversMap)
        }


        if (drivers.has(driverInput)) {
            setAssignedDriver(drivers.get(driverInput))
        } else setAssignedDriver(null)
    }, [company, driverInput, companySourceError]);

    async function onSubmit() {
        if (assignedDriver) {
            setLoading(true);

            const updatedOrder = produce(
                order,
                (draft) => {
                    if (draft) {
                        draft.carrierId = assignedDriver.id
                        draft.invoices = []
                        draft.transactions = []
                    }
                }
            )

            const res = await updateOrder(updatedOrder);

            if (isInstanceOf<ErrorMessage>(res, 'message')){
                handleResponseError(res, () => navigate('/'))
            } else {
                toast({
                    description: 'Request fetched'
                })
            }

            await mutateSource();
            setLoading(false);
            close();
        }
    }

    return <section className={'flex flex-col gap-4 text-sm font-medium'}>
        <Tabs defaultValue="carrier" className="w-full">
            <TabsList className={'w-full flex truncate overflow-x-hidden'}>
                <TabsTrigger className={'grow'} disabled value="company">{company?.companyName}</TabsTrigger>
                <TabsTrigger className={'grow'} value="carrier">{assignedDriver ? getString([
                    {element: assignedDriver.firstName, tag: ElementTag.name},
                    {element: assignedDriver.lastName, tag: ElementTag.name},
                ]) : 'Assign driver'}</TabsTrigger>
            </TabsList>

            <TabsContent value="company"></TabsContent>

            <TabsContent value="carrier">
                <Command className="rounded-lg border shadow-md">
                    <CommandInput
                        value={driverInput}
                        onValueChange={(e) => {
                            setDriverInput(e)
                        }}
                        placeholder="Search company..."/>
                    <CommandList className={'h-[18rem]'}>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup heading="Suggestions">
                            {drivers.map(el =>
                                <CommandItem
                                    key={el.id}
                                >
                                    <div onClick={(e) => setDriverInput(getString([
                                        {element: el.firstName, tag: ElementTag.name},
                                        {element: el.lastName, tag: ElementTag.name},
                                    ]))}>
                                        {getString([
                                            {element: el.firstName, tag: ElementTag.name},
                                            {element: el.lastName, tag: ElementTag.name},
                                        ])}
                                    </div>
                                </CommandItem>)}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </TabsContent>
        </Tabs>

        <div
            className="items-start justify-between flex w-full gap-4">
            <Button
                className={'w-full'}
                disabled={isLoading}
                type={"reset"}
                variant={"outline"}
                onClick={() => close()}>
                Cancel
            </Button>
            <Button
                onClick={onSubmit}
                className={'w-full'}
                disabled={isLoading}
                variant={'green'}
            >{isLoading ? <ButtonLoading/> : 'Save'}</Button>
        </div>

    </section>
}