import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {CustomItem} from "utils/types/primary/CustomItem";
import { Order } from "utils/types/primary/Order";
import {OrderItem} from "utils/types/primary/OrderItem";
import useTokenStore from "utils/zustand/useTokenStore";

export default async function updateCustomItems(order: Order, customItems: (CustomItem[] | {name: string, length: number, width: number, height: number, count: number, weight: number, description: string})[] ) {
    const token = useTokenStore.getState().token

    try {
        const res: Response = await fetch(ApiHost + encodeURI(`api/v1/${order.id}/customItems`), {
            method: 'POST',
            body: JSON.stringify(customItems),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            return {res: 'ok'}
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}