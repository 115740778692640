import { toast } from "cn/components/ui/use-toast"
import { ErrorMessage } from "utils/types/primary/errorMessage"
import useTokenStore from "utils/zustand/useTokenStore"

export const handleResponseError = (errorMessage: ErrorMessage, unauthorisedAction?: () => void) => {
    toast({
        variant: 'destructive',
        title: 'Error',
        description: `${errorMessage.message}`
    })

    if (errorMessage.status === '401') {
        useTokenStore.getState().resetToken()
        if (unauthorisedAction) {
            unauthorisedAction()
        }
    }
}