import {Separator} from "cn/components/ui/separator";

export default function MemberOrdersSkeleton() {
    return <div className={'border rounded-lg w-full py-4 px-4 text-sm flex flex-col gap-4 text-marcoDefaultTextColor'}>

        <h2 className={'font-bold'}>Last orders placed</h2>


        <div className={'flex flex-col gap-4'}>
            <section className={'border rounded-lg flex flex-col'}>
                <div>
                    <div className={'flex  flex-col p-4 gap-2'}>
                        <div className={'flex justify-between'}>
                            <span className={'skeleton-header w-36'}/>
                            <span className={'skeleton-header w-32'}/>
                        </div>
                        <span className={'skeleton-text-sm w-20'}/>
                    </div>

                    <Separator/>

                    <div className={'flex justify-between p-4'}>
                        <span className={'skeleton-text-md w-56'}/>
                        <span className={'skeleton-text-md w-32'}/>
                    </div>
                </div>
            </section>

            <section className={'border rounded-lg flex flex-col'}>
                <div>
                    <div className={'flex  flex-col p-4 gap-2'}>
                        <div className={'flex justify-between'}>
                            <span className={'skeleton-header w-36'}/>
                            <span className={'skeleton-header w-32'}/>
                        </div>
                        <span className={'skeleton-text-sm w-20'}/>
                    </div>

                    <Separator/>

                    <div className={'flex justify-between p-4'}>
                        <span className={'skeleton-text-md w-56'}/>
                        <span className={'skeleton-text-md w-32'}/>
                    </div>
                </div>
            </section>


        </div>



    </div>
}