import paginationDefaultValues from "utils/config/paginationDefaultValues";
import {Order} from "utils/types/primary/Order";
import {create} from 'zustand'

interface State {
    orderList: Order[]

    sort: string

    searchBar: string

    pageSize: string
    pageIndex: number

    currentSelectedRows: Order[]
}

interface Actions {
    setOrderList: (orderList: Order[]) => void

    setSort: (value: string) => void

    setSearchBar: (value: string) => void

    setPageSize: (pageSize: string) => void
    setPageIndex: (pageIndex: number) => void
    resetPagination: () => void

    isAnySelected: () => boolean
    checkAllRows: (orders: Order[]) => void
    resetAllRows: () => void

    checkRow: (order: Order) => void
    isRowChecked: (order: Order) => boolean

    resetQuotes: () => void
}

const useQuotes = create<State & Actions>(
    (set, get) => ({
        orderList: [],

        sort: 'sId, DESC',

        searchBar: '',

        pageIndex: paginationDefaultValues.pageIndex,
        pageSize: paginationDefaultValues.pageSize,

        currentSelectedRows: [],

        resetQuotes: () => {
            set(
                (state) => (
                    {
                        orderList: [],

                        sort: 'sId, DESC',

                        searchBar: '',

                        pageIndex: paginationDefaultValues.pageIndex,
                        pageSize: paginationDefaultValues.pageSize,

                        currentSelectedRows: [],
                    }
                )
            )
        },

        resetAllRows: () => {
            set(
                (state) => (
                    {
                        currentSelectedRows: []
                    }
                )
            )
        },

        isAnySelected: () => {
            const currentSelected = useQuotes.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        isRowChecked: (order: Order) => {
            const currentSelected = useQuotes.getState().currentSelectedRows.filter(el => el.id === order.id);
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        checkAllRows: (orders: Order[]) => {
            const currentSelected = useQuotes.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                set(
                    (state) => (
                        {
                            currentSelectedRows: []
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, ...orders]
                        }
                    )
                )
            }
        },

        checkRow: (order: Order) => {
            const currentSelected = useQuotes.getState().currentSelectedRows.filter(el => el.id === order.id);

            if (currentSelected.length > 0) {
                const updatedArr = useQuotes.getState().currentSelectedRows.filter(el => el.id !== order.id)
                set(
                    (state) => (
                        {
                            currentSelectedRows: updatedArr
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, order]
                        }
                    )
                )
            }

            console.log(useQuotes.getState().currentSelectedRows)
        },

        setSort: (value: string) => {
            set(
                (state) => (
                    {
                        sort: value,
                    }
                )
            );
        },

        setOrderList: (orderList: Order[]) => {
            set(
                (state) => (
                    {
                        orderList: orderList,
                    }
                )
            );
        },

        setSearchBar: (value: string) => {
            set(
                (state) => (
                    {
                        searchBar: value
                    }
                )
            )
        },

        setPageSize: (pageSize: string) => {
            set(
                (state) => (
                    {pageSize: pageSize}
                )
            )
        },

        setPageIndex: (pageIndex: number) => {
            set(
                (state) => (
                    {pageIndex: pageIndex}
                )
            )
        },

        resetPagination: () => {
            set(
                (state) => (
                    {
                        pageSize: paginationDefaultValues.pageSize,
                        pageIndex: paginationDefaultValues.pageIndex
                    }
                )
            )
        }
    })
)

export default useQuotes;
