import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "cn/components/ui/alertDialog";
import {Button} from "cn/components/ui/button";
import {Separator} from "cn/components/ui/separator";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {produce} from "immer";
import {X} from "lucide-react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import updateOrder from "utils/API/updateOrder";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {Company} from "utils/types/primary/Company";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import useSheet, {ElementType} from "utils/zustand/useSheet";
import {ReactComponent as Copy} from "../../../../../icons/copy.svg";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";

interface Props {
    order: Order | undefined
    mutateOrder: () => {}
}

export default function OrderCompany({order, mutateOrder}: Props) {
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)

    const {
        data: companySource,
        isLoading: companyLoading,
        mutate: mutateCompanySource,
        error: companySourceError,
    } = useSWR<Company>((order && order.carrierCompanyId) ? ApiHost + encodeURI(`api/v1/company/${order.carrierCompanyId}`) : null, fetcher);

    useEffect(() => {
        if (companySourceError) {
            handleResponseError(companySourceError, () => navigate('/'))
        }

    }, [companySourceError]);

    const {open} = useSheet();

    async function onDelete() {
        if (order) {
            setLoading(true);

            const updatedOrder = produce(
                order,
                (draft) => {
                    if (draft) {
                        draft.carrierCompanyId = null
                        draft.carrierId = null
                        draft.invoices = []
                        draft.transactions = []
                    }
                }
            )

            const res = await updateOrder(updatedOrder);

            if (isInstanceOf<ErrorMessage>(res, 'message')){
                handleResponseError(res, () => navigate('/'))
            } else {
                toast({
                    description: 'Request fetched'
                })
            }

            mutateOrder();
            setLoading(false);
        }
    }


    return <div className={'flex flex-col gap-4'}>
        <section
            key={'order-company'}
            className={'border rounded-lg'}>
            <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
                <h3 className={'text-sm font-semibold '}>Company</h3>
                {companySource
                    ? <AlertDialog>
                        <AlertDialogTrigger asChild>
                            <X className={'xDefault h-6 text-marcoSecondaryErrorColor'}/>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This action cannot be undone. This will delete company and driver from order.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel disabled={isLoading}>Cancel</AlertDialogCancel>
                                <Button onClick={onDelete} disabled={isLoading} variant={'destructive'}>{isLoading ? <ButtonLoading/> : 'Continue'}</Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>

                    : <div
                        onClick={() => {
                            if (order) {
                                open(ElementType.Company, order, `Assign company and driver`, mutateOrder)
                            }
                        }}
                        className={'grid place-items-center xDefault'}>
                        <Dots/>
                    </div>
                }
            </div>

            {companySource && <>
                <Separator/>
                <div className={'p-2 flex flex-col text-sm'}>

                    <p className={'text-marcoLinks'}>{companySource.companyName}</p>

                    <h3 className={'text-sm font-semibold pt-6 pb-2'}>Contact information</h3>

                    <div className={'flex justify-between place-items-center pb-[0.375rem]'}>
                        <p className={'text-marcoLinks'}>{companySource.email}</p>
                        <div
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    `${companySource?.email}`
                                );
                                toast({
                                    description: 'Email copied to clipboard'
                                })
                            }}
                            className={'xDefault grid place-items-center'}>
                            <Copy/>
                        </div>
                    </div>

                    <p>{companySource.phone}</p>
                </div>
            </>}
        </section>
    </div>
}