import * as z from "zod";

const rateSchema = z.object({
    rate: z.coerce.number()
        .min(
            0,
            {message: "Required field"})
        .max(
            3,
            {message: "Rate value must not exceed 3 points"}),

    zipcode: z.coerce.string().min(5).max(5),
    id: z.string(),
    createOn: z.string(),
    county: z.string(),
    state: z.string(),
    homePrice: z.string()
})

export default rateSchema