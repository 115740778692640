export enum OrderStatus {

    open = 'OPEN',
    closed = 'CLOSED',


    new = "NEW",
    completed = "COMPLETED",
    in_progress = 'IN_PROGRESS',
    approved = 'APPROVED',
    draft = 'DRAFT',
    quote = "QUOTE",
    canceled = "CANCELED",
}