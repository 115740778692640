import {Button} from "cn/components/ui/button";
import {toast} from "cn/components/ui/use-toast";
import {ArrowLeft} from "lucide-react";
import MemberDetailsLoadingState from "cn/custom/skeletons/memberDetailsLoadingState";
import MemberOrders from "pages/main/details/memberDetails/memberOrders";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import stringToFormatDateYear from "utils/stringHandlers/stringToFormatDateYear";
import {Member} from "utils/types/primary/Member";
import useSheet, {ElementType} from "utils/zustand/useSheet";
import {ReactComponent as Dots} from "../../../../icons/threeDotsInLine.svg";
import {ReactComponent as Copy} from "../../../../icons/copy.svg";

export default function MemberDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const {open} = useSheet()

    const {
        data: memberSource,
        isLoading: memberLoading,
        mutate: mutateMemberSource,
        error: memberSourceError,
    } = useSWR<Member>(id ? ApiHost + encodeURI(`api/v1/member/${id}`) : null, fetcher);


    useEffect(() => {
        if (memberSourceError) {
            handleResponseError(memberSourceError, () => navigate('/'))
        }
    }, [memberSourceError]);

    if (!memberSource) {
        return <MemberDetailsLoadingState/>
    }

    return <div className={'middleWidth container text-marcoDefaultTextColor'}>
        <section>
            <div className={'flex justify-between'}>
                <div className={'flex flex-col gap-[0.375rem]'}>
                    <div
                        onClick={() => navigate(-1)}
                        className={'flex gap-2 place-items-center cursor-pointer text-marcoDefaultTextColor font-bold text-lg'}>
                        <ArrowLeft className={'h-5 text-marcoDefaultTextColor'}/>
                        <h1>{getString([
                            {element: memberSource.firstName, tag: ElementTag.name},
                            {element: memberSource.lastName, tag: ElementTag.name},
                        ])}</h1>
                    </div>
                    <p className={'ml-8 text-xs text-marcoGrayTextColor'}>{stringToFormatDateYear(memberSource.createOn)}</p>
                </div>
                <Button disabled>More actions</Button>
            </div>
        </section>

        <section className={'flex gap-5 pt-6'}>

            <div className={'leftSection'}>
                <MemberOrders/>
            </div>

            <div className={'flex-1 flex flex-col gap-4 text-marcoDefaultTextColor font-normal'}>
                <div className={'border rounded-lg w-full py-4 px-4 text-sm'}>
                    <div className={'flex justify-between place-items-center'}>
                        <p className={'font-bold'}>{formatEnumValueToString(memberSource.role)}</p>
                        <div onClick={() => {
                            open(ElementType.member, memberSource, 'Edit member', mutateMemberSource)
                        }} className={'xDefault grid place-items-center'}><Dots/></div>
                    </div>

                    <div className={'flex flex-col gap-4 pt-4'}>
                            <div className={'flex flex-col'}>

                                <p className={'text-marcoLinks'}>{getString([
                                    {element: memberSource.firstName, tag: ElementTag.name},
                                    {element: memberSource.lastName, tag: ElementTag.name},
                                ])}
                                </p>

                                <div className={'flex justify-between place-items-center pb-[0.375rem]'}>
                                    <p className={'text-marcoLinks'}>{memberSource.email}</p>

                                    <div
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `${memberSource.email}`
                                            );
                                            toast({
                                                description: 'Email copied to clipboard'
                                            })
                                        }}
                                        className={'xDefault grid place-items-center'}>
                                        <Copy/>
                                    </div>
                                </div>
                                <p>{memberSource.phone}</p>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}