import {create} from 'zustand'

interface State {
    progressValue: number
}

interface Actions {
    setProgressValue: (progressValue: number) => void
    resetProgressBar: () => void
}

const useProgressBar = create<State & Actions>(
    (set, get) => ({
        progressValue: 0,

        setProgressValue: (progressValue: number) => {
            set(
                (state) => (
                    {
                        progressValue: progressValue
                    }
                )
            )
        },

        resetProgressBar: () => {
            set(
                (state) => (
                    {
                        progressValue: 0
                    }
                )
            )
        },
    }),
)

export default useProgressBar;
