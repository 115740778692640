import menuBar, {MenuBar} from "utils/config/menuSections";
import {create} from 'zustand'

interface State {
    menuBar: MenuBar[]
}

interface Actions {
    checkCurrentURL: (currentURL: string) => void;
}

const useMenubar = create<State & Actions>(
    (set, get) => ({
        menuBar: menuBar,

        checkCurrentURL: (currentURL: string) => {
            const updatedBars = useMenubar.getState().menuBar.map(
                el => {
                    if (el.route === currentURL) {
                        const newBar: MenuBar = {
                            ...el,
                            isActive: true
                        }
                        return newBar
                    } else {
                        const resettedBar: MenuBar = {
                            ...el,
                            isActive: false
                        }
                        return resettedBar
                    }
                }
            )

            set(
                (state) => (
                    {
                        menuBar: updatedBars
                    }
                )
            )
        },

    }),
)

export default useMenubar;
