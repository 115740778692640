import {zodResolver} from "@hookform/resolvers/zod";
import {Button} from "cn/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "cn/components/ui/select";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import getCompany from "utils/API/getCompany";
import updateMember from "utils/API/updateMember";
import {MemberRole} from "utils/enum/memberRole";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import {Company} from "utils/types/primary/Company";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Member} from "utils/types/primary/Member";
import memberSchema from "utils/zodSchemas/memberSchema";
import useSheet from "utils/zustand/useSheet";
import * as z from "zod";

interface Props {
    member: Member
}

export default function MemberEdit({member}: Props) {
    const navigate = useNavigate();
    const form = useForm<z.infer<typeof memberSchema>>({
        resolver: zodResolver(memberSchema),
        defaultValues: {
            companyId: "",
            role: "",
            phone: "",
            lastName: "",
            firstName: "",
            email: ""
        }
    });

    const [companyName, setCompanyName] = useState<string>('');

    async function getCompanyName() {
        const res = await getCompany(member.companyId);
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate('/'))
        } else {
            return res.companyName
        }
    }

    useEffect(() => {
        form.setValue('role', member.role ?? 'DRIVER');
        form.setValue('phone', member.phone ?? '');
        form.setValue('lastName', member.lastName ?? '');
        form.setValue('firstName', member.firstName ?? '');
        form.setValue('email', member.email ?? '');
        form.setValue('companyId', member.companyId ?? '');

        getCompanyName()
            .then(value => {
                if (value) {
                    setCompanyName(value)
                }
            })
    }, [member]);

    const {mutateSource, close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);

    async function onSubmit(values: z.infer<typeof memberSchema>) {
        setLoading(true);

        const updatedMember = {
            ...values,
            id: member.id,
            createOn: member.createOn,
            lastUpdate: member.lastUpdate,
        }

        const res = await updateMember(updatedMember);

        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate('/'))
        } else {
            toast({
                description: 'Request fetched'
            })
        }
        await mutateSource();
        setLoading(false);
        close();
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={"flex flex-col gap-[16px]"}>
            <section className={"flex flex-col gap-4"}>
                <FormField
                    control={form.control}
                    name="firstName"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                First name
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"First name"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="lastName"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Last name
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Last name"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="email"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Email
                            </FormLabel>
                            <FormControl>
                                <Input
                                    disabled
                                    placeholder={"Email"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="phone"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Phone
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Phone"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <div className={'flex flex-col gap-1'}>
                    <p className={'text-sm font-medium text-marcoDefaultTextColor'}>Company</p>
                    <Input disabled value={companyName} placeholder={"Company"}/>
                </div>

                <FormField
                    control={form.control}
                    name="role"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Role</FormLabel>
                            <Select
                                onValueChange={field.onChange}
                                value={field.value}>
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue
                                            placeholder="Select role"/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    {Object.values(MemberRole).map(el => <SelectItem key={el}
                                                                                     value={el}>{formatEnumValueToString(el)}</SelectItem>)}
                                </SelectContent>
                            </Select>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </section>


            <div
                className="items-start justify-between flex w-full gap-4">
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    type={"reset"}
                    variant={"outline"}
                    onClick={() => close()}>
                    Cancel
                </Button>
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    variant={'green'}
                    type={"submit"}>{isLoading ? <ButtonLoading/> : 'Save'}</Button>
            </div>
        </form>
    </Form>
}