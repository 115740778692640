import {zodResolver} from "@hookform/resolvers/zod";
import {Button} from "cn/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import { Input } from "cn/components/ui/input";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {ArrowLeft} from "lucide-react";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import createCompany from "utils/API/createCompany";
import createMember, {UpdatedMember} from "utils/API/createMember";
import {MemberRole} from "utils/enum/memberRole";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {Company} from "utils/types/primary/Company";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import companySchema from "utils/zodSchemas/companySchema";
import useApplication from "utils/zustand/primary/useApplication";
import * as z from "zod";

export default function CreateCompany() {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false)
    const {application, resetApplicationStore} = useApplication()

    const form = useForm<z.infer<typeof companySchema>>({
        resolver: zodResolver(companySchema),
        defaultValues: {
            id: "",
            stripeAccount: "",
            members: [],
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            legalName: "",
            companyName: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            country: "",
            addressPhone: "",
            addressFirstName: "",
            addressLastName: "",
            createOn: "",
            lastUpdate: "",
            dot: "",
            fmsc: "",
            ein: "",
        }
    });

    async function onSubmit(values: z.infer<typeof companySchema>) {
        setLoading(true);

        const companyData: Company = {
            id: "",
            stripeAccount: "",
            members: [],
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.addressPhone,
            legalName: values.legalName,
            companyName: values.companyName,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            country: values.country,
            addressPhone: values.addressPhone,
            addressFirstName: values.addressFirstName,
            addressLastName: values.addressLastName,
            createOn: "",
            lastUpdate: "",
            dot: values.dot,
            fmsc: values.fmsc,
            ein: values.ein
        }

        const companyRes = await createCompany(companyData);

        if (isInstanceOf<ErrorMessage>(companyRes, 'message')) {
            handleResponseError(companyRes, () => navigate('/'))
        } else {

            const memberData: UpdatedMember = {
                role: MemberRole.owner,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                companyId: companyRes.id,
            }

            const memberRes = await createMember(memberData);

            if (isInstanceOf<ErrorMessage>(memberRes, 'message')) {
                handleResponseError(memberRes, () => navigate('/'))
            } else {
                toast({
                    description: 'Request fetched'
                })
            }
        }

        setLoading(false);
    }


    useEffect(() => {
        if (application) {
            form.setValue('firstName', application.firstName ?? '')
            form.setValue('lastName', application.lastName ?? '')
            form.setValue('email', application.email ?? '')
            form.setValue('companyName', application.companyName ?? '')
            form.setValue('phone', application.phone ?? '')
            form.setValue('dot', application.dot ?? '')
            form.setValue('ein', application.mc ?? '')
            resetApplicationStore()
        }
    }, []);

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={'middleWidth container text-marcoDefaultTextColor'}>
                <section className={'flex justify-between place-items-center'}>
                    <div
                        onClick={() => navigate(-1)}
                        className={'flex gap-2 place-items-center cursor-pointer text-marcoDefaultTextColor font-bold text-lg'}>
                        <ArrowLeft className={'h-5'}/>
                        <h1>New company</h1>
                    </div>

                    <div className={'flex gap-2'}>
                        <Button disabled={isLoading} type={'reset'} onClick={() => form.reset()}>Discard</Button>
                        <Button disabled={isLoading} type={'submit'}>{isLoading ? <ButtonLoading/> : 'Save'}</Button>
                    </div>
                </section>

                <section className={'flex flex-col gap-8 pt-[3rem]'}>

                    <div className={'flex'}>
                        <p className={'leftSmallSection flex-none text-sm font-semibold'}>Main contact</p>

                        <div className={'grow border rounded-lg py-4 px-4 flex flex-col gap-2'}>

                            <div className={'flex gap-2'}>

                                <FormField
                                    control={form.control}
                                    name="firstName"
                                    render={({field}) => (
                                        <FormItem className={'flex-1'}>
                                            <FormLabel>First name</FormLabel>
                                            <FormControl>
                                                <Input placeholder={"First name"}
                                                       {...field}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="lastName"
                                    render={({field}) => (
                                        <FormItem className={'flex-1'}>
                                            <FormLabel>Last name</FormLabel>
                                            <FormControl>
                                                <Input placeholder={"Last name"}
                                                       {...field}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <FormField
                                control={form.control}
                                name="email"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <FormLabel>Email</FormLabel>
                                        <FormControl>
                                            <Input placeholder={"Email"}
                                                   {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="phone"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <FormLabel>Phone</FormLabel>
                                        <FormControl>
                                            <Input placeholder={"Phone"}
                                                   {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <div>
                                <h2 className={'text-sm font-medium pb-2'}>Role</h2>
                                <Input placeholder={"Role"} defaultValue={'Owner'} disabled/>
                            </div>
                        </div>
                    </div>

                    <div className={'flex'}>
                        <div className={'w-[30%] flex-none'}>
                            <p className={'text-sm font-semibold'}>Company overview</p>
                            <p className={'text-xs text-marcoGrayTextColor pt-2'}>Company Company legal information</p>
                        </div>


                        <div className={'grow border rounded-lg py-4 px-4 flex flex-col gap-2'}>

                            <FormField
                                control={form.control}
                                name="legalName"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <FormLabel>Legal name</FormLabel>
                                        <FormControl>
                                            <Input placeholder={"Company legal name"}
                                                   {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="ein"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>EIN</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={"12-3456789"}
                                                pattern={"[0-9]{2}-[0-9]{7}"}
                                                {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <div className={'flex gap-2'}>

                                <FormField
                                    control={form.control}
                                    name="dot"
                                    render={({field}) => (
                                        <FormItem className={'flex-1'}>
                                            <FormLabel>DOT</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={"1234567"}
                                                    pattern={"[0-9]{7}"}
                                                    {...field}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="fmsc"
                                    render={({field}) => (
                                        <FormItem className={'flex-1'}>
                                            <FormLabel>FMSC</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={"12345"}
                                                    pattern={"[0-9]{5}"}
                                                    {...field}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'flex'}>
                        <div className={'w-[30%] flex-none'}>
                            <p className={'text-sm font-semibold'}>Address</p>
                            <p className={'text-xs text-marcoGrayTextColor pt-2'}>The primary company address</p>
                        </div>

                        <div className={'grow border rounded-lg py-4 px-4 flex flex-col gap-2'}>

                            <div className={'flex gap-2'}>

                                <FormField
                                    control={form.control}
                                    name="addressFirstName"
                                    render={({field}) => (
                                        <FormItem className={'flex-1'}>
                                            <FormLabel>
                                                First name
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={"First name"}
                                                    {...field}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="addressLastName"
                                    render={({field}) => (
                                        <FormItem className={'flex-1'}>
                                            <FormLabel
                                                className={"text-zinc-800 text-sm font-normal font-['Inter'] leading-tight"}>
                                                Last name
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={"Last name"}
                                                    {...field}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <FormField
                                control={form.control}
                                name="companyName"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>Company</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={"Company name"}
                                                {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="address1"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>Address</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={"Address"}
                                                {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="address2"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>
                                            Apartment, suite, etc.
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={"Apartment, suite, etc."}
                                                {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <div className={'flex gap-2'}>
                                <FormField
                                    control={form.control}
                                    name="city"
                                    render={({field}) => (
                                        <FormItem className={'flex-1'}>
                                            <FormLabel>City</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={"City"}
                                                    {...field}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="state"
                                    render={({field}) => (
                                        <FormItem className={'flex-1'}>
                                            <FormLabel>State</FormLabel>
                                            <FormControl>
                                                <Input placeholder={"State"}
                                                       {...field}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <FormField
                                control={form.control}
                                name="country"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>Country</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={"Country"}
                                                {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="addressPhone"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>Phone</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={"Phone"}
                                                {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </form>
    </Form>
}