import {ApiHost} from "utils/config/config";
import { Packing } from "utils/enum/packing";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {Order} from "utils/types/primary/Order";
import useTokenStore from "utils/zustand/useTokenStore";

export default async function submitPacking(order: Order) {
    const token = useTokenStore.getState().token;

    try {
        const res: Response = await fetch(ApiHost + encodeURI(`api/v1/service/packing`), {
            method: 'POST',
            body: JSON.stringify({
                orderId: order.id,
                onlyPrice: false,
                action: order.services.PACKING
            }),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            const data: Packing = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}