import {zodResolver} from "@hookform/resolvers/zod";
import {Button} from "cn/components/ui/button";
import {Toaster} from "cn/components/ui/toaster";
import {toast} from "cn/components/ui/use-toast";
import jwt from "jwt-decode";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import POSTLoginCredentials from "utils/API/POSTLoginCredentials";
import routes from "utils/config/routes";
import {checkToken} from "utils/errorHandler/tokenErrors/checkToken";
import {handleTokenExpiredError} from "utils/errorHandler/tokenErrors/handleTokenExpiredError";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Token} from "utils/types/suibsidiary/Token";
import loginCredentials from "utils/zodSchemas/loginCredentials";
import useTokenStore from "utils/zustand/useTokenStore";
import {ReactComponent as MarcoLogo} from "../../media/marcoLogo.svg";
import backgroundImg from "../../media/authBackground.jpg";
import {useForm} from "react-hook-form";
import * as z from "zod";
import {Form, FormControl, FormField, FormItem, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";

export default function Auth() {

    const navigate = useNavigate();

    const form = useForm<z.infer<typeof loginCredentials>>({
        resolver: zodResolver(loginCredentials),
        defaultValues: {
            username: "",
            password: ""
        }
    });

    useEffect(() => {
        checkToken()
            .then(isValid => {
                if (isValid) {
                    navigate(routes.statisticsBoard)
                }
            })
    }, []);

    async function onSubmit(values: z.infer<typeof loginCredentials>) {
        const res = await POSTLoginCredentials(values.username, values.password);

        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate('/'))
        } else {
            await useTokenStore.getState().setToken(res.token);
            navigate(routes.statisticsBoard)
        }
    }

    return <div className={'h-[100dvh] w-full grid'}>

        <section className={"flex flex-col w-[25%] justify-center place-self-center h-full mb-20"}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className={"flex flex-col gap-4"}>

                        <FormField
                            control={form.control}
                            name="username"
                            render={({field}) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            placeholder={"Email"}
                                            className={"w-full"}
                                            type={'text'}
                                            {...field}
                                            autoComplete="on"/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="password"
                            render={({field}) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            placeholder={"Password"}
                                            className={"w-full"}
                                            {...field}
                                            type={'password'}
                                            autoComplete="on"
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <Button className={"w-full"}
                                variant={"green"}
                                type={'submit'}
                        >Continue
                        </Button>
                    </div>
                </form>
            </Form>
            <Toaster/>
        </section>
    </div>
}