import * as z from "zod";

const customerSchema = z.object({
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    phone: z.string().min(2),
    emergencyPhone: z.string().min(2),
    emergencyFullName: z.string().min(2),
    email: z.string().email(),
})

export default customerSchema