import paginationDefaultValues from "utils/config/paginationDefaultValues";
import {Customer} from "utils/types/primary/Customer";

import {create} from 'zustand'

interface State {
    customerList: Customer[]

    sort: string

    searchBar: string

    pageSize: string
    pageIndex: number

    currentSelectedRows: Customer[]
}

interface Actions {
    setCustomerList: (customerList: Customer[]) => void

    setSort: (value: string) => void

    setSearchBar: (value: string) => void

    setPageSize: (pageSize: string) => void
    setPageIndex: (pageIndex: number) => void
    resetPagination: () => void

    isAnySelected: () => boolean
    checkAllRows: (customers: Customer[]) => void
    resetAllRows: () => void

    checkRow: (customer: Customer) => void
    isRowChecked: (customer: Customer) => boolean

    resetCustomers: () => void
}

const useCustomers = create<State & Actions>(
    (set, get) => ({
        customerList: [],

        sort: 'id, DESC',

        searchBar: '',

        pageIndex: paginationDefaultValues.pageIndex,
        pageSize: paginationDefaultValues.pageSize,

        currentSelectedRows: [],

        resetCustomers: () => {
            set(
                (state) => (
                    {
                        customerList: [],

                        sort: 'id, DESC',

                        searchBar: '',

                        pageIndex: paginationDefaultValues.pageIndex,
                        pageSize: paginationDefaultValues.pageSize,

                        currentSelectedRows: [],
                    }
                )
            )
        },

        resetAllRows: () => {
            set(
                (state) => (
                    {
                        currentSelectedRows: []
                    }
                )
            )
        },

        isAnySelected: () => {
            const currentSelected = useCustomers.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        isRowChecked: (customer: Customer) => {
            const currentSelected = useCustomers.getState().currentSelectedRows.filter(el => el.id === customer.id);
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        checkAllRows: (customers: Customer[]) => {
            const currentSelected = useCustomers.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                set(
                    (state) => (
                        {
                            currentSelectedRows: []
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, ...customers]
                        }
                    )
                )
            }
        },

        checkRow: (customer: Customer) => {
            const currentSelected = useCustomers.getState().currentSelectedRows.filter(el => el.id === customer.id);

            if (currentSelected.length > 0) {
                const updatedArr = useCustomers.getState().currentSelectedRows.filter(el => el.id !== customer.id)
                set(
                    (state) => (
                        {
                            currentSelectedRows: updatedArr
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, customer]
                        }
                    )
                )
            }
        },

        setSort: (value: string) => {
            set(
                (state) => (
                    {
                        sort: value,
                    }
                )
            );
        },

        setCustomerList: (customerList: Customer[]) => {
            set(
                (state) => (
                    {
                        customerList: customerList,
                    }
                )
            );
        },

        setSearchBar: (value: string) => {
            set(
                (state) => (
                    {
                        searchBar: value
                    }
                )
            )
        },

        setPageSize: (pageSize: string) => {
            set(
                (state) => (
                    {pageSize: pageSize}
                )
            )
        },

        setPageIndex: (pageIndex: number) => {
            set(
                (state) => (
                    {pageIndex: pageIndex}
                )
            )
        },

        resetPagination: () => {
            set(
                (state) => (
                    {
                        pageSize: paginationDefaultValues.pageSize,
                        pageIndex: paginationDefaultValues.pageIndex
                    }
                )
            )
        }
    })
)

export default useCustomers;
