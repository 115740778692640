import {create} from 'zustand'

interface State {
    width: number
    height: number
}

interface Actions {
    setDimensions: () => void
}

const useViewport = create<State & Actions>(
    (set, get) => ({
        width: window.innerWidth,
        height: window.innerHeight,

        setDimensions: () => {
            set(
                (state) => (
                    {
                        width: window.innerWidth,
                        height: window.innerHeight
                    }
                )
            )
        },
    })
)

export default useViewport;
