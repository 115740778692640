import { Button } from "cn/components/ui/button";
import { ArrowLeft } from "lucide-react";
import {useNavigate} from "react-router-dom";

export default function OrderSkeleton() {
    const navigate = useNavigate()

    return <div className={'middleWidth container text-marcoDefaultTextColor'}>

        <section>
            <div className={'flex justify-between'}>
                <div className={'flex flex-col gap-[0.375rem]'}>
                    <div className={'flex gap-2'}>
                        <div className={'flex flex-col gap-2'}>
                            <div
                                onClick={() => navigate(-1)}
                                className={'flex gap-2 place-items-center cursor-pointer text-marcoDefaultTextColor font-bold text-lg'}>
                                <ArrowLeft className={`h-5 text-marcoGrayTextColor opacity-20 animate-pulse`}/>
                                <h1 className={'skeleton-header w-60'}/>
                            </div>
                            <p className={'skeleton-text-sm ml-8 w-36'}/>
                        </div>
                        <p className={'skeleton-text-sm w-16 h-6 rounded-lg'}/>
                        <p className={'skeleton-text-sm w-14 h-6 rounded-lg'}/>
                    </div>
                </div>

                <div className={'flex justify-between gap-2'}>
                    <Button disabled >Refund</Button>
                    <Button disabled >More actions</Button>
                </div>
            </div>
        </section>

        <section className={'flex gap-5 pt-4'}>
            <div className={'leftSection'}>
                <div className={'flex flex-col gap-4'}>
                    <div className={'border rounded-lg w-full py-4 px-4 text-sm'}>
                        <div className={'flex flex-col gap-2'}>
                            <h2 className={'skeleton-header w-60'}/>
                            <p className={'skeleton-text-sm w-36'}/>
                        </div>
                    </div>

                    <div className={'border rounded-lg w-full py-4 px-4 text-sm flex flex-col gap-4'}>
                        <div className={'border rounded-lg flex flex-col gap-2 p-2'}>
                            <h2 className={'skeleton-header w-60'}/>
                            <p className={'skeleton-text-sm w-36'}/>
                            <p className={'skeleton-text-sm w-36'}/>
                            <p className={'skeleton-text-sm w-36'}/>
                        </div>

                        <div className={'border rounded-lg flex flex-col gap-2 p-2'}>
                            <h2 className={'skeleton-header w-60'}/>
                            <p className={'skeleton-text-sm w-72'}/>
                        </div>

                        <div className={'border rounded-lg flex flex-col gap-2 p-2'}>
                            <h2 className={'skeleton-header w-60'}/>
                            <p className={'skeleton-text-sm w-36'}/>
                            <p className={'skeleton-text-sm w-72'}/>
                        </div>

                        <div className={'border rounded-lg flex flex-col gap-2 p-2'}>
                            <h2 className={'skeleton-header w-60'}/>
                            <p className={'skeleton-text-sm w-36'}/>
                            <p className={'skeleton-text-sm w-56'}/>
                            <p className={'skeleton-text-sm w-24'}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'flex-1 flex flex-col gap-4'}>
                <div className={'border rounded-lg w-full py-4 px-4 text-sm'}>
                    <div className={'flex flex-col gap-2'}>
                        <h2 className={'skeleton-header w-60'}/>
                        <p className={'skeleton-text-sm w-36'}/>
                    </div>
                </div>

                <div className={'border rounded-lg w-full py-4 px-4 text-sm'}>
                    <div className={'flex flex-col gap-2'}>
                        <h2 className={'skeleton-header w-60'}/>
                        <p className={'skeleton-text-sm w-36'}/>
                    </div>

                    <div className={'flex flex-col gap-2 pt-4 pb-4'}>
                        <p className={'skeleton-text-sm w-56'}/>
                        <p className={'skeleton-text-sm w-36'}/>
                    </div>

                    <p className={'skeleton-text-sm w-40'}/>
                </div>

                <div className={'border rounded-lg w-full py-4 px-4 text-sm'}>
                    <div className={'flex flex-col gap-2'}>
                        <h2 className={'skeleton-header w-60'}/>
                        <p className={'skeleton-text-sm w-36'}/>
                    </div>

                    <div className={'flex flex-col gap-2 pt-4'}>
                        <p className={'skeleton-text-sm w-56'}/>
                        <p className={'skeleton-text-sm w-36'}/>
                    </div>

                    <div className={'flex flex-col gap-2 pt-8 pb-8'}>
                        <h2 className={'skeleton-header w-60'}/>
                        <p className={'skeleton-text-sm w-56'}/>
                        <p className={'skeleton-text-sm w-20'}/>
                    </div>
                </div>
            </div>
        </section>
    </div>
}