import {TSMap} from "typescript-map";
import orderStatusFilter from "utils/config/orderFilterItems/orderStatus";
import paymentStatusFilter from "utils/config/orderFilterItems/paymentStatus";
import paginationDefaultValues from "utils/config/paginationDefaultValues";
import {Order} from "utils/types/primary/Order";
import {FilterItem} from 'utils/types/suibsidiary/filterItem'
import {create} from 'zustand'

interface State {
    orderList: Order[]

    textFilter: string | null
    orderStatus: FilterItem[]
    paymentStatus: FilterItem[]

    sort: string

    searchBar: string

    pageSize: string
    pageIndex: number

    currentSelectedRows: Order[]
}

interface Actions {
    setOrderList: (orderList: Order[]) => void

    setSort: (value: string) => void

    setSearchBar: (value: string) => void
    updateTextFilter: () => void

    setPageSize: (pageSize: string) => void
    setPageIndex: (pageIndex: number) => void
    resetPagination: () => void

    checkOrderStatusValue: (orderStatus: FilterItem) => void
    clearOrderStatusFilter: () => void

    checkPaymentStatusValue: (orderStatus: FilterItem) => void
    clearPaymentStatusFilter: () => void

    isAnySelected: () => boolean
    checkAllRows: (orders: Order[]) => void
    resetAllRows: () => void

    checkRow: (order: Order) => void
    isRowChecked: (order: Order) => boolean

    resetOrders: () => void
}

const defaultOrderStatus: FilterItem[] = orderStatusFilter
    .sort(
        (aItem, bItem) => aItem.filterItemKey.localeCompare(bItem.filterItemKey))
    .map(item => {
        const updatedItem: FilterItem = {
            ...item,
            checked: item.checked
        }

        return updatedItem
    });

const defaultPaymentStatus: FilterItem[] = paymentStatusFilter.sort(
    (aItem, bItem) => aItem.filterItemKey.localeCompare(bItem.filterItemKey)
)

const defaultTextFilter = () => {
    const map = new TSMap<string, string>();
    const orderStatusFilter = defaultOrderStatus;
    let result: string = ""

    orderStatusFilter.forEach(
        (newFilterItem: FilterItem) => {

            if (newFilterItem.checked) {

                if (map.has(newFilterItem.filterItemKey)) {
                    const mapValue = map.get(newFilterItem.filterItemKey)
                    map.set(
                        newFilterItem.filterItemKey,
                        mapValue.replace("]", "") + ", " + newFilterItem.filterItemValue.replace("[", ""))

                } else {
                    map.set(newFilterItem.filterItemKey, newFilterItem.filterItemValue)
                }

            }
        }
    )

    const keys = map.keys();
    const val = map.values();

    for (let i = 0; i < map.length; i++) {
        if (i !== map.length - 1) {
            result = result + keys[i] + val[i] + ", "
        } else {
            result = result + keys[i] + val[i]
        }
    }

    return result
}

const useOrders = create<State & Actions>(
    (set, get) => ({
        orderList: [],

        textFilter: defaultTextFilter(),
        orderStatus: defaultOrderStatus,
        paymentStatus: defaultPaymentStatus,

        sort: 'sId, DESC',

        searchBar: '',

        pageIndex: paginationDefaultValues.pageIndex,
        pageSize: paginationDefaultValues.pageSize,

        currentSelectedRows: [],

        resetOrders: () => {
            set(
                (state) => (
                    {
                        orderList: [],

                        textFilter: defaultTextFilter(),
                        orderStatus: defaultOrderStatus,
                        paymentStatus: defaultPaymentStatus,

                        sort: 'sId, DESC',

                        searchBar: '',

                        pageIndex: paginationDefaultValues.pageIndex,
                        pageSize: paginationDefaultValues.pageSize,

                        currentSelectedRows: [],
                    }
                )
            )
        },

        resetAllRows: () => {
            set(
                (state) => (
                    {
                        currentSelectedRows: []
                    }
                )
            )
        },

        isAnySelected: () => {
            const currentSelected = useOrders.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        isRowChecked: (order: Order) => {
            const currentSelected = useOrders.getState().currentSelectedRows.filter(el => el.id === order.id);
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        checkAllRows: (orders: Order[]) => {
            const currentSelected = useOrders.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                set(
                    (state) => (
                        {
                            currentSelectedRows: []
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, ...orders]
                        }
                    )
                )
            }
        },

        checkRow: (order: Order) => {
            const currentSelected = useOrders.getState().currentSelectedRows.filter(el => el.id === order.id);

            if (currentSelected.length > 0) {
                const updatedArr = useOrders.getState().currentSelectedRows.filter(el => el.id !== order.id)
                set(
                    (state) => (
                        {
                            currentSelectedRows: updatedArr
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, order]
                        }
                    )
                )
            }

            console.log(useOrders.getState().currentSelectedRows)
        },

        checkPaymentStatusValue: (filterItem: FilterItem) => {
            const currentItems = useOrders.getState().paymentStatus;
            if (currentItems) {
                const updatedItems: FilterItem[] = []
                for (let i = 0; i < currentItems.length; i++) {
                    if (currentItems[i].filterItemValue === filterItem.filterItemValue) {
                        updatedItems.push(
                            {
                                ...filterItem,
                                checked: !filterItem.checked
                            }
                        )
                    } else updatedItems.push(currentItems[i])
                }

                set(
                    (state) => (
                        {
                            paymentStatus: updatedItems
                        }
                    )
                )
            }
        },

        clearPaymentStatusFilter: () => {
            const currentItems = useOrders.getState().paymentStatus;

            const updatedItems: FilterItem[] = []
            for (let i = 0; i < currentItems.length; i++) {
                updatedItems.push(
                    {
                        ...currentItems[i],
                        checked: false
                    }
                )
            }

            set(
                (state) => (
                    {
                        paymentStatus: updatedItems
                    }
                )
            )
        },

        checkOrderStatusValue: (filterItem: FilterItem) => {
            const currentItems = useOrders.getState().orderStatus;
            if (currentItems) {
                const updatedItems: FilterItem[] = []
                for (let i = 0; i < currentItems.length; i++) {
                    if (currentItems[i].filterItemValue === filterItem.filterItemValue) {
                        updatedItems.push(
                            {
                                ...filterItem,
                                checked: !filterItem.checked
                            }
                        )
                    } else updatedItems.push(currentItems[i])
                }

                set(
                    (state) => (
                        {
                            orderStatus: updatedItems
                        }
                    )
                )
            }
        },

        clearOrderStatusFilter: () => {
            const currentItems = useOrders.getState().orderStatus;

                const updatedItems: FilterItem[] = []
                for (let i = 0; i < currentItems.length; i++) {
                    updatedItems.push(
                        {
                            ...currentItems[i],
                            checked: false
                        }
                    )
                }

                set(
                    (state) => (
                        {
                            orderStatus: updatedItems
                        }
                    )
                )
        },

        setSort: (value: string) => {
            set(
                (state) => (
                    {
                        sort: value,
                    }
                )
            );
        },

        setOrderList: (orderList: Order[]) => {
            set(
                (state) => (
                    {
                        orderList: orderList,
                    }
                )
            );
        },

        setSearchBar: (value: string) => {
            set(
                (state) => (
                    {
                        searchBar: value
                    }
                )
            )
        },

        updateTextFilter: () => {
            const map = new TSMap<string, string>();
            const orderStatusFilter = useOrders.getState().orderStatus.slice();
            const orderPaymentFilter = useOrders.getState().paymentStatus.slice()
            let result: string = ""

            orderPaymentFilter.forEach(
                el => orderStatusFilter.push(el)
            )

            orderStatusFilter.forEach(
                (newFilterItem: FilterItem) => {

                    if (newFilterItem.checked) {

                        if (map.has(newFilterItem.filterItemKey)) {
                            const mapValue = map.get(newFilterItem.filterItemKey)
                            map.set(
                                newFilterItem.filterItemKey,
                                mapValue.replace("]", "") + ", " + newFilterItem.filterItemValue.replace("[", ""))

                        } else {
                            map.set(newFilterItem.filterItemKey, newFilterItem.filterItemValue)
                        }
                    }
                }
            )

            const keys = map.keys();
            const val = map.values();

            for (let i = 0; i < map.length; i++) {
                if (i !== map.length - 1) {
                    result = result + keys[i] + val[i] + ", "
                } else {
                    result = result + keys[i] + val[i]
                }
            }

            set(
                (state) => (
                    {
                        textFilter: result
                    }
                )
            );
        },

        setPageSize: (pageSize: string) => {
            set(
                (state) => (
                    {pageSize: pageSize}
                )
            )
        },

        setPageIndex: (pageIndex: number) => {
            set(
                (state) => (
                    {pageIndex: pageIndex}
                )
            )
        },

        resetPagination: () => {
            set(
                (state) => (
                    {
                        pageSize: paginationDefaultValues.pageSize,
                        pageIndex: paginationDefaultValues.pageIndex
                    }
                )
            )
        }
    })
)

export default useOrders;
