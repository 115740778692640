import {Badge} from "cn/components/ui/badge";
import {Category} from "utils/types/primary/Category";

interface Props {
    currentCategories: number[]
    setCurrentCategories: React.Dispatch<React.SetStateAction<number[]>>
    categoriesList: Category[]
}
export default function CategoryBadges({currentCategories, setCurrentCategories, categoriesList}: Props) {

    function getCategoryName (categoryId: number) {
        const category = categoriesList.filter(el => el.id === categoryId);
        if (category[0]) {
            return category[0].name
        } else return ''

    }

    return <div className={`grid-flow-row grid-cols-1 ${currentCategories.length > 0 ? '' : 'hidden'} border-b pl-2 py-2`}>
        {currentCategories.map(el =>
            <Badge variant={'outline'}
                   key={`cat+${el}`}
                   className={'mr-1 hover:bg-gray-100 w-fit h-fit cursor-pointer font-medium'}
            onClick={() => {
                const updatedCategories = currentCategories.filter(cat => cat !== el)
                setCurrentCategories(updatedCategories)
            }}>
                {getCategoryName(el)}
            </Badge>)}
    </div>
}