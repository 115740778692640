import {TSMap} from "typescript-map";
import {create} from 'zustand'

interface State {
    companies: string[]
}

interface Actions {
    setCompanies: (companies: (string|null)[]) => void
    resetCompanies: () => void
}

const useCompaniesMap = create<State & Actions>(
    (set, get) => ({
        companies: [],


        setCompanies: (companies: (string|null)[]) => {
            const uniqueCompanies = new TSMap<string, string>();

            companies.forEach(el => {
                if (typeof el === 'string') {
                    uniqueCompanies.set(el, el)
                }
            })

            set(
                (state) => (
                    {
                        companies: uniqueCompanies.values(),
                    }
                )
            )
        },

        resetCompanies: () => {
            set(
                (state) => (
                    {
                        companies: []
                    }
                )
            )
        }
    })
)

export default useCompaniesMap;