import {OrderStatus} from "utils/enum/orderStatus";
import {ReactComponent as GrayEmptyPip} from "../../../icons/pips/gray/emptyPip.svg";
import {ReactComponent as GrayFullPip} from "../../../icons/pips/gray/fullPip.svg";
import {ReactComponent as GrayHalfPip} from "../../../icons/pips/gray/halfPip.svg";
import {ReactComponent as GreenFullPip} from "../../../icons/pips/green/fullPip.svg";
import {ReactComponent as OrangeEmptyPip} from "../../../icons/pips/orange/emptyPip.svg";
import {ReactComponent as OrangeFullPip} from "../../../icons/pips/orange/fullPip.svg";
import {ReactComponent as OrangeHalfPip} from "../../../icons/pips/orange/halfPip.svg";

interface Props {
    status: OrderStatus | null
}

export default function OrderStatusBadge({status}: Props) {
    switch (status) {

        case OrderStatus.draft: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-zinc-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <GrayEmptyPip/>
                    <span>Draft</span>
                </div>
            )
            break
        }

        case OrderStatus.open: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight\""}>
                    <OrangeHalfPip/>
                    <span>Open</span>
                </div>
            )
            break
        }

        case OrderStatus.quote: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-zinc-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <GrayHalfPip/>
                    <span>Quote</span>
                </div>
            )
            break
        }

        case OrderStatus.canceled: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-zinc-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <GrayFullPip/>
                    <span>Canceled</span>
                </div>
            )
            break
        }

        case OrderStatus.new: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-orange-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeEmptyPip/>
                    <span>New</span>
                </div>
            )
            break
        }

        case OrderStatus.approved: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-orange-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeHalfPip/>
                    <span>Approved</span>
                </div>
            )
            break
        }

        case OrderStatus.in_progress: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-orange-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeFullPip/>
                    <span>In progress</span>
                </div>
            )
            break
        }

        case OrderStatus.completed: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-emerald-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <GreenFullPip/>
                    <span>Completed</span>
                </div>
            )
            break
        }

        default: {
            return null
            break
        }
    }

}