import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";

export async function getFileFromURL(URL: string) {
    try {
        const res = await fetch(URL, {
            headers: {}
        });

        if (res.ok) {
            const file = await res.blob();
            return file
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}