import {zodResolver} from "@hookform/resolvers/zod";
import {Button} from "cn/components/ui/button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "cn/components/ui/command";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {ScrollArea} from "cn/components/ui/scroll-area";
import {Textarea} from "cn/components/ui/textarea";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import CategoryBadges from "cn/custom/sheet/sheetContent/item/categoryBadges";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import getCategories from "utils/API/getCategories";
import updateItem from "utils/API/updateItem";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {Category} from "utils/types/primary/Category";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Item} from "utils/types/primary/Item";
import itemSchema from "utils/zodSchemas/itemSchema";
import useSheet from "utils/zustand/useSheet";
import * as z from "zod";

interface Props {
    item: Item
}

export default function ItemEdit({item}: Props) {
    const navigate = useNavigate();
    const {mutateSource, close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [currentCategories, setCurrentCategories] = useState<number[]>([])
    const [categoriesList, setCategoriesList] = useState<Category[]>([])


    const form = useForm<z.infer<typeof itemSchema>>({
        resolver: zodResolver(itemSchema),
        defaultValues: {
            name: "",
            categories: [],
            description: "",
            id: null,
            enable: false,
            height: 1,
            length: 1,
            volume: 1,
            weight: 1,
            width: 1,
        }
    });

    useEffect(() => {
        form.setValue("id", Number(item.id)  ?? '');
        form.setValue("name", item.name   ?? '');
        form.setValue("description", item.description   ?? '');
        form.setValue("categories", item.categories);
        form.setValue("volume", item.volume   ?? 1);
        form.setValue("weight", item.weight  ?? 1);
        form.setValue("enable", item.enable  ?? true);
        form.setValue("height", item.height  ?? 1);
        form.setValue("length", item.length  ?? 1);
        form.setValue("width", item.width  ?? 1);

        setCurrentCategories(item.categories);

        getCategories()
            .then(value => {
                if (isInstanceOf<ErrorMessage>(value, 'message')) {
                    handleResponseError(value, () => navigate('/'))
                } else {
                    setCategoriesList(value.content)
                }
            })
    }, [item]);


    useEffect(() => {
        form.setValue("categories", currentCategories);
    }, [currentCategories]);

    async function onSubmit(values: z.infer<typeof itemSchema>) {
        setLoading(true);

        const res = await updateItem(values);

        if (isInstanceOf<ErrorMessage>(res, 'message')){
            handleResponseError(res, () => navigate('/'))
        } else {
            await mutateSource();
            toast({
                description: 'Request fetched'
            })
        }

        setLoading(false);
        close();
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={"flex flex-col gap-4"}>
            <section className={"flex flex-col gap-4"}>

                <FormField
                    control={form.control}
                    name="name"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Item name
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Item name"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="description"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Description</FormLabel>
                            <FormControl>
                                <Textarea
                                    placeholder={"Item description"}
                                    className={"max-h-[16rem] caret-black"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="categories"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Category</FormLabel>
                            <FormControl>
                                <Command className="rounded-lg border shadow-md">
                                        <CategoryBadges
                                            categoriesList={categoriesList}
                                            currentCategories={currentCategories}
                                            setCurrentCategories={setCurrentCategories}/>
                                    <CommandInput placeholder="Select category..."/>
                                    <CommandList>
                                        <CommandEmpty>No results found.</CommandEmpty>
                                        <CommandGroup heading="Categories">
                                            <ScrollArea className={'h-[8rem] pr-4'}>
                                                {categoriesList.map(category =>
                                                    <CommandItem key={category.id}>
                                                        <span className={'cursor-pointer w-full'}
                                                              onClick={(e) => {
                                                                  e.stopPropagation();
                                                                  e.preventDefault();
                                                                  const isSelected = currentCategories.filter(el => el === category.id);
                                                                  if (isSelected.length > 0) {
                                                                      toast({
                                                                          variant: 'destructive',
                                                                          description: 'This category has already been added'
                                                                      })
                                                                  } else setCurrentCategories(prevState => [...prevState, category.id])
                                                              }}>{category.name}</span>
                                                    </CommandItem>)}
                                            </ScrollArea>
                                        </CommandGroup>
                                    </CommandList>
                                </Command>

                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <div className="flex items-start gap-[175px] relative">
                    <div className="inline-flex flex-col items-start relative self-stretch flex-[0_0_auto]">
                        <div
                            className="mt-5 relative w-fit [font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#303030] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
                            Dimensions
                        </div>
                    </div>
                </div>

                <div
                    className="items-end gap-[16px] flex relative self-stretch w-full flex-[0_0_auto]">

                    <FormField
                        control={form.control}
                        name="width"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Width (in.)</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={"1"}
                                        min={0}
                                        step={1}
                                        type={"number"}
                                        {...field}
                                        onChange={event => {
                                            const currentVolume = Math.ceil((form.getValues().length * form.getValues().height * Number(event.currentTarget.value)) / 1728)
                                            form.setValue("width", Number(event.currentTarget.value));
                                            form.setValue("volume", currentVolume);
                                            form.setValue("weight", Math.ceil(currentVolume * 6))
                                        }}/>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="length"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Depth (in.)</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={"1"}
                                        min={0}
                                        step={1}
                                        type={"number"}
                                        {...field}
                                        onChange={event => {
                                            const currentVolume = Math.ceil((form.getValues().width * form.getValues().height * Number(event.currentTarget.value)) / 1728)
                                            form.setValue("length", Number(event.currentTarget.value));
                                            form.setValue("volume", currentVolume);
                                            form.setValue("weight", Math.ceil(currentVolume * 6))
                                        }}/>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="height"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Height (in.)</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={"1"}
                                        min={0}
                                        step={1}
                                        type={"number"}
                                        {...field}
                                        onChange={event => {
                                            const currentVolume = Math.ceil((form.getValues().width * form.getValues().length * Number(event.currentTarget.value)) / 1728)
                                            form.setValue("height", Number(event.currentTarget.value));
                                            form.setValue("volume", currentVolume);
                                            form.setValue("weight", Math.ceil(currentVolume * 6))
                                        }}/>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                </div>

                <div
                    className="items-end gap-[16px] flex relative self-stretch w-full flex-[0_0_auto]">

                    <FormField
                        control={form.control}
                        name="volume"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Volume (cu. ft)</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={"1"}
                                        min={0}
                                        step={1}
                                        type={"number"}
                                        {...field}
                                        onChange={event => {
                                            form.setValue("volume", Number(event.currentTarget.value));
                                            form.setValue("weight", Math.ceil(Number(event.currentTarget.value) * 6))
                                        }
                                        }/>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="weight"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Weight (lb)</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={"1"}
                                        min={0}
                                        step={0.01}
                                        type={"number"}
                                        {...field}/>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                </div>
            </section>


            <div
                className="items-start justify-between flex w-full gap-4">
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    type={"reset"}
                    variant={"outline"}
                    onClick={() => close()}>
                    Cancel
                </Button>
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    variant={'green'}
                    type={"submit"}>{isLoading ? <ButtonLoading/> : 'Save'}</Button>
            </div>
        </form>
    </Form>
}