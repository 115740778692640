import {TSMap} from "typescript-map";
import paginationDefaultValues from "utils/config/paginationDefaultValues";
import ratesFilterValues from "utils/config/ratesFilterValues/ratesFilterValues";
import {Rate} from "utils/types/primary/Rate";
import {FilterItem} from "utils/types/suibsidiary/filterItem";
import {create} from 'zustand'

interface State {
    ratesList: Rate[]

    textFilter: string | null
    ratesFilter: FilterItem[]

    sort: string

    searchBar: string

    pageSize: string
    pageIndex: number

    currentSelectedRows: Rate[]
}

interface Actions {
    setRatesList: (ratesList: Rate[]) => void

    setSort: (value: string) => void

    setSearchBar: (value: string) => void
    updateTextFilter: () => void

    setPageSize: (pageSize: string) => void
    setPageIndex: (pageIndex: number) => void
    resetPagination: () => void

    checkFilterValue: (filterItem: FilterItem) => void
    clearFilter: () => void

    isAnySelected: () => boolean
    checkAllRows: (items: Rate[]) => void
    resetAllRows: () => void

    checkRow: (rate: Rate) => void
    isRowChecked: (rate: Rate) => boolean

    setFilter: (filterItems: FilterItem[]) => void

    resetRates: () => void
}

const defaultTextFilter = () => {
    const map = new TSMap<string, string>();
    const orderStatusFilter: FilterItem[] = ratesFilterValues;
    let result: string = ""

    orderStatusFilter.forEach(
        (newFilterItem: FilterItem) => {

            if (newFilterItem.checked) {

                if (map.has(newFilterItem.filterItemKey)) {
                    const mapValue = map.get(newFilterItem.filterItemKey)
                    map.set(
                        newFilterItem.filterItemKey,
                        mapValue.replace("]", "") + ", " + newFilterItem.filterItemValue.replace("[", ""))

                } else {
                    map.set(newFilterItem.filterItemKey, newFilterItem.filterItemValue)
                }

            }
        }
    )

    const keys = map.keys();
    const val = map.values();

    for (let i = 0; i < map.length; i++) {
        if (i !== map.length - 1) {
            result = result + keys[i] + val[i] + ", "
        } else {
            result = result + keys[i] + val[i]
        }
    }

    return result
}

const useItems = create<State & Actions>(
    (set, get) => ({
        ratesList: [],

        textFilter: defaultTextFilter(),
        ratesFilter: ratesFilterValues,

        sort: 'state, ASC',

        searchBar: '',

        pageIndex: paginationDefaultValues.pageIndex,
        pageSize: paginationDefaultValues.pageSize,

        currentSelectedRows: [],

        resetRates: () => {
            set(
                (state) => (
                    {
                        ratesList: [],

                        textFilter: defaultTextFilter(),
                        ratesFilter: ratesFilterValues,

                        sort: 'state, ASC',

                        searchBar: '',

                        pageIndex: paginationDefaultValues.pageIndex,
                        pageSize: paginationDefaultValues.pageSize,

                        currentSelectedRows: [],
                    }
                )
            )
        },

        setFilter: (filterItems: FilterItem[]) => {
            set(
                (state) => (
                    {
                        ratesFilter: filterItems
                    }
                )
            )
        },

        resetAllRows: () => {
            set(
                (state) => (
                    {
                        currentSelectedRows: []
                    }
                )
            )
        },

        isAnySelected: () => {
            const currentSelected = useItems.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        isRowChecked: (rate: Rate) => {
            const currentSelected = useItems.getState().currentSelectedRows.filter(el => el.id === rate.id);
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        checkAllRows: (rates: Rate[]) => {
            const currentSelected = useItems.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                set(
                    (state) => (
                        {
                            currentSelectedRows: []
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, ...rates]
                        }
                    )
                )
            }
        },

        checkRow: (rate: Rate) => {
            const currentSelected = useItems.getState().currentSelectedRows.filter(el => el.id === rate.id);

            if (currentSelected.length > 0) {
                const updatedArr = useItems.getState().currentSelectedRows.filter(el => el.id !== rate.id)
                set(
                    (state) => (
                        {
                            currentSelectedRows: updatedArr
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, rate]
                        }
                    )
                )
            }
        },

        checkFilterValue: (categoryStatus: FilterItem) => {
            const currentItems = useItems.getState().ratesFilter;
            if (currentItems) {
                const updatedItems: FilterItem[] = []
                for (let i = 0; i < currentItems.length; i++) {
                    if (currentItems[i].filterItemValue === categoryStatus.filterItemValue) {
                        updatedItems.push(
                            {
                                ...categoryStatus,
                                checked: !categoryStatus.checked
                            }
                        )
                    } else updatedItems.push(currentItems[i])
                }

                set(
                    (state) => (
                        {
                            ratesFilter: updatedItems
                        }
                    )
                )
            }
        },

        clearFilter: () => {
            const currentItems = useItems.getState().ratesFilter;

            const updatedItems: FilterItem[] = []
            for (let i = 0; i < currentItems.length; i++) {
                updatedItems.push(
                    {
                        ...currentItems[i],
                        checked: false
                    }
                )
            }

            set(
                (state) => (
                    {
                        ratesFilter: updatedItems
                    }
                )
            )
        },


        setSort: (value: string) => {
            set(
                (state) => (
                    {
                        sort: value,
                    }
                )
            );
        },

        setRatesList: (ratesList: Rate[]) => {
            set(
                (state) => (
                    {
                        ratesList: ratesList,
                    }
                )
            );
        },

        setSearchBar: (value: string) => {
            set(
                (state) => (
                    {
                        searchBar: value
                    }
                )
            )
        },

        updateTextFilter: () => {
            const map = new TSMap<string, string>();
            const categoryStatusFilter = useItems.getState().ratesFilter.slice();
            let result: string = ""

            categoryStatusFilter.forEach(
                (newFilterItem: FilterItem) => {

                    if (newFilterItem.checked) {

                        if (map.has(newFilterItem.filterItemKey)) {
                            const mapValue = map.get(newFilterItem.filterItemKey)
                            map.set(
                                newFilterItem.filterItemKey,
                                mapValue.replace("]", "") + ", " + newFilterItem.filterItemValue.replace("[", ""))

                        } else {
                            map.set(newFilterItem.filterItemKey, newFilterItem.filterItemValue)
                        }
                    }
                }
            )

            const keys = map.keys();
            const val = map.values();

            for (let i = 0; i < map.length; i++) {
                if (i !== map.length - 1) {
                    result = result + keys[i] + val[i] + ", "
                } else {
                    result = result + keys[i] + val[i]
                }
            }

            set(
                (state) => (
                    {
                        textFilter: result
                    }
                )
            );
        },

        setPageSize: (pageSize: string) => {
            set(
                (state) => (
                    {pageSize: pageSize}
                )
            )
        },

        setPageIndex: (pageIndex: number) => {
            set(
                (state) => (
                    {pageIndex: pageIndex}
                )
            )
        },

        resetPagination: () => {
            set(
                (state) => (
                    {
                        pageSize: paginationDefaultValues.pageSize,
                        pageIndex: paginationDefaultValues.pageIndex
                    }
                )
            )
        }
    })
)

export default useItems;