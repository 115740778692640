import {TSMap} from "typescript-map";
import {Category} from "utils/types/primary/Category";
import {create} from 'zustand'

interface State {
    categoriesMap: TSMap<number, Category>
}

interface Actions {
    updateCategoriesMap: (category: Category[]) => void
}

const useCategoriesMap = create<State & Actions>(
    (set, get) => ({
        categoriesMap: new TSMap<number, Category>(),

        updateCategoriesMap: (categories: Category[]) => {
            const currentCategories = useCategoriesMap.getState().categoriesMap.clone()
            categories.forEach(el => currentCategories.set(el.id, el))

            set(
                (state) => (
                    {
                        categoriesMap: currentCategories,
                    }
                )
            )
        },

    })
)

export default useCategoriesMap;