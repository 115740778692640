import {useNavigate} from "react-router-dom";
import resetPagination from "utils/zustand/resetPagination";
import useMenubar from "utils/zustand/tables/useMenubar";

interface Props {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ({setOpen}: Props) {
    const {menuBar} = useMenubar();
    const navigate = useNavigate()

    return <ul className={'px-2 pt-4 text-sm'}>
        {menuBar.map(el =>
            <li
                key={el.title}
                onClick={() => {
                    if (!el.isActive) {
                        resetPagination();
                        if (setOpen) {
                            setOpen(false)
                        }
                        navigate(el.route)
                    }
                }}
                className={`
                pr-2 py-1 rounded-lg cursor-pointer font-semibold itemHover
                ${el.isSecondary ? 'pl-[1.563rem] text-marcoGrayTextColor' : 'pl-2 text-marcoDefaultTextColor'} 
                ${el.isActive && 'bg-gray-400 bg-opacity-5'}
                `}>
                {el.title}
            </li>)}
    </ul>
}