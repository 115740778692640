import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Order} from "utils/types/primary/Order";
import {PaymentTransaction} from "utils/types/primary/PaymentTransaction";

export function getOrderSid(transaction: PaymentTransaction, orders: Order[] | undefined) {

    if (orders) {
        const order = orders.filter(el => el.id === transaction.orderId)
        if (order[0]) {
            const orderSid = getString([{
                element: order[0].sid,
                tag: ElementTag.name
            }])
            return '#' + orderSid
        }
    }
}