import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Customer} from "utils/types/primary/Customer";
import {Order} from "utils/types/primary/Order";

export function getCustomerName(order: Order, customers: Customer[] | undefined) {
    if (customers) {
        const customer = customers.filter(el => el.id === order.user)
        if (customer[0]) {
            const customerName = getString([{
                element: customer[0].firstName,
                tag: ElementTag.name
            }, {element: customer[0].lastName, tag: ElementTag.name}])
            return customerName
        }
    }
}