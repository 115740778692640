import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import { Rate } from "utils/types/primary/Rate";
import useTokenStore from "utils/zustand/useTokenStore";

export type UpdatedItem = {
    length: number; id: number | null;
    name: string;
    description: string;
    categories: number[];
    volume: number;
    weight: number;
    enable: boolean;
    height: number;
    width: number;
}

export default async function updateItem(item: UpdatedItem) {
    const token = useTokenStore.getState().token;

    try {
        const res = await fetch(ApiHost + `api/v1/item/${item.id}`, {
            method: 'PUT',
            body: JSON.stringify(item),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })

        if (res.ok) {
            const data: Rate = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}