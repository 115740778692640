import * as z from "zod";

const customItemSchema = z.object({
    name: z.string().min(1,),
    quantity: z.coerce.number().min(1),
    description: z.string().optional(),
    width: z.coerce.number().min(0.1),
    length: z.coerce.number().min(0.1),
    height: z.coerce.number().min(0.1),
    weight: z.coerce.number().min(0.1),
})

export default customItemSchema

