import {PlusCircledIcon} from "@radix-ui/react-icons";
import {Badge} from "cn/components/ui/badge";
import {Button} from "cn/components/ui/button";
import {Checkbox} from "cn/components/ui/checkbox";
import {Popover, PopoverContent, PopoverTrigger} from "cn/components/ui/popover";
import {Separator} from "cn/components/ui/separator";
import {FilterItem} from "utils/types/suibsidiary/filterItem";
import useMembers from "utils/zustand/tables/useMembers";

export default function MemberStatusFilter() {
    const {memberStatus, setPageIndex, updateTextFilter, checkMemberStatusValue, clearMemberStatusFilter} = useMembers();

    function onCheckHandle(filterItem: FilterItem) {
        checkMemberStatusValue(filterItem);
        updateTextFilter();
        setPageIndex(1)
    }

    function onClearFilterHanle() {
        clearMemberStatusFilter();
        updateTextFilter();
        setPageIndex(1)
    }

    return <Popover>
        <PopoverTrigger asChild>
            <Button
                variant="outline" size="sm" className="h-8 border-dashed">
                <PlusCircledIcon className="mr-2 h-4 w-4"/>
                Role

                {memberStatus.filter(el => el.checked).length > 0 && (
                    <>
                        <Separator orientation="vertical" className="mx-2 h-4"/>
                        <Badge
                            variant="secondary"
                            className="rounded-sm px-1 mr-2 font-normal lg:hidden"
                        >
                            {memberStatus.filter(el => el.checked).length}
                        </Badge>
                        <div className="hidden space-x-1 lg:flex">
                            {memberStatus.filter(el => el.checked).length > 2 ? (
                                <Badge
                                    variant="secondary"
                                    className="rounded-sm px-1 font-normal"
                                >
                                    {memberStatus.filter(el => el.checked).length} selected
                                </Badge>
                            ) : (
                                <>
                                    {
                                        memberStatus
                                            .filter(el => el.checked)
                                            .map(
                                                el => <Badge
                                                    variant="secondary"
                                                    key={el.filterItemValue}
                                                    className="rounded-sm px-1 font-normal"
                                                >
                                                    {el.filterItemTitle}
                                                </Badge>
                                            )
                                    }
                                </>
                            )}
                        </div>
                    </>
                )}

            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0" align="start">
            <ul className={'w-full h-full text-sm font-medium py-2 px-2'}>
                {memberStatus.map(item =>
                    <li
                        onClick={e => onCheckHandle(item)}
                        key={item.filterItemValue}
                        className={'py-[0.15rem] px-2 cursor-pointer itemHover'}>
                        <div className={"gap-3 flex items-center"}>
                            <Checkbox
                                checked={item.checked}
                                onCheckedChange={e => onCheckHandle(item)}>
                            </Checkbox>
                            {item.filterItemTitle}
                        </div>
                    </li>)}
            </ul>

            <Separator/>

            {(memberStatus.filter(el => el.checked).length > 0) &&
                <div onClick={onClearFilterHanle}
                     className={'text-sm font-medium cursor-pointer py-2 px-2 itemHover rounded-none'}>Clear filters</div>}
        </PopoverContent>
    </Popover>
}