import {Separator} from "cn/components/ui/separator";
import {useEffect, useState} from "react";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import getNumberStories from "utils/stringHandlers/getAddress/getNumberStories";
import getPropertySize from "utils/stringHandlers/getAddress/getPropertySize";
import getSquareFootage from "utils/stringHandlers/getAddress/getSquareFootage";
import getUnitSize from "utils/stringHandlers/getAddress/getUnitSize";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Address} from "utils/types/primary/Address";
import { Order } from "utils/types/primary/Order";
import useSheet, {ElementType} from "utils/zustand/useSheet";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";

interface Props {
    order: Order | undefined
    mutateOrder: () => void
}

export default function PickUpAddress({order, mutateOrder}: Props) {
    const [pickupAddress, setPickupAddress] = useState<Address | null>(null);
    const {open} = useSheet()

    useEffect(() => {
        if (order) {
            const pickup = order.addresses?.filter(el => el.direction === 'ORIGIN');
            if (pickup && pickup[0]) {
                setPickupAddress(pickup[0])
            }
        }
    }, [order]);

    return <div
        key={'order-pickup-address'}
        className={'border rounded-lg'}>
        <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
            <h3 className={'text-sm font-semibold '}>Pickup address</h3>
            <div
                onClick={() => order && open(ElementType.PickupAddress, order, 'Edit pickup address', mutateOrder)}
                className={'grid place-items-center xDefault'}>
                <Dots/>
            </div>
        </div>

        {pickupAddress && <>
            <Separator/>

            <div className={'flex flex-col gap-1 p-2'}>
                <p>{getString([
                    {element: pickupAddress?.address, tag: ElementTag.address},
                    {element: pickupAddress?.city, tag: ElementTag.address},
                    {element: pickupAddress?.state, tag: ElementTag.address},
                    {element: pickupAddress?.country, tag: ElementTag.address},
                ])}</p>

                <p>{getString([
                    {element: pickupAddress?.propertyType ? formatEnumValueToString(pickupAddress?.propertyType) : '', tag: ElementTag.unit},
                    {element: pickupAddress?.access ? 'access: ' + formatEnumValueToString(pickupAddress?.access).toLowerCase() : '', tag: ElementTag.unit},


                    {element: pickupAddress?.squareFootage ? getSquareFootage(pickupAddress.squareFootage) : '', tag: ElementTag.unit},
                    {element: pickupAddress?.propertySize ? getPropertySize(pickupAddress.propertySize) : '', tag: ElementTag.unit},
                    {element: pickupAddress?.unitSize ? getUnitSize(pickupAddress.unitSize) : '', tag: ElementTag.unit},
                    {element: pickupAddress?.numberStories ? getNumberStories(pickupAddress.numberStories) : '', tag: ElementTag.unit},
                    {element: pickupAddress?.floorNumber ? 'floor: ' + formatEnumValueToString(pickupAddress?.floorNumber).toLowerCase() : '', tag: ElementTag.unit},
                    {element: pickupAddress?.unitNumber ? 'unit: ' + formatEnumValueToString(pickupAddress?.unitNumber).toLowerCase() : '', tag: ElementTag.unit},
                ])}</p>

            </div>
        </>}
    </div>
}