import {Button} from "cn/components/ui/button";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "cn/components/ui/select";
import React from "react";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
} from "@radix-ui/react-icons"
import {IData} from "utils/types/primary/IData";


interface ComponentProps<T> {
    data: IData<T> | undefined
    pageSize: string
    setPageSize: (pageSize: string) => void
    pageIndex: number
    setPageIndex: (pageIndex: number) => void
}

export default function Pagination<T>({data, setPageSize, setPageIndex, pageSize, pageIndex}: ComponentProps<T>) {

    if (!data) {
        return null
    }

    return (
        <div className="flex items-center space-x-6 lg:space-x-8">
            <div className="flex items-center space-x-2">
                <p className="text-sm font-medium">Rows per page</p>
                <Select
                    value={pageSize}
                    onValueChange={(e) => {
                        setPageSize(e);
                        setPageIndex(1)
                    }
                    }
                >
                    <SelectTrigger className="h-8 w-[70px]">
                        <SelectValue placeholder={pageSize}/>
                    </SelectTrigger>
                    <SelectContent side="top">


                        {[10, 20, 30].map((pageSize) => (
                            <SelectItem key={pageSize} value={`${pageSize}`}>
                                {pageSize}
                            </SelectItem>

                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className="flex w-fit items-center justify-center text-sm font-medium">
                Page {pageIndex} of {data.totalPages}
            </div>
            <div className="flex items-center space-x-2">

                <Button
                    size={"sm"}
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        setPageIndex(1)
                    }}
                    disabled={pageIndex === 1}

                >
                    <span className="sr-only">Go to first page</span>
                    <DoubleArrowLeftIcon className="h-4 w-4"/>
                </Button>

                <Button
                    size={"sm"}
                    variant="outline"
                    className="h-8 w-8 p-0"
                    onClick={() => {
                        setPageIndex(pageIndex - 1)
                    }}
                    disabled={pageIndex - 1 <= 0}
                >
                    <span className="sr-only">Go to previous page</span>
                    <ChevronLeftIcon className="h-4 w-4"/>
                </Button>

                <Button
                    size={"sm"}
                    variant="outline"
                    className="h-8 w-8 p-0"
                    onClick={() => {
                        setPageIndex(pageIndex + 1)
                    }}
                    disabled={pageIndex === data.totalPages || data.numberOfElements === 0}
                >
                    <span className="sr-only">Go to next page</span>
                    <ChevronRightIcon className="h-4 w-4"/>
                </Button>

                <Button
                    size={"sm"}
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    disabled={pageIndex === data.totalPages || data.numberOfElements === 0}
                    onClick={() => {
                        setPageIndex(data.totalPages)
                    }}
                >
                    <span className="sr-only">Go to last page</span>
                    <DoubleArrowRightIcon className="h-4 w-4"/>
                </Button>
            </div>
        </div>
    )
}