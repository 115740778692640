import {Sheet, SheetContent} from "cn/components/ui/sheet";
import DashboardContent from "cn/custom/dashboard/dashboardContent";
import {useEffect} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {defaultDimensions} from "utils/config/defaultDimensions";
import useViewport from "utils/zustand/useViewport";

interface Props {
    isOpen: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DashboardSheet({isOpen, setOpen}: Props) {
    const isOutside = useOnclickOutside(() => {
        setOpen(false)
    });

    const {width} = useViewport()

    useEffect(() => {
        if (width >= defaultDimensions.lg) {
            setOpen(false)
        }
    }, [width]);

    return <Sheet open={isOpen}>
        <SheetContent side={'left'} className={'py-0 px-0 w-60'}>
            <div ref={isOutside} className={'w-full h-full'}><DashboardContent setOpen={setOpen}/></div>
        </SheetContent>
    </Sheet>
}