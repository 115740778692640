export enum ElementTag {
    name,
    address,
    phone,
    email,
    date,
    unit,
    items
}

export interface DataElement {
    element: string | null | undefined
    tag: ElementTag
    attribute?: string
}

export default function getString(data: DataElement[]): string {
    const elementsLength = data.length;
    switch (elementsLength) {
        case 0: {
            return "No data"
        }

        case 1: {
            if (data[0].element) {
                const result = data[0].element + (data[0].attribute ? (" " + data[0].attribute) : "")
                return result
            } else {
                switch (data[0].tag) {
                    case ElementTag.address:
                        return "No address";
                    case ElementTag.email:
                        return "No email";
                    case ElementTag.name:
                        return "No name";
                    case ElementTag.phone:
                        return "No phone";
                    case ElementTag.date:
                        return "No date";
                    case ElementTag.unit:
                        return "No property data";
                    case ElementTag.items:
                        return "No items"
                }
            }
            break
        }

        default: {
            let result: string = "";

            for (let i = 0; i < data.length; i++) {
                if (data[i].element) {

                    if (data[i] === data[0]) {
                        result = data[i].element + (data[i].attribute ? (" " + data[i].attribute) : "")
                    } else {
                        (data[i - 1] && data[i - 1].tag === ElementTag.name && data[i].tag === ElementTag.name)
                            ? result = result + " " + data[i].element + (data[i].attribute ? (" " + data[i].attribute) : "")
                            : result = result + ", " + data[i].element + (data[i].attribute ? (" " + data[i].attribute) : "")
                    }
                }
            }
            return result
        }
    }
}