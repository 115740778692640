import { Button } from "cn/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "cn/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "cn/components/ui/tabs";
import { CalendarDateRangePicker } from "cn/custom/calendarRangePicker";

export default function StatisticsBoard() {
    return  <div className={"flex flex-col mt-2 w-full gap-[24px]"}>
        <div className="flex justify-between">
            <h1 className={"text-zinc-800 text-2xl font-semibold font-['Inter']"}>Home</h1>

            <div className={"flex gap-[8px]"}>
                <CalendarDateRangePicker/>
                <Button size={"sm"}>Download</Button>
            </div>
        </div>

        <Tabs defaultValue="overview" className="space-y-4">
            <TabsList>
                <TabsTrigger value="overview">Overview</TabsTrigger>
                <TabsTrigger value="analytics">Analytics</TabsTrigger>
                <TabsTrigger value="reports">Reports</TabsTrigger>
                <TabsTrigger value="notifications">Notifications</TabsTrigger>
            </TabsList>

            <TabsContent value="overview">

                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Total Revenue
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">$45,231.89</div>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Total orders
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">2350</div>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">Average order value</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">$452.03</div>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Conversion rate
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">1.1%</div>
                        </CardContent>
                    </Card>
                </div>
            </TabsContent>

            <TabsContent value="analytics">Analytics</TabsContent>
            <TabsContent value="reports">Reports</TabsContent>
            <TabsContent value="notifications">Notifications</TabsContent>

        </Tabs>
    </div>
}