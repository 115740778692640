import {zodResolver} from "@hookform/resolvers/zod";
import {Button} from "cn/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {ScrollArea} from "cn/components/ui/scroll-area";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "cn/components/ui/select";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import createMember from "utils/API/createMember";
import getCompanies from "utils/API/getCompanies";
import {MemberRole} from "utils/enum/memberRole";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import {Company} from "utils/types/primary/Company";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import memberSchema from "utils/zodSchemas/memberSchema";
import useSheet from "utils/zustand/useSheet";
import * as z from "zod";

export default function MemberCreate() {
    const navigate = useNavigate();
    const form = useForm<z.infer<typeof memberSchema>>({
        resolver: zodResolver(memberSchema),
        defaultValues: {
            companyId: "",
            role: "",
            phone: "",
            lastName: "",
            firstName: "",
            email: ""
        }
    });

    const {mutateSource, close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Company[]>([])


    useEffect(() => {
        getCompanies()
            .then(value => {
                if (isInstanceOf<ErrorMessage>(value, 'message')) {
                    handleResponseError(value, () => navigate('/'))
                } else {
                    setCompanies(value.content)
                }
            })
    }, []);

    async function onSubmit(values: z.infer<typeof memberSchema>) {
        setLoading(true);

        const res = await createMember(values);

        if (isInstanceOf<ErrorMessage>(res, 'message')){
            handleResponseError(res, () => navigate('/'))
        } else {
            await mutateSource();
            toast({
                description: 'Request fetched'
            })
        }

        setLoading(false);
        close();
    }


    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={"flex flex-col gap-[16px]"}>
            <section className={"flex flex-col gap-4"}>
                <FormField
                    control={form.control}
                    name="firstName"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                First name
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"First name"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="lastName"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Last name
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Last name"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="email"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Email
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Email"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="phone"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Phone
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Phone"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="companyId"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Company</FormLabel>
                            <Select
                                onValueChange={field.onChange}
                                defaultValue={field.value}>
                                <FormControl className={"w-full"}>
                                    <SelectTrigger>
                                        <SelectValue
                                            placeholder="Select company"/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <ScrollArea className={'h-[15rem] pr-5'}>
                                        {companies.map(company => <SelectItem key={company.id}
                                                                              value={company.id}>{company.legalName}</SelectItem>)}
                                    </ScrollArea>
                                </SelectContent>
                            </Select>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="role"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Role</FormLabel>
                            <Select
                                onValueChange={field.onChange}
                                defaultValue={field.value}>
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue
                                            placeholder="Select role"/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    {Object.values(MemberRole).map(el => <SelectItem key={el}
                                                                                     value={el}>{formatEnumValueToString(el)}</SelectItem>)}
                                </SelectContent>
                            </Select>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </section>


            <div
                className="items-start justify-between flex w-full gap-4">
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    type={"reset"}
                    variant={"outline"}
                    onClick={() => close()}>
                    Cancel
                </Button>
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    variant={'green'}
                    type={"submit"}>{isLoading ? <ButtonLoading/> : 'Save'}</Button>
            </div>
        </form>
    </Form>
}