import {Button} from "cn/components/ui/button";
import {toast} from "cn/components/ui/use-toast";
import {ArrowLeft} from "lucide-react";
import CompanyDetailsLoadingState from "cn/custom/skeletons/companyDetailsLoadingState";
import CompanyOrders from "pages/main/details/companyDetails/components/companyOrders";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import stringToFormatDateYear from "utils/stringHandlers/stringToFormatDateYear";
import {Company} from "utils/types/primary/Company";
import useMembers from "utils/zustand/tables/useMembers";
import {ReactComponent as Copy} from "../../../../icons/copy.svg";
import {ReactComponent as Dots} from "../../../../icons/threeDotsInLine.svg";

export default function CompanyDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [sliceNumber, setSliceNumber] = useState<number>(2)

    const {
        data: companySource,
        isLoading: companyLoading,
        mutate: mutateCompanySource,
        error: companySourceError,
    } = useSWR<Company>(id ? ApiHost + encodeURI(`api/v1/company/${id}`) : null, fetcher);

    useEffect(() => {
        if (companySourceError) {
            handleResponseError(companySourceError, () => navigate('/'))
        }
    }, [companySourceError]);

    if (!companySource) {
        return <CompanyDetailsLoadingState/>
    }

    return <div className={'middleWidth container text-marcoDefaultTextColor'}>
        <section>
            <div className={'flex justify-between'}>

                <div className={'flex flex-col gap-[0.375rem]'}>

                    <div
                        onClick={() => navigate(-1)}
                        className={'flex gap-2 place-items-center cursor-pointer text-marcoDefaultTextColor font-bold text-lg'}>
                        <ArrowLeft className={'h-5 text-marcoDefaultTextColor'}/>
                        <h1>{companySource.legalName}</h1>
                    </div>

                    <p className={'ml-8 text-xs text-marcoGrayTextColor'}>{stringToFormatDateYear(companySource.createOn)}</p>
                </div>
                <Button disabled>More actions</Button>
            </div>
        </section>

        <section className={'flex gap-5 pt-6'}>

            <div className={'leftSection'}>
                <CompanyOrders/>
            </div>

            <div className={'flex-1 flex flex-col gap-4 text-marcoDefaultTextColor font-normal'}>
                <div className={'border rounded-lg w-full py-4 px-4 text-sm'}>

                    <div className={'flex justify-between place-items-center'}>
                        <p className={'font-bold'}>{companySource.legalName}</p>
                        <div
                            onClick={() => navigate(`${routes.editCompany}/${companySource?.id}`)}
                            className={'xDefault grid place-items-center'}><Dots/></div>
                    </div>

                    <p className={'font-bold pt-4 pb-[0.375rem]'}>Main contact</p>

                    <p>{getString([
                        {element: companySource.firstName, tag: ElementTag.name},
                        {element: companySource.lastName, tag: ElementTag.name},

                    ])}</p>

                    <div className={'flex justify-between place-items-center pb-[0.375rem]'}>
                        <p className={'text-marcoLinks'}>{companySource.email}</p>
                        <div
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    `${companySource?.email}`
                                );
                                toast({
                                    description: 'Email copied to clipboard'
                                })
                            }}
                            className={'xDefault grid place-items-center'}>
                            <Copy/>
                        </div>
                    </div>

                    <p className={'pb-[0.375rem]'}>+{companySource.phone}</p>

                    <p className={'font-bold pt-4 pb-[0.375rem]'}>Primary address</p>

                    <div className={'flex justify-between'}>
                        <div className={'flex flex-col'}>
                            <p>{companySource.address1}</p>
                            <p>{companySource.city} {companySource.state}</p>
                            <p>{companySource.country}</p>
                        </div>

                        <div
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    `${getString([
                                        {element: companySource?.address1, tag: ElementTag.address},
                                        {element: companySource?.city, tag: ElementTag.address},
                                        {element: companySource?.state, tag: ElementTag.address},
                                        {element: companySource?.country, tag: ElementTag.address},
                                    ])}`
                                );
                                toast({
                                    description: 'Address copied to clipboard'
                                })
                            }}
                            className={'xDefault grid place-items-center'}>
                            <Copy/>
                        </div>
                    </div>

                    <p className={'pt-[0.5rem]'}>{companySource.addressPhone}</p>

                </div>

                <div className={'border rounded-lg w-full py-4 px-4 text-sm'}>
                    <div className={'flex justify-between place-items-center'}>
                        <div className={'flex gap-2 place-items-center'}>
                            <p className={'font-bold'}>Members</p>
                            <p className={'py-1 px-2 bg-gray-100 rounded-lg font-semibold text-xs'}>{companySource.members.length}</p>
                        </div>
                        <div onClick={() => {
                            useMembers.getState().setSearchBar(`${companySource?.companyName}`);
                            navigate(routes.members)
                        }} className={'xDefault grid place-items-center'}><Dots/></div>
                    </div>

                    <div className={'flex flex-col gap-4 pt-4'}>
                        {companySource.members.slice(0, sliceNumber).map(member =>
                            <div className={'flex flex-col'}>
                            <p className={'font-bold'}>{formatEnumValueToString(member.role)}</p>

                                <p className={'text-marcoLinks'}>{getString([
                                    {element: member.firstName, tag: ElementTag.name},
                                    {element: member.lastName, tag: ElementTag.name},
                                ])}
                                </p>

                                <div className={'flex justify-between place-items-center pb-[0.375rem]'}>
                                    <p className={'text-marcoLinks'}>{member.email}</p>

                                    <div
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `${member.email}`
                                            );
                                            toast({
                                                description: 'Email copied to clipboard'
                                            })
                                        }}
                                        className={'xDefault grid place-items-center'}>
                                        <Copy/>
                                    </div>
                                </div>

                                <p>{member.phone}</p>


                        </div>)}

                        {companySource.members.length > 2 && <p
                            onClick={() => {if (sliceNumber === 2) {
                            setSliceNumber(companySource?.members.length)
                            } else setSliceNumber(2)
                            }
                        }
                            className={'text-marcoLinks'}>View all members</p>}
                    </div>

                </div>
            </div>
        </section>
    </div>
}