import {PaymentStatus} from "utils/enum/paymentStatus";
import {ReactComponent as GrayFullPip} from "../../../icons/pips/gray/fullPip.svg";
import {ReactComponent as GreenFullPip} from "../../../icons/pips/green/fullPip.svg";
import {ReactComponent as OrangeEmptyPip} from "../../../icons/pips/orange/emptyPip.svg";
import {ReactComponent as OrangeFullPip} from "../../../icons/pips/orange/fullPip.svg";
import {ReactComponent as OrangeHalfPip} from "../../../icons/pips/orange/halfPip.svg";

interface Props {
    status: PaymentStatus | null
}

export default function PaymentStatusBadge({status}: Props) {
    switch (status) {

        case PaymentStatus.notPaid: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeEmptyPip/>
                    <span>Not paid</span>
                </div>
            )
            break
        }

        case PaymentStatus.partiallyPaid: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeHalfPip/>
                    <span>Partially paid</span>
                </div>
            )
            break
        }

        case PaymentStatus.partiallyRefunded: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeFullPip/>
                    <span>Partially refunded</span>
                </div>
            )
            break
        }

        case PaymentStatus.paid: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-emerald-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <GreenFullPip/>
                    <span>Paid</span>
                </div>
            )
            break
        }

        case PaymentStatus.refunded: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-zinc-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <GrayFullPip/>
                    <span>Refunded</span>
                </div>
            )
            break
        }

        default: {
            return null
            break
        }
    }

}