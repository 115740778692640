import {UpdatedItem} from "utils/API/updateItem";
import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import { Rate } from "utils/types/primary/Rate";
import useTokenStore from "utils/zustand/useTokenStore";

export default async function createItem(item: UpdatedItem) {
    const token = useTokenStore.getState().token;

    try {
        const res = await fetch(ApiHost + `api/v1/item`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })

        if (res.ok) {
            const data: Rate = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}