import {zodResolver} from "@hookform/resolvers/zod";
import {Button} from "cn/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "cn/components/ui/select";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import Map from "cn/custom/map/map";
import React, {useEffect, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";
import submitUpdatedAddress, {UpdatedAddress} from "utils/API/submitUpdatedAddress";
import {Access} from "utils/enum/access";
import {PropertySize} from "utils/enum/propertySize";
import {PropertyType} from "utils/enum/propertyType";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import addressSchema from "utils/zodSchemas/addressSchema";
import useSheet from "utils/zustand/useSheet";
import * as z from "zod";

interface Props {
    order: Order | undefined
    direction: string

}

export default function EditAddressForm({
                                            order,
                                            direction,
                                        }: Props) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false);
    const {mutateSource: mutateOrderSource, title, close} = useSheet();
    const [flag, setFlag] = useState<boolean>(false);

    const [currentAddress, setCurrentAddress] = useState<string | null>(null);

    const [isFloorVisible, setFloorVisible] = useState<boolean>(false)
    const [isNumberStoriesVisible, setNumberStoriesVisible] = useState<boolean>(false)
    const [isUnitSizeVisible, setUnitSizeVisible] = useState<boolean>(false)
    const [isPropertySizeVisible, setPropertySizeVisible] = useState<boolean>(false)
    const [isSquareFootageVisible, setSquareFootageVisible] = useState<boolean>(false)

    const addressForm = useForm<z.infer<typeof addressSchema>>({
        resolver: zodResolver(addressSchema),
        defaultValues: {
            address: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            errors: "",
            direction: '',
            id: "",
            orderId: "",
            userId: "",
            unitNumber: "",

            access: "",
            propertyType: "",
            floorNumber: "",
            unitSize: null,
            propertySize: null,
            numberStories: null,
            squareFootage: null,
        }
    })

    const {
        ready: originReady,
        value: originValue,
        suggestions: {status: originStatus, data: originData},
        setValue: setOriginValue,
        clearSuggestions: clearOriginSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {country: "us"}
        },
        debounce: 300,
    });

    const clearOrigin = useOnclickOutside(() => {
        setFlag(false)
        clearOriginSuggestions();
    });

    async function setSuggestionValue(address: string) {
        const geocode = await getGeocode({address: address});
        if (geocode[0]) {
            const {lat, lng} = getLatLng(geocode[0]);
            setOriginValue(address, false);
            setCurrentAddress(address);
            addressForm.setValue('address', address);
        } else {
            toast({
                variant: 'destructive',
                description: "Unable to find address",
            })
        }
    }

    useEffect(() => {
        if (order && order.addresses) {
            const currentAddress = order.addresses.filter(el => el.direction === direction)
            if (currentAddress[0]) {
                setCurrentAddress(currentAddress[0].address ?? '');
                setOriginValue(currentAddress[0].address ?? '')

                addressForm.setValue('address', currentAddress[0].address ?? '')
                addressForm.setValue('city', currentAddress[0].city ?? '')
                addressForm.setValue('state', currentAddress[0].state ?? '')
                addressForm.setValue('country', currentAddress[0].country ?? '')
                addressForm.setValue('direction', currentAddress[0].direction)
                addressForm.setValue('id', currentAddress[0].id)
                addressForm.setValue('orderId', currentAddress[0].orderId)
                addressForm.setValue('userId', currentAddress[0].userId)
                addressForm.setValue('unitNumber', currentAddress[0].unitNumber ?? '')
                addressForm.setValue('propertyType', currentAddress[0].propertyType ?? '')

                switch (currentAddress[0].propertyType) {
                    case PropertyType.apartment: {
                        addressForm.setValue("numberStories", null)
                        addressForm.setValue("unitSize", null)
                        addressForm.setValue("propertySize", currentAddress[0].propertySize ? currentAddress[0].propertySize : "")
                        addressForm.setValue("squareFootage", null)
                        addressForm.setValue("floorNumber", currentAddress[0].floorNumber ?? "")
                        addressForm.setValue("access", currentAddress[0].access)

                        setFloorVisible(true)
                        setNumberStoriesVisible(false)
                        setUnitSizeVisible(false)
                        setPropertySizeVisible(true)
                        setSquareFootageVisible(false)

                        break
                    }

                    case PropertyType.house : {
                        addressForm.setValue("numberStories", currentAddress[0].numberStories ? currentAddress[0].numberStories : "")
                        addressForm.setValue("unitSize", null)
                        addressForm.setValue("propertySize", currentAddress[0].propertySize ? currentAddress[0].propertySize : "")
                        addressForm.setValue("squareFootage", null)
                        addressForm.setValue("floorNumber", "")
                        addressForm.setValue("access", currentAddress[0].access)

                        setFloorVisible(false)
                        setNumberStoriesVisible(true)
                        setUnitSizeVisible(false)
                        setPropertySizeVisible(true)
                        setSquareFootageVisible(false)

                        break
                    }

                    case PropertyType.storage: {
                        addressForm.setValue("numberStories", null)
                        addressForm.setValue("unitSize", currentAddress[0].unitSize ? currentAddress[0].unitSize : "")
                        addressForm.setValue("propertySize", null)
                        addressForm.setValue("squareFootage", null)
                        addressForm.setValue("floorNumber", currentAddress[0].floorNumber ?? "")
                        addressForm.setValue("access", currentAddress[0].access)

                        setFloorVisible(true)
                        setNumberStoriesVisible(false)
                        setUnitSizeVisible(true)
                        setPropertySizeVisible(false)
                        setSquareFootageVisible(false)

                        break
                    }

                    case PropertyType.commercial : {
                        addressForm.setValue("numberStories", null)
                        addressForm.setValue("unitSize", null)
                        addressForm.setValue("propertySize", null)
                        addressForm.setValue("squareFootage", currentAddress[0].squareFootage ? currentAddress[0].squareFootage : "")
                        addressForm.setValue("floorNumber", currentAddress[0].floorNumber ?? "")
                        addressForm.setValue("access", currentAddress[0].access)

                        setFloorVisible(true)
                        setNumberStoriesVisible(false)
                        setUnitSizeVisible(false)
                        setPropertySizeVisible(false)
                        setSquareFootageVisible(true)

                        break
                    }
                }
            } else {

                addressForm.setValue('address', '')
                addressForm.setValue('city', '')
                addressForm.setValue('state', '')
                addressForm.setValue('country', '')
                addressForm.setValue('direction', direction)
                addressForm.setValue('id', '')
                addressForm.setValue('orderId', order.id)
                addressForm.setValue('userId', order.user ?? '')
                addressForm.setValue('unitNumber', '')

                addressForm.setValue("propertyType", PropertyType.apartment)
                addressForm.setValue("numberStories", null)
                addressForm.setValue("unitSize", null)
                addressForm.setValue("propertySize", PropertySize.studio)
                addressForm.setValue("squareFootage", null)
                addressForm.setValue("floorNumber", "")
                addressForm.setValue("access", Access.no_stairs)

                setFloorVisible(true)
                setNumberStoriesVisible(false)
                setUnitSizeVisible(false)
                setPropertySizeVisible(true)
                setSquareFootageVisible(false)

            }
        }
    }, [order]);

    function onPropertyTypeChangeValue(e: string) {
        switch (e) {
            case "APARTMENT": {
                addressForm.setValue("numberStories", null)
                addressForm.setValue("unitSize", null)
                addressForm.setValue("squareFootage", null)
                addressForm.setValue("propertySize", "")
                addressForm.setValue("floorNumber", "")
                addressForm.setValue("access", "")

                setFloorVisible(true)
                setNumberStoriesVisible(false)
                setUnitSizeVisible(false)
                setPropertySizeVisible(true)
                setSquareFootageVisible(false)
                break
            }

            case "HOUSE" : {
                addressForm.setValue("unitSize", null)
                addressForm.setValue("squareFootage", null)

                addressForm.setValue("floorNumber", "")
                addressForm.setValue("propertySize", "")
                addressForm.setValue("numberStories", "")
                addressForm.setValue("access", "")

                setFloorVisible(false)
                setNumberStoriesVisible(true)
                setUnitSizeVisible(false)
                setPropertySizeVisible(true)
                setSquareFootageVisible(false)
                break
            }

            case "STORAGE": {
                addressForm.setValue("numberStories", null)
                addressForm.setValue("propertySize", null)
                addressForm.setValue("squareFootage", null)

                addressForm.setValue("unitSize", "")
                addressForm.setValue("floorNumber", "")
                addressForm.setValue("access", "")

                setFloorVisible(true)
                setNumberStoriesVisible(false)
                setUnitSizeVisible(true)
                setPropertySizeVisible(false)
                setSquareFootageVisible(false)
                break
            }

            case "COMMERCIAL" : {
                addressForm.setValue("numberStories", null)
                addressForm.setValue("unitSize", null)
                addressForm.setValue("propertySize", null)

                addressForm.setValue("squareFootage", "")
                addressForm.setValue("floorNumber", "")
                addressForm.setValue("access", "")

                setFloorVisible(true)
                setNumberStoriesVisible(false)
                setUnitSizeVisible(false)
                setPropertySizeVisible(false)
                setSquareFootageVisible(true)
                break
            }
        }
    }

    async function onSubmitHandler(values: z.infer<typeof addressSchema>) {
        setLoading(true)
        try {
            const geocode = await getGeocode({address: values.address});
            if (geocode[0]) {
                const updatedAddress: UpdatedAddress = {
                    address: values.address,
                    orderId: values.orderId,
                    direction: values.direction,
                    propertyType: values.propertyType,
                    unitNumber: values.unitNumber ?? '',
                    floorNumber: values.floorNumber,
                    numberStories: (values.numberStories !== '' ? values.numberStories : null),
                    squareFootage: (values.squareFootage !== '' ? values.squareFootage : null),
                    access: values.access,
                    unitSize: (values.unitSize !== '' ? values.unitSize : null) ,
                    propertySize: (values.propertySize !== '' ? values.propertySize : null)
                }

                const res = await submitUpdatedAddress(updatedAddress);
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate('/'))
                } else {
                    mutateOrderSource();
                    close()
                }
            } else addressForm.setError('address', {message: 'Invalid address'})

        } catch (e) {
            addressForm.setError('address', {message: 'Invalid address'})
        }

        setLoading(false)
    }

    return <Form {...addressForm}>
        <form onSubmit={addressForm.handleSubmit(onSubmitHandler)}>
            <div className={'flex flex-col gap-4 h-[100dvh]'}>

                <section key={'map'} className={'flex-none'}><Map address={currentAddress}/></section>

                <section key={'address-form'} className={'flex-none flex flex-col gap-2'}>
                    <FormField
                        control={addressForm.control}
                        name="address"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Address</FormLabel>
                                <FormControl>
                                    <div>
                                        <Input
                                            {...field}
                                            placeholder={"Search address"}
                                            value={originValue ?? ''}
                                            onChange={(e) => {
                                                setOriginValue(e.target.value);
                                                addressForm.setValue('address', e.target.value)
                                            }}
                                            disabled={!originReady}
                                            onInput={() => {
                                                if (!flag) setFlag(true)
                                            }}
                                        >
                                        </Input>

                                        {(flag && originStatus === 'OK') && <div className={'static  w-full'}>
                                            <ul ref={clearOrigin}
                                                className={'relative mt-[0.2rem] w-full border bg-white z-30 rounded-lg'}>
                                                {originData.map(
                                                    (suggestion) => {
                                                        return (
                                                            <li
                                                                key={suggestion.place_id}
                                                                className={'p-2 text-xs hover:bg-gray-100 hover:bg-opacity-50 cursor-pointer'}
                                                                onClick={(event) => {
                                                                    clearOriginSuggestions();
                                                                    const addr = suggestion.structured_formatting.main_text + ", " + suggestion.structured_formatting.secondary_text
                                                                    setSuggestionValue(addr)
                                                                }}>{suggestion.structured_formatting.main_text}, {suggestion.structured_formatting.secondary_text}</li>
                                                        );
                                                    })}
                                            </ul>
                                        </div>}
                                    </div>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={addressForm.control}
                        name="unitNumber"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Apartment, suite, etc.</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />


                    <div className={'flex gap-4'}>
                        <FormField
                            control={addressForm.control}
                            name="city"
                            render={({field}) => (
                                <FormItem className={"grow"}>
                                    <FormLabel>City</FormLabel>
                                    <FormControl>
                                        <Input disabled {...field}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={addressForm.control}
                            name="state"
                            render={({field}) => (
                                <FormItem className={"grow"}>
                                    <FormLabel>State</FormLabel>
                                    <FormControl>
                                        <Input disabled {...field}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex gap-4'}>
                        <FormField
                            control={addressForm.control}
                            name="zip"
                            render={({field}) => (
                                <FormItem className={"grow"}>
                                    <FormLabel>Zip</FormLabel>
                                    <FormControl>
                                        <Input disabled {...field}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={addressForm.control}
                            name="country"
                            render={({field}) => (
                                <FormItem className={"grow"}>
                                    <FormLabel>Country</FormLabel>
                                    <FormControl>
                                        <Input disabled {...field}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                </section>


                <section key={'properties-form'} className={'flex flex-col gap-4'}>
                    <FormField
                        control={addressForm.control}
                        name="propertyType"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Property type</FormLabel>
                                <Select
                                    value={field.value ?? ""}
                                    onValueChange={(e) => {
                                        addressForm.setValue("propertyType", e);
                                        onPropertyTypeChangeValue(e)
                                    }}
                                >
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select value"/>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectItem value={PropertyType.apartment}>Apartment</SelectItem>
                                        <SelectItem value={PropertyType.house}>House</SelectItem>
                                        <SelectItem value={PropertyType.storage}>Storage</SelectItem>
                                        <SelectItem value={PropertyType.commercial}>Commercial</SelectItem>
                                    </SelectContent>
                                </Select>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />

                    {isUnitSizeVisible && <FormField
                        control={addressForm.control}
                        name="unitSize"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Size</FormLabel>
                                <Select
                                    onValueChange={(e) => {
                                        addressForm.setValue("unitSize", e);
                                    }}
                                    value={field.value ?? ""}>
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select value"/>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectItem key="UNIT_5x5" value="UNIT_5x5">5`x 5`</SelectItem>
                                        <SelectItem key="UNIT_5x10" value="UNIT_5x10">5`x
                                            10`</SelectItem>
                                        <SelectItem key="UNIT_10x10" value="UNIT_10x10">10`x
                                            10`</SelectItem>
                                        <SelectItem key="UNIT_10x15" value="UNIT_10x15">10`x
                                            15`</SelectItem>
                                        <SelectItem key="UNIT_10x20" value="UNIT_10x20">10`x
                                            20`</SelectItem>
                                        <SelectItem key="UNIT_10x25" value="UNIT_10x25">10`x
                                            25`</SelectItem>
                                        <SelectItem key="UNIT_10x30" value="UNIT_10x30">10`x
                                            30`</SelectItem>
                                    </SelectContent>
                                </Select>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />}

                    {isPropertySizeVisible && <FormField
                        control={addressForm.control}
                        name="propertySize"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Size</FormLabel>
                                <Select
                                    onValueChange={(e) => {
                                        addressForm.setValue("propertySize", e);
                                    }
                                    }
                                    value={field.value ?? ""}>
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select value"/>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectItem key="STUDIO" value="STUDIO">Studio</SelectItem>
                                        <SelectItem key="ONE_BEDROOM" value="ONE_BEDROOM">1
                                            bedroom</SelectItem>
                                        <SelectItem key="TWO_BEDROOMS" value="TWO_BEDROOMS">2
                                            bedrooms</SelectItem>
                                        <SelectItem key="THREE_BEDROOMS" value="THREE_BEDROOMS">3
                                            bedrooms</SelectItem>
                                        <SelectItem key="FOUR_BEDROOMS" value="FOUR_BEDROOMS">4
                                            bedrooms</SelectItem>
                                    </SelectContent>
                                </Select>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />}

                    {isSquareFootageVisible && <FormField
                        control={addressForm.control}
                        name="squareFootage"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Size</FormLabel>
                                <Select
                                    onValueChange={(e) => {
                                        addressForm.setValue("squareFootage", e);
                                    }}
                                    value={field.value ?? ""}>
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select value"/>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectItem key="SQ_FT_500_1000" value="SQ_FT_500_1000">500-1000
                                            sq.ft</SelectItem>
                                        <SelectItem key="SQ_FT_1000_2000" value="SQ_FT_1000_2000">1000-2000
                                            sq.ft</SelectItem>
                                        <SelectItem key="SQ_FT_2000_3000" value="SQ_FT_2000_3000">2000-3000
                                            sq.ft</SelectItem>
                                        <SelectItem key="SQ_FT_3000_PLUS" value="SQ_FT_3000_PLUS">3000
                                            sq.ft and
                                            more</SelectItem>
                                    </SelectContent>
                                </Select>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />}

                    {isFloorVisible && <FormField
                        control={addressForm.control}
                        name="floorNumber"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel
                                    className="text-zinc-800 text-sm font-normal font-['Inter']">Floor
                                    number</FormLabel>
                                <FormControl>
                                    <Input value={field.value}
                                           onChange={(e) => {
                                               addressForm.setValue("floorNumber", e.target.value);
                                           }}/>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />}

                    {isNumberStoriesVisible && <FormField
                        control={addressForm.control}
                        name="numberStories"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Stories</FormLabel>
                                <Select
                                    onValueChange={(e) => {
                                        addressForm.setValue("numberStories", e);
                                    }}
                                    value={field.value ?? ""}>
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select value"/>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectItem key="ONE_STORIES" value="ONE_STORIES">1
                                            story</SelectItem>
                                        <SelectItem key="TWO_STORIES" value="TWO_STORIES">2
                                            stories</SelectItem>
                                        <SelectItem key="THREE_STORIES" value="THREE_STORIES">3
                                            stories</SelectItem>
                                        <SelectItem key="FOUR_STORIES" value="FOUR_STORIES">4+
                                            stories</SelectItem>
                                    </SelectContent>
                                </Select>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />}

                    <FormField
                        control={addressForm.control}
                        name="access"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Access</FormLabel>
                                <Select
                                    onValueChange={(e) => {
                                        addressForm.setValue("access", e);
                                    }}
                                    value={field.value}>
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select value"/>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectItem key="NO_STAIRS" value="NO_STAIRS">No stairs</SelectItem>
                                        <SelectItem key="ONE_FLIGHT_OF_STAIRS" value="ONE_FLIGHT_OF_STAIRS">1
                                            flight of
                                            stairs</SelectItem>
                                        <SelectItem key="TWO_FLIGHT_OF_STAIRS" value="TWO_FLIGHT_OF_STAIRS">2
                                            flight of
                                            stairs</SelectItem>
                                        <SelectItem key="THREE_FLIGHT_OF_STAIRS"
                                                    value="THREE_FLIGHT_OF_STAIRS">3 flight of
                                            stairs</SelectItem>
                                        <SelectItem key="FOUR_FLIGHT_OF_STAIRS"
                                                    value="FOUR_FLIGHT_OF_STAIRS">4 flight of
                                            stairs</SelectItem>
                                        <SelectItem key="FIVE_FLIGHT_OF_STAIRS"
                                                    value="FIVE_FLIGHT_OF_STAIRS">5 flight of
                                            stairs</SelectItem>
                                        <SelectItem key="SIX_FLIGHT_OF_STAIRS" value="SIX_FLIGHT_OF_STAIRS">6
                                            flight of
                                            stairs</SelectItem>
                                        <SelectItem key="ELEVATOR" value="ELEVATOR">Elevator</SelectItem>
                                        <SelectItem key="DRIVE_UP" value="DRIVE_UP">Drive up</SelectItem>
                                    </SelectContent>
                                </Select>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                </section>

                <section key={'buttons'} className={'flex-none bottom-0 pb-2 sticky bg-white'}>
                    <div
                        className="flex-none items-start justify-between flex w-full gap-4">
                        <Button
                            className={'w-full'}
                            disabled={isLoading}
                            variant={"outline"}
                            type={'button'}
                            onClick={close}>
                            Cancel
                        </Button>

                        <Button
                            className={'w-full'}
                            disabled={isLoading}
                            variant={'green'}
                            type={'submit'}
                        >{isLoading ? <ButtonLoading/> : 'Save'}</Button>
                    </div>
                </section>
            </div>
        </form>
    </Form>
}