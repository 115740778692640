import useTokenStore from "utils/zustand/useTokenStore";

export async function checkToken() {
    const isValid = useTokenStore.getState().checkTokenValidity();
    if (isValid) {
        console.log('Valid token.')
    } else {
        console.log('Token is invalid.')
    }
    return isValid
}