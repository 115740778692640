import {Category} from "utils/types/primary/Category";
import {Customer} from "utils/types/primary/Customer";
import {Item} from "utils/types/primary/Item";
import {Member} from "utils/types/primary/Member";
import {Order} from "utils/types/primary/Order";
import { Rate } from "utils/types/primary/Rate";
import {create} from 'zustand'

export enum ElementType {
    default = 'DEFAULT',
    rate = 'RATE',
    category = 'CATEGORY',
    item = 'ITEM',
    member = 'MEMBER',
    customer = 'CUSTOMER',
    notes = 'NOTES',
    PickupAddress = 'PICKUP_ADDRESS',
    DestinationAddress = 'DESTINATION_ADDRESS',
    Inventory = 'INVENTORY',
    Services = 'SERVICES',
    DateAndTime = 'DATE_AND_TIME',
    Company = 'COMPANY',
    Driver = 'DRIVER',
    orderDetailsChangeOrderStatus = 'ORDER_DETAILS_CHANGE_ORDER_STATUS'
}

export type Definition = null | Rate | Category | Item | Member | Customer |Order

interface State {
    isOpen: boolean
    type: ElementType
    value: Definition
    title: string
    mutateSource: () => void
    mutateSecondSource?: () => void
}

interface Actions {
    open: (elementType: ElementType, value: Definition, title: string, mutateSource: () => void, mutateSecondSource?: () => void) => void
    close: () => void
}

const useSheet = create<State & Actions>(
    (set, get) => ({
        isOpen: false,
        type: ElementType.default,
        value: null,
        title: '',
        mutateSource: () => {},
        mutateSecondSource: () => {},

        open: (elementType: ElementType, value: Definition, title: string, mutateSource: () => void, mutateSecondSource?: () => void) => {
            set(
                (state) => (
                    {
                        isOpen: true,
                        type: elementType,
                        value: value,
                        title: title,
                        mutateSource: mutateSource,
                        mutateSecondSource: mutateSecondSource
                    }
                )
            )
        },

        close: () => {
            set(
                (state) => (
                    {
                        isOpen: false,
                    }
                )
            )
        },
    })
)

export default useSheet;
