import useCategories from "utils/zustand/tables/useCategories";
import useCompanies from "utils/zustand/tables/useCompanies";
import useCustomers from "utils/zustand/tables/useCustomers";
import useDrafts from "utils/zustand/tables/useDrafts";
import useFinances from "utils/zustand/tables/useFinances";
import useMembers from "utils/zustand/tables/useMembers";
import useOrders from "utils/zustand/tables/useOrders";
import usePresets from "utils/zustand/tables/usePresets";
import useQuotes from "utils/zustand/tables/useQuotes";
import useRates from "utils/zustand/tables/useRates";
import useItems from "./tables/useItems";


export default function resetPagination() {
    useCategories.getState().resetCategories();
    useCompanies.getState().resetCompanies();
    useCustomers.getState().resetCustomers();
    useDrafts.getState().resetDrafts();
    useFinances.getState().resetFinance();
    useItems.getState().resetItems();
    useMembers.getState().resetMembers();
    useOrders.getState().resetOrders();
    usePresets.getState().resetPresets();
    useQuotes.getState().resetQuotes();
    useRates.getState().resetRates();
}