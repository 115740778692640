import {Separator} from "cn/components/ui/separator";
import {toast} from "cn/components/ui/use-toast";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Customer} from "utils/types/primary/Customer";
import {Order} from "utils/types/primary/Order";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";
import {ReactComponent as Copy} from "../../../../../icons/copy.svg";

interface Props {
    order: Order
}

export default function OrderCustomer({order} : Props) {
    const navigate = useNavigate();
    const {id} = useParams();

    const {
        data: customerSource,
        isLoading: customerLoading,
        mutate: mutateCustomerSource,
        error: customerSourceError,
    } = useSWR<Customer>(order ? ApiHost + encodeURI(`api/v1/customer/${order.user}`) : null, fetcher);

    useEffect(() => {
        if (customerSourceError) {
            handleResponseError(customerSourceError, () => navigate('/'))
        }
    }, [customerSourceError]);


        return <div
            key={'order-customer'}
            className={'border rounded-lg'}>
            <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
                <h3 className={'text-sm font-semibold '}>Customer</h3>
                <div
                    onClick={() => {
                        navigate(`${routes.customerDetails}/${customerSource?.id}`)
                    }}
                    className={'grid place-items-center xDefault'}>
                    <Dots/>
                </div>
            </div>

            {customerSource && <>
                <Separator/>

                <div className={'p-2 flex flex-col text-sm'}>
                    <p className={'text-marcoLinks'}>{getString([
                        {element: customerSource.firstName, tag: ElementTag.name},
                        {element: customerSource.lastName, tag: ElementTag.name},
                    ])}
                    </p>

                    <div className={'flex flex-col gap-4 pt-4'}>
                        <div className={'flex flex-col'}>

                            <p className={'font-bold'}>Contact information</p>

                            <div className={'flex justify-between place-items-center pb-[0.375rem]'}>
                                <p className={'text-marcoLinks'}>{customerSource.email}</p>

                                <div
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            `${customerSource.email}`
                                        );
                                        toast({
                                            description: 'Email copied to clipboard'
                                        })
                                    }}
                                    className={'xDefault grid place-items-center'}>
                                    <Copy/>
                                </div>
                            </div>
                            <p>{customerSource.phone}</p>
                        </div>
                    </div>
                </div>
            </>}
        </div>
}