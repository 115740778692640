import {Separator} from "cn/components/ui/separator";
import {useNavigate} from "react-router-dom";
import routes from "utils/config/routes";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import stringToFormatDateYear from "utils/stringHandlers/stringToFormatDateYear";
import {Order} from "utils/types/primary/Order";

interface Props {
    order: Order
}
export default function OrderCard({order}: Props) {
    const navigate = useNavigate()
    const getPropertyTypeSize = () => {
        const originAddress = order.addresses?.filter(el => el.direction === 'ORIGIN')
        if (originAddress && originAddress[0]) {
            return {
                propertyType: originAddress[0].propertyType,
                propertySize: originAddress[0].propertySize
            }
        } else return {
            propertyType: null,
            propertySize: null
        }
    }

    return <section className={'border rounded-lg flex flex-col mb-4'}>
        <div>
            <div className={'flex  flex-col p-4 gap-2'}>
                <div className={'flex justify-between'}>
                    <p
                        onClick={() => navigate(`${routes.orderDetails}/${order.id}`)}
                        className={'font-bold text-marcoLinks cursor-pointer'}>#{order.sid}</p>

                    <p className={'font-bold'}>
                        ${
                            order.transactions.reduce(
                                (sum, transaction) => {
                                    return sum + transaction.amount
                                }, 0
                            ).toFixed(2)
                        }
                    </p>
                </div>
                <p className={'text-xs text-marcoGrayTextColor'}>{stringToFormatDateYear(order.createOn)}</p>
            </div>

            <Separator/>

            <div className={'flex justify-between p-4'}>
                <p className={''}>{getString([
                    {element: formatEnumValueToString(getPropertyTypeSize().propertyType), tag: ElementTag.unit},
                    {element: formatEnumValueToString(getPropertyTypeSize().propertySize), tag: ElementTag.unit},

                ])}</p>

                <div className={''}>{getString([
                    {element: order.distance ? order.distance.toFixed(2) + ' mi' : '0.00 mi', tag: ElementTag.items},
                    {element: order.volume ? order.volume.toFixed(2) + ' cu.ft' : '0.00 cu.ft', tag: ElementTag.items},

                ])}</div>
            </div>
        </div>
    </section>
}