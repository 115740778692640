import { Checkbox } from "cn/components/ui/checkbox";
import { toast } from "cn/components/ui/use-toast";
import AddFromURLPopUp from "pages/main/details/orderDetails/sections/media/addFromURLPopUp";
import deleteCurrentFile from "pages/main/details/orderDetails/sections/media/deleteCurrentFile";
import {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import {Image} from "utils/types/primary/Image";
import useFilesStore from "pages/main/details/orderDetails/sections/media/useFilesStore";
import useScreenViewStore from "pages/main/details/orderDetails/sections/media/useScreenViewStore";
import useProgressBar from "utils/zustand/primary/useProgressBar";
import ImageSkeleton from "cn/custom/skeletons/ImageSkeleton";
import { Order } from "utils/types/primary/Order";
import { ApiHost } from "utils/config/config";
import fetcher from "utils/API/fetcher";
import {File} from "pages/main/details/orderDetails/sections/media/useFilesStore"
import { Button } from "cn/components/ui/button";
import { addImageOnClick } from "./addImageOnClick";
import {ReactComponent as OptionsMenu} from "../../../../../../icons/optionsMenu.svg";
import {ReactComponent as Enlarge} from "../../../../../../icons/enlarge.svg";

interface Props {
    order: Order | undefined
}


export default function Media({order}: Props) {
    const {files, setFiles, resetFiles, checkFile, hoverFile, restoreFiles} = useFilesStore();
    const {setProgressValue} = useProgressBar()
    const {
        isOpen: isViewOpen,
        setOpen: setViewOpen,
        setCurrentFile,
        setSId,
        setOrderId,
    } = useScreenViewStore();
    const navigate = useNavigate();
    const [showMoreFiles, setShowMoreFiles] = useState<number>(7);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isDownloadByURLPopUpOpen, setDownloadByURLPopUpOpen] = useState<boolean>(false);
    const [ShowFilesButton, setShowFilesButton] = useState<string>("Show more files");
    const [SkeletonNumber, setSkeletonNumber] = useState<number>(0);
    const skeleton = Array.from({length: SkeletonNumber}, (v, i) => <ImageSkeleton/>);

    const {
        data: fileSource,
        mutate: mutateFileSource,
        isLoading
    } = useSWR<Image[]>(order ? ApiHost + encodeURI(`api/v1/file/${order.id}/`) : null, fetcher);

    const checkedFilesLength = files.filter(file => file.isChecked).length;


    useEffect(() => {
        if (order) {
            setSId(order.sid);
            setOrderId(order.id);
        }
    }, [order]);

    useEffect(() => {
        resetFiles();
        if (fileSource) {
            const files: File[] = fileSource.map(
                value => {
                    const file: File = {
                        id: value.id,
                        createOn: value.createOn,
                        imageSource: value.url,
                        isHovered: false,
                        isChecked: false
                    }
                    return file
                }
            )
            setFiles(files);
        }
    }, [fileSource]);

    return (
        <div className={"flex flex-col border rounded-xl px-[12px] py-[12px]"}>
            <AddFromURLPopUp
                order={order}
                isOpen={isDownloadByURLPopUpOpen} setOpen={setDownloadByURLPopUpOpen}
                             mutateFileSource={() => mutateFileSource()} setFilesCount={setShowMoreFiles}
                             setShowFilesButton={setShowFilesButton} setHide={setOpen}/>
            <div className={"flex flex-col gap-4"}>
                {checkedFilesLength > 0

                    ? <div className={"flex justify-between px-2"}>
                        <div className={"flex gap-2 text-sm font-semibold font-inter"}>
                            <Checkbox checked={checkedFilesLength > 0} onCheckedChange={() => restoreFiles()}/>
                            {checkedFilesLength > 1 ? <span>{checkedFilesLength} files selected</span> :
                                <span>1 file selected</span>}
                        </div>

                        <div className={"text-sm font-inter text-rose-800 hover:underline cursor-pointer"}
                             onClick={() => order
                                 ? () => {
                                     const selectedFiles = files.filter(file => file.isChecked);
                                     const dataLoadPace = Number((100 / selectedFiles.length).toFixed(0));

                                     const deleteFiles = async () => {
                                         for (let i = 0; i < selectedFiles.length;) {
                                             const res = await deleteCurrentFile(order.id, selectedFiles[i].id)
                                             if (res === 200) {
                                                 setProgressValue(useProgressBar.getState().progressValue + dataLoadPace)
                                                 i++
                                             } else {
                                                 toast({
                                                     variant: "destructive",
                                                     description: 'Request failed',
                                                 })
                                                 return
                                             }
                                         }
                                         setProgressValue(100)
                                     }

                                     deleteFiles()
                                         .then(value => {
                                             setProgressValue(0);
                                             mutateFileSource();
                                             toast({
                                                 title: "Fetched",
                                                 description: "Request fetched",
                                             })
                                         })
                                 }
                                 : null}>
                            {checkedFilesLength > 1 ? <span>Delete files</span> : <span>Delete file</span>}
                        </div>
                    </div>

                    : <p
                        className={"text-zinc-800 text-sm font-semibold font-inter leading-tight"}>
                        Media
                    </p>}

                {files.length > 0 || skeleton.length > 0
                    ? <div>
                        <div className={"grid grid-cols-4 gap-3"}>
                            {files.slice(0, showMoreFiles).map(
                                file => {
                                    return <div
                                        className={`relative rounded-lg h-[130px] w-[130px]`}
                                        key={file.id}
                                        onMouseEnter={() => hoverFile(file.id, true)}
                                        onMouseLeave={() => hoverFile(file.id, false)}>

                                        <div className={"relative"}>
                                            <div
                                                className={`absolute h-[130px] w-[130px] border rounded-lg flex content-center}`}>
                                                <img
                                                    className={`py-2 px-2 h-[130px] w-fit object-scale-down`}
                                                    src={file.imageSource}/>
                                            </div>

                                            <Checkbox
                                                className={`absolute top-1 left-1 border ${checkedFilesLength > 0 ? "" : "hidden"} border-gray-700 border-opacity-50 bg-white my-1 mx-1 z-30 disabled:opacity-50`}
                                                checked={file.isChecked}
                                                onCheckedChange={() => checkFile(file.id, !file.isChecked)}/>

                                            {file.isHovered &&
                                                <div
                                                    className={"cursor-pointer absolute z-20 w-[130px] grid grid-cols-1 shadow-md border h-[130px] bg-black bg-opacity-50 rounded-lg"}

                                                    onClick={() => {
                                                        if (checkedFilesLength > 0) {
                                                            checkFile(file.id, !file.isChecked)
                                                        } else {
                                                            setCurrentFile(file);
                                                            setViewOpen(true);
                                                            hoverFile(file.id, false)
                                                        }
                                                    }}>
                                                    <div className={"flex justify-between my-1"}>
                                                        <Checkbox
                                                            className={`absolute left-1 border ${checkedFilesLength > 0 ? "hidden" : ""} border-gray-700 border-opacity-50 bg-white my-1 mx-1 disabled:opacity-50`}
                                                            checked={file.isChecked}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onCheckedChange={(e) => checkFile(file.id, !file.isChecked)}/>

                                                        <div className={"absolute right-1"}>
                                                            <OptionsMenu/>
                                                        </div>
                                                    </div>

                                                    <div className={"cursor-pointer absolute self-center ml-[52px]"}>
                                                        <Enlarge/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            )}

                            {skeleton}

                            <div
                                className={"border border-dashed rounded-xl w-[130px] h-[130px] hover:bg-gray-100 flex flex-col gap-2 items-center cursor-pointer"}
                                onClick={(e) => {
                                    const input = document.getElementById("fileInput");
                                    if (input) {
                                        input.click()
                                    }
                                }}>

                                <div className={"flex flex-col gap-4 mt-8 place-items-center"}>
                                    <Button
                                        size={"sm"}
                                        className={"bg-white w-fit"}
                                        variant={"outline"}
                                    >
                                        Add
                                    </Button>

                                    <span className={"text-xs text-gray-500"}>Upload new file</span>

                                    <input className={"w-[0px] h-[0px]"}
                                           type='file'
                                           name={"files"}
                                           multiple={true}
                                           id={"fileInput"}
                                           onChange={(e) => {
                                               if (e.target.files && order) {
                                                   const dataLoadPace = Number((100 / e.target.files.length).toFixed(0));
                                                   const addFiles = async () => {
                                                       if (e.target.files) {
                                                           setSkeletonNumber(e.target.files.length);
                                                           setShowMoreFiles(files.length + e.target.files.length);
                                                           if ((files.length + e.target.files.length) > 7) {
                                                               setOpen(true);
                                                               setShowFilesButton("Show less files")
                                                           }
                                                           for (let i = 0; i < e.target.files.length;) {
                                                               const res = await addImageOnClick(e, e.target.files[i], order)
                                                               if (res === 200) {
                                                                   setProgressValue(useProgressBar.getState().progressValue + dataLoadPace)
                                                                   i++
                                                               } else {
                                                                   toast({
                                                                       variant: "destructive",
                                                                       description: "Request failed",
                                                                   })
                                                                   return
                                                               }
                                                           }
                                                       }
                                                   }

                                                   addFiles()
                                                       .then(value => {
                                                           setProgressValue(0);
                                                       })
                                                       .then(value => mutateFileSource()
                                                           .then(value1 => {
                                                               setSkeletonNumber(0);
                                                               toast({
                                                                   title: "Fetched",
                                                                   description: "Request fetched",
                                                               })
                                                           }))
                                               }
                                           }}/>
                                </div>
                            </div>
                        </div>

                        {files.length > 7
                            ? <div
                                className={`text-center text-sm font-inter text-blue-600 hover:text-blue-700 pt-3 cursor-pointer`}
                                onClick={() => {
                                    if (isOpen) {
                                        setShowMoreFiles(7);
                                        setOpen(false)
                                        setShowFilesButton("Show more files")
                                    } else {
                                        setShowMoreFiles(files.length);
                                        setOpen(true);
                                        setShowFilesButton("Show less files")
                                    }
                                }}>
                                {ShowFilesButton}
                            </div>

                            : null}
                    </div>


                    : <div
                        className={"grid place-content-center border border-dashed rounded-xl h-[125px] hover:bg-gray-100 cursor-pointer"}
                        onClick={(e) => {
                            const input = document.getElementById("fileInput");
                            if (input) {
                                input.click()
                            }
                        }}>

                        <div className={"grid place-content-center gap-2"}>
                            <Button
                                size={"sm"}
                                className={"bg-white"}
                                variant={"outline"}
                            >
                                Upload new
                            </Button>


                            <input className={"w-[0px] h-[0px]"}
                                   type='file'
                                   name={"files"}
                                   multiple={true}
                                   id={"fileInput"}
                                   onChange={(e) => {
                                       if (e.target.files && order) {
                                           const dataLoadPace = Number((100 / e.target.files.length).toFixed(0));
                                           const addFiles = async () => {
                                               if (e.target.files) {
                                                   setSkeletonNumber(e.target.files.length);
                                                   for (let i = 0; i < e.target.files.length;) {
                                                       const res = await addImageOnClick(e, e.target.files[i], order)
                                                       if (res === 200) {
                                                           setProgressValue(useProgressBar.getState().progressValue + dataLoadPace)
                                                           i++
                                                       } else {
                                                           toast({
                                                               variant: "destructive",
                                                               description: "Request failed",
                                                           })
                                                           return
                                                       }
                                                   }
                                               }
                                           }

                                           addFiles()
                                               .then(value => {
                                                   setProgressValue(0);
                                                   setSkeletonNumber(0)
                                                   mutateFileSource();
                                                   toast({
                                                       title: "Fetched",
                                                       description: "Request fetched",
                                                   })
                                               })
                                       }
                                   }}/>
                        </div>
                        <span className={"text-xs text-gray-500"}>Upload new file</span>
                    </div>}
            </div>
        </div>
    )
}