import {File} from "pages/main/details/orderDetails/sections/media/useFilesStore";
import {create} from 'zustand'

interface ViewState {
    currentFile: File
    orderSid: string
    orderId: string
    isOpen: boolean
}

interface ViewActions {
    setCurrentFile: (file: File) => void
    setOpen: (isOpen: boolean) => void
    setSId: (sID: string) => void
    setOrderId: (orderId: string) => void
}

const useScreenViewStore = create<ViewState & ViewActions>(
    (set, get) => ({
        orderSid: "",
        orderId: "",
        currentFile: {id: "", isChecked: false, imageSource: "", isHovered: false, createOn: new Date()},
        isOpen: false,

        setSId: (sID: string) => {
            set(
                (state) => (
                    {
                        orderSid: sID
                    }
                )
            )
        },

        setOrderId: (orderId: string) => {
            set(
                (state) => (
                    {
                        orderId: orderId
                    }
                )
            )
        },

        setCurrentFile: (file: File) => {
            set(
                (state) => (
                    {
                        currentFile: file
                    }
                )
            )
        },

        setOpen: (isOpen: boolean) => {

            set(
                (state) => (
                    {
                        isOpen: isOpen
                    }
                )
            )
        }
    })
)

export default useScreenViewStore;