import {Button} from "cn/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "cn/components/ui/dropdown-menu";
import FulfillmentStatusBadge from "cn/custom/badges/fulfillmentStatusBadge";
import OrderStatusBadge from "cn/custom/badges/orderStatusBadge";
import PaymentStatusBadge from "cn/custom/badges/paymentStatusBadge";
import OrderSkeleton from "cn/custom/skeletons/orderSkeleton";
import {ArrowLeft} from "lucide-react";
import DateAndTime from "pages/main/details/orderDetails/sections/dateAndTime";
import DestinationAddress from "pages/main/details/orderDetails/sections/destinationAddress";
import Media from "pages/main/details/orderDetails/sections/media/media";
import Notes from "pages/main/details/orderDetails/sections/notes";
import OrderCompany from "pages/main/details/orderDetails/sections/orderCompany";
import OrderCustomer from "pages/main/details/orderDetails/sections/orderCustomer";
import OrderDriver from "pages/main/details/orderDetails/sections/orderDriver";
import PickUpAddress from "pages/main/details/orderDetails/sections/pickUpAddress";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import stringToFormatDateYear from "utils/stringHandlers/stringToFormatDateYear";
import {Order} from "utils/types/primary/Order";
import useSheet, {ElementType} from "utils/zustand/useSheet";
import Services from "../orderDetails/sections/services";
import Header from "./sections/header";
import Inventory from "./sections/inventory";
import Payment from "./sections/payment";

export default function OrderDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const {open} = useSheet();

    const {
        data: order,
        mutate: mutateOrderSource,
        error: orderError,
        isLoading: orderLoading,
    } = useSWR<Order>(id ? ApiHost + encodeURI(`api/v1/order/${id}`) : null, fetcher);

    useEffect(() => {
        if (orderError) {
            handleResponseError(orderError, () => navigate('/'))
        }
    }, [orderError]);

    if (!order) {
        return <OrderSkeleton/>
    } else return <div className={'middleWidth container text-marcoDefaultTextColor'}>
        <section>
            <div className={'flex justify-between'}>
                <div className={'flex flex-col gap-[0.375rem]'}>
                    <div className={'flex flex-col gap-0'}>
                        <div className={'flex gap-2 place-items-center'}>
                            <div
                                onClick={() => navigate(-1)}
                                className={'flex gap-2 place-items-center cursor-pointer text-marcoDefaultTextColor font-bold text-lg'}>
                                <ArrowLeft className={`h-5 text-marcoDefaultTextColor`}/>
                                <h1 className={'font-bold'}>#{order.sid}</h1>
                            </div>
                            <OrderStatusBadge status={order.status}/>
                            <PaymentStatusBadge status={order.paymentStatus}/>
                            <FulfillmentStatusBadge status={order.fulfillmentStatus}/>
                        </div>
                        <p className={'text-xs ml-8'}>{stringToFormatDateYear(order.createOn)}</p>
                    </div>
                </div>

                <div className={'flex justify-between gap-2'}>
                    <Button disabled>Refund</Button>


                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button>More actions</Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                            <DropdownMenuGroup>
                                <DropdownMenuItem onClick={() => open(ElementType.orderDetailsChangeOrderStatus, order, 'Change order status', () => mutateOrderSource())}>Change order status</DropdownMenuItem>
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>

                </div>
            </div>
        </section>

        <div className={'flex gap-5 pt-4'}>
            <section className={'leftSection'}>
                <div className={'flex flex-col gap-4'}>

                    <Header order={order}/>

                    <section key={'order-main-section'}
                             className={'border rounded-lg w-full py-2 px-4 text-sm flex flex-col gap-4'}>

                        <h2 className={'text-sm font-semibold'}>Order details</h2>
                        <DateAndTime order={order} mutateOrder={mutateOrderSource}/>
                        <PickUpAddress order={order} mutateOrder={mutateOrderSource}/>
                        <DestinationAddress order={order} mutateOrder={mutateOrderSource}/>
                        <Inventory order={order}/>
                        <Services order={order} mutateOrder={mutateOrderSource}/>
                        <Media order={order}/>

                    </section>
                </div>

                <Payment order={order}/>

            </section>


            <section key={'right-section'} className={'flex-1 flex flex-col gap-4'}>
                <Notes order={order} mutateOrder={mutateOrderSource}/>
                <OrderCustomer order={order}/>
                <OrderCompany mutateOrder={mutateOrderSource} order={order}/>
                <OrderDriver mutateOrder={mutateOrderSource} order={order}/>
            </section>
        </div>
    </div>
}