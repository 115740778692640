import {Button} from "cn/components/ui/button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "cn/components/ui/command";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "cn/components/ui/tabs";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import { produce } from "immer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import {TSMap} from "typescript-map";
import fetcher from "utils/API/fetcher";
import getCompanies from "utils/API/getCompanies";
import updateOrder from "utils/API/updateOrder";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Company} from "utils/types/primary/Company";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Member} from "utils/types/primary/Member";
import {Order} from "utils/types/primary/Order"
import useSheet from "utils/zustand/useSheet";

interface Props {
    order: Order
}

export default function EditCompany({order}: Props) {
    const navigate = useNavigate();
    const {mutateSource, close} = useSheet();

    const [isDisabled, setDisabled] = useState<boolean>(true)
    const [isLoading, setLoading] = useState<boolean>(false);

    const {
        data: company,
        isLoading: companyLoading,
        mutate: mutateCompanySource,
        error: companySourceError,
    } = useSWR<Company>((order && order.carrierCompanyId) ? ApiHost + encodeURI(`api/v1/company/${order.carrierCompanyId}`) : null, fetcher);

    const {
        data: memberSource,
        isLoading: memberLoading,
        mutate: mutateMemberSource,
        error: memberSourceError,
    } = useSWR<Member>((order && order.carrierId) ? ApiHost + encodeURI(`api/v1/member/${order.carrierId}`) : null, fetcher);

    const [companies, setCompanies] = useState<TSMap<string, Company>>(new TSMap<string, Company>())
    const [drivers, setDrivers] = useState<TSMap<string, Member>>(new TSMap<string, Member>())

    const [assignedCompany, setAssignedCompany] = useState<Company | null>(null);
    const [assignedCarrier, setAssignedCarrier] = useState<Member | null>(null)

    const [companyInput, setCompanyInput] = useState<string>('')
    const [driverInput, setDriverInput] = useState<string>('')

    useEffect(() => {
        if (companySourceError) {
            handleResponseError(companySourceError, () => navigate('/'))
        }

        if (memberSourceError) {
            handleResponseError(memberSourceError, () => navigate('/'))
        }

        if (!company) {
            getCompanies()
                .then(value => {
                    if (isInstanceOf<ErrorMessage>(value, 'message')) {
                        handleResponseError(value, () => navigate('/'))
                    } else {
                        const companiesMap: TSMap<string, Company> = new TSMap<string, Company>()
                        value.content.forEach(el => {
                            if (el.companyName) {
                                companiesMap.set(el.companyName, el)
                            }
                        })
                        setCompanies(companiesMap)
                    }
                })
        }

        if (companies.has(companyInput)) {
            setAssignedCompany(companies.get(companyInput))
        } else setAssignedCompany(null)


        if (drivers) {
            if (drivers.has(driverInput)) {
                setAssignedCarrier(drivers.get(driverInput))
            } else setAssignedCarrier(null)
        }

        if (!assignedCompany) {
            setAssignedCarrier(null);
            setDriverInput('');
        }

        if (assignedCompany && assignedCompany.members.length > 0) {
            setDisabled(false);

            const driversMap: TSMap<string, Member> = new TSMap<string, Member>()
            assignedCompany.members.forEach(el => {
                driversMap.set(getString([
                    {element: el.firstName, tag: ElementTag.name},
                    {element: el.lastName, tag: ElementTag.name},
                ]), el)
            })
            setDrivers(driversMap)
        } else setDisabled(true)

    }, [company, companyInput, driverInput, assignedCompany, companySourceError, memberSourceError]);

    async function onSubmit() {
        setLoading(true);

        const updatedOrder = produce(
            order,
            (draft) => {
                if (draft) {
                    draft.carrierCompanyId = assignedCompany ? assignedCompany.id : null
                    draft.carrierId = assignedCarrier ? assignedCarrier.id : null
                    draft.invoices = []
                    draft.transactions = []
                }
            }
        )

        const res = await updateOrder(updatedOrder);

        if (isInstanceOf<ErrorMessage>(res, 'message')){
            handleResponseError(res, () => navigate('/'))
        } else {
            toast({
                description: 'Request fetched'
            })
        }

        await mutateSource();
        setLoading(false);
        close();
    }

    return <section className={'flex flex-col gap-4 text-sm font-medium'}>

        <Tabs defaultValue="company" className="w-full">
            <TabsList className={'w-full flex truncate overflow-x-hidden'}>
                <TabsTrigger className={'grow'} value="company">{assignedCompany ? assignedCompany.companyName : 'Assign company'}</TabsTrigger>
                <TabsTrigger className={'grow'} disabled={isDisabled} value="carrier">{assignedCarrier ? getString([
                    {element: assignedCarrier.firstName, tag: ElementTag.name},
                    {element: assignedCarrier.lastName, tag: ElementTag.name},
                ]) : 'Assign driver'}</TabsTrigger>
            </TabsList>

            <TabsContent value="company">
                <Command className="rounded-lg border shadow-md">
                    <CommandInput
                        value={companyInput}
                        onValueChange={(e) => {
                            setCompanyInput(e)
                        }}
                        placeholder="Search company..." />
                    <CommandList className={'h-[18rem]'}>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup heading="Suggestionsz">
                            {companies
                                .map(el =>
                                    <CommandItem
                                        key={el.id}
                                    >
                                        <div onClick={(e) => setCompanyInput(el.companyName)}>
                                            {getString([
                                                {element: el.companyName, tag:ElementTag.name}
                                            ])}
                                        </div>
                                    </CommandItem>)}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </TabsContent>

            <TabsContent value="carrier">
                <Command className="rounded-lg border shadow-md">
                    <CommandInput
                        value={driverInput}
                        onValueChange={(e) => {
                            setDriverInput(e)
                        }}
                        placeholder="Search company..."/>
                    <CommandList className={'h-[18rem]'}>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup heading="Suggestions">
                            {assignedCompany && assignedCompany.members
                                .map(el =>
                                    <CommandItem
                                        key={el.id}
                                    >
                                        <div onClick={(e) => setDriverInput(getString([
                                            {element: el.firstName, tag: ElementTag.name},
                                            {element: el.lastName, tag: ElementTag.name},
                                        ]))}>
                                            {getString([
                                                {element: el.firstName, tag: ElementTag.name},
                                                {element: el.lastName, tag: ElementTag.name},
                                            ])}
                                        </div>
                                    </CommandItem>)}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </TabsContent>
        </Tabs>

        <div
            className="items-start justify-between flex w-full gap-4">
            <Button
                className={'w-full'}
                disabled={isLoading}
                type={"reset"}
                variant={"outline"}
                onClick={() => close()}>
                Cancel
            </Button>
            <Button
                onClick={onSubmit}
                className={'w-full'}
                disabled={isLoading}
                variant={'green'}
                >{isLoading ? <ButtonLoading/> : 'Save'}</Button>
        </div>

    </section>
}