import getString, {ElementTag} from "utils/stringHandlers/getString";
import { Company } from "utils/types/primary/Company";
import {Order} from "utils/types/primary/Order";

export function getCompanyName(order: Order, companies: Company[] | undefined) {

    if (companies) {
        const company = companies.filter(el => el.id === order.carrierCompanyId)
        if (company[0]) {
            const companyName = getString([{element: company[0].legalName, tag: ElementTag.name}])
            return companyName
        }
    }
}