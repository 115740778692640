import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import { Member } from "utils/types/primary/Member";
import useTokenStore from "utils/zustand/useTokenStore";

export type PUTMember = {
    id: string,
    companyId: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    createOn: string,
    lastUpdate: string,
    role: string
}

export default async function updateMember(member: PUTMember) {
    const token = useTokenStore.getState().token;

    try {
        const res = await fetch(ApiHost + `api/v1/member/${member.id}`, {
            method: 'PUT',
            body: JSON.stringify(member),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })

        if (res.ok) {
            const data: Member = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}