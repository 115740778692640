import {getOrderEstimate} from "utils/getOrderEstimate";
import { Order } from "utils/types/primary/Order";
interface Props {
    order: Order | undefined
}

export default function Header({order}: Props) {
    if (order) {
        return <section key={'order-header-section'}
                        className={'grid grid-cols-3 border rounded-lg py-2 px-4 text-sm font-medium'}>
            <div className={'flex flex-col border-r'}>
                <p>Estimate</p>
                <p className={'font-bold'}>${getOrderEstimate(order).toFixed(2)}</p>
            </div>

            <div className={'flex flex-col pl-2 border-r'}>
                <p>Distance</p>
                <p className={'font-bold'}>{order.distance ? order.distance.toFixed(2) + ' mi' : '0.00 mi'}</p>
            </div>

            <div className={'flex flex-col pl-2'}>
                <p>Volume</p>
                <p className={'font-bold'}>{order.volume ? order.volume.toFixed(2) + ' sq.ft' : '0.00 sq.ft'}</p>
            </div>
        </section>
    }

    return null
}