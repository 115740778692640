import {FilterItem} from "utils/types/suibsidiary/filterItem";

const paymentStatusFilter: FilterItem[] = [
    {
        checked: false,
        filterItemKey: "\"paymentStatus\":",
        filterItemValue: "[NOT_PAID]",
        filterItemTitle: "Not paid",
    },
    {
        checked: false,
        filterItemKey: "\"paymentStatus\":",
        filterItemValue: "[PARTIALLY_PAID]",
        filterItemTitle: "Partially paid",
    },
    {
        checked: false,
        filterItemKey: "\"paymentStatus\":",
        filterItemValue: "[PARTIALLY_REFUNDED]",
        filterItemTitle: "Partially refunded",
    },
    {
        checked: false,
        filterItemKey: "\"paymentStatus\":",
        filterItemValue: "[PAID]",
        filterItemTitle: "Paid",
    },

    {
        checked: false,
        filterItemKey: "\"paymentStatus\":",
        filterItemValue: "[REFUNDED]",
        filterItemTitle: "Refunded",
    },
]

export default paymentStatusFilter