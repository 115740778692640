import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import { Member } from "utils/types/primary/Member";
import useTokenStore from "utils/zustand/useTokenStore";

export type UpdatedMember = {
    role: string,
    email: string,
    companyId: string,
    firstName: string,
    lastName: string,
    phone: string
}

export default async function POSTRate(member: UpdatedMember) {
    const token = useTokenStore.getState().token;

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/member`),
            {
                method: 'POST',
                body: JSON.stringify(member),
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json"
                }
            });

        if (res.ok) {
            const data: Member = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}