import {Button} from "cn/components/ui/button";
import {Calendar} from "cn/components/ui/calendar";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "cn/components/ui/select";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {produce} from "immer";
import moment from "moment/moment";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import updateOrder from "utils/API/updateOrder";
import {AppointmentTime} from "utils/enum/appointmentTime";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import useSheet from "utils/zustand/useSheet";
interface Props {
    order: Order
}
export default function EditDateAndTime({order}: Props) {

    const [date, setDate] = useState<Date | undefined>(new Date());
    const [time, setTime] = useState<string>('')
    const navigate = useNavigate();
    const {mutateSource, close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (order.appointmentDate) {
          const appDate = new Date(order.appointmentDate);
          setDate(appDate)
        } else setDate(new Date())

        if (order.appointmentTime) {
            setTime(order.appointmentTime)
        } else setTime(AppointmentTime.morning)
    }, [order]);

    useEffect(() => {
        if (!date) {
            setDate(new Date())
        }
    }, [date]);

    async function onSubmit() {
        setLoading(true);

        const updatedOrder: Order = produce(
            order,
            (draft) => {
                const currDate = (moment(date).format('YYYY-MM-DD'))

                if (draft) {
                    draft.appointmentDate = currDate === undefined ? "" : currDate
                    draft.appointmentTime = time
                    draft.invoices = []
                    draft.transactions = []
                }
            }
        )

        const res = await updateOrder(updatedOrder)
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate('/'))
        } else {
            await mutateSource();
            toast({
                description: 'Request fetched'
            })
        }

        setLoading(false);
        close();
    }

    return <div className={'flex flex-col gap-4'}>

        <section className={'flex flex-col gap-2'}>
            <Calendar
                className={'py-o px-0'}
                mode="single"
                selected={date}
                onSelect={(e) => {
                    setDate(e);

                }}
            />

            <Select value={time} onValueChange={(e) => setTime(e)}>
                <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select time"/>
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        {Object.values(AppointmentTime).map(el => <SelectItem key={el} value={el}>{formatEnumValueToString(el)}</SelectItem>)}
                    </SelectGroup>
                </SelectContent>
            </Select>
        </section>

        <div
            className="items-start justify-between flex w-full gap-4">
            <Button
                className={'w-full'}
                disabled={isLoading}
                variant={"outline"}
                onClick={() => close()}>
                Cancel
            </Button>

            <Button
                className={'w-full'}
                disabled={isLoading}
                variant={'green'}
                onClick={onSubmit}
                >{isLoading ? <ButtonLoading/> : 'Save'}</Button>
        </div>
    </div>
}