import * as z from "zod";

const itemSchema = z.object({
    id: z.number().nullable(),
    name: z.string().min(1),
    description: z.string().min(1),

    categories: z.array(z.number()).min(1),

    volume: z.coerce.number().min(0),
    weight: z.coerce.number().min(0),
    enable: z.boolean(),
    height: z.coerce.number().min(1),
    length: z.coerce.number().min(1),
    width: z.coerce.number().min(1),
})

export default itemSchema