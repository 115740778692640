export default function getNumberStories(numberStory: string) {
    switch (numberStory) {
        case "ONE_STORIES": {
            return "1 story"
        }

        case "TWO_STORIES": {
            return "2 stories"
        }

        case "THREE_STORIES": {
            return "3 stories"
        }

        case "FOUR_STORIES": {
            return "4 stories"
        }
    }
}