import {zodResolver} from "@hookform/resolvers/zod";
import {Button} from "cn/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import {Textarea} from "cn/components/ui/textarea";
import ButtonLoading from "cn/custom/buttonLoading";
import {Trash2} from "lucide-react";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import deleteCustomItems from "utils/API/deleteCustomItems";
import updateCustomItems from "utils/API/updateCustomItems";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {CustomItem} from "utils/types/primary/CustomItem";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import customItemSchema from "utils/zodSchemas/customItemSchema";
import useSheet from "utils/zustand/useSheet";
import * as z from "zod";

interface Props {
    order: Order

    customItems: CustomItem[]
    setCustomItems: React.Dispatch<React.SetStateAction<CustomItem[]>>

    deletedCustomItems: string[]
    setDeletedCustomItems: React.Dispatch<React.SetStateAction<string[]>>

}

export default function ManageCustomItems({
                                              customItems,
                                              setCustomItems,
                                              deletedCustomItems,
                                              setDeletedCustomItems,
                                              order
                                          }: Props) {
    const navigate = useNavigate()
    const {mutateSource, mutateSecondSource} = useSheet()
    const [isLoading, setLoading] = useState<boolean>(false)


    const form = useForm<z.infer<typeof customItemSchema>>({
        resolver: zodResolver(customItemSchema),
        defaultValues: {
            quantity: 1,
            weight: 1,
            height: 1,
            width: 1,
            length: 1,
            name: '',
            description: ''
        }
    })

    async function onSubmit(values: z.infer<typeof customItemSchema>) {
        setLoading(true);
        const data = {
            name: form.getValues().name,
            length: Number(form.getValues().length),
            width: Number(form.getValues().width),
            height: Number(form.getValues().height),
            count: Number(form.getValues().quantity),
            weight: Number(form.getValues().weight),
            description: form.getValues().description ?? '',
            id: null
        }


        if (deletedCustomItems.length > 0) {
            const deleteRes = await deleteCustomItems(order, deletedCustomItems)
            if (isInstanceOf<ErrorMessage>(deleteRes, 'message')) {
                handleResponseError(deleteRes, () => navigate('/'))
            }
        }

        const updateRes = await updateCustomItems(order, [...customItems, data])
        if (isInstanceOf<ErrorMessage>(updateRes, 'message')) {
            handleResponseError(updateRes, () => navigate('/'))
        }
        if (mutateSecondSource) {
            mutateSecondSource();
        }
        form.reset()
        setLoading(false)
    }

    function changeItemCount(customItem: CustomItem, count: string) {
        const updatedItems: CustomItem[] = [];

        customItems.forEach(el => {
            if (el.id === customItem.id) {
                const item: CustomItem = {
                    ...customItem,
                    count: Number(count)
                }
                updatedItems.push(item)
            } else return updatedItems.push(el)
        })

        setCustomItems(updatedItems)
    }

    function deleteItem(customItem: CustomItem) {
        const updatedItems = customItems.filter(el => el.id !== customItem.id);
        if (customItem.id) {
            const itemId = customItem.id
            setDeletedCustomItems(prevState => [...prevState, itemId])
        }
        setCustomItems(updatedItems)
    }


    return <div className={'flex flex-col gap-2 text-xs'}>
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <section className={"flex flex-col gap-2 text-xs"}>
                    <div className={'flex gap-2 text-xs'}>
                        <FormField
                            control={form.control}
                            name="name"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel className={'text-xs font-semibold'}>
                                        Item name</FormLabel>
                                    <FormControl className={'text-xs'}>
                                        <Input type={"text"} {...field} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="quantity"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel className={'text-xs font-semibold'}>Quantity</FormLabel>
                                    <FormControl className={'text-xs'}>
                                        <Input type={"number"}  {...field} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>

                    <FormField
                        control={form.control}
                        name="description"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel className={'text-xs font-semibold'}>Description</FormLabel>
                                <Textarea
                                    placeholder="Type your message here"
                                    className="resize-none w-full h-[74px] text-xs"
                                    {...field}
                                />
                                <FormMessage/>
                            </FormItem>
                        )}
                    />

                    <div className={'flex flex-col gap-2'}>
                        <h2 className="text-xs font-semibold">Dimenstions</h2>

                        <div className={'flex justify-between gap-2'}>
                            <FormField
                                control={form.control}
                                name="width"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel className={'text-xs font-semibold'}>
                                            Width (in.)</FormLabel>
                                        <FormControl className={'text-xs'}>
                                            <Input className={"flex-1"} step={0.1} min={0.1}
                                                   type={"number"} {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="length"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel className={'text-xs font-semibold'}>
                                            Depth (in.)</FormLabel>
                                        <FormControl className={'text-xs'}>
                                            <Input className={"flex-1"} type={"number"} step={0.1}
                                                   min={0.1} {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>

                        <div className={'flex gap-4'}>
                            <FormField
                                control={form.control}
                                name="height"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel className={'text-xs font-semibold'}>
                                            Height (in.)</FormLabel>
                                        <FormControl className={'text-xs'}>
                                            <Input className={"flex-1"} type={"number"} step={0.1}
                                                   min={0.1} {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="weight"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel className={'text-xs font-semibold'}>
                                            Weight (lb)</FormLabel>
                                        <FormControl className={'text-xs'}>
                                            <Input className={"flex-1"} type={"number"} step={0.1}
                                                   min={0.1} {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>
                        <Button disabled={isLoading} type={"submit"}>{isLoading ? <ButtonLoading/> : 'Add item'}</Button>
                    </div>
                </section>
            </form>

            <section>
                {(customItems.length > 0) && <Table>
                    <TableHeader className={'text-xs'}>
                        <TableRow>
                            <TableHead className="w-52">Name</TableHead>
                            <TableHead className="w-16">Volume</TableHead>
                            <TableHead className="w-16">Quantity</TableHead>
                            <TableHead/>
                        </TableRow>
                    </TableHeader>

                    <TableBody className={'text-xs'}>
                        {customItems.map(el => <TableRow
                            key={el.id}
                        >
                            <TableCell>{el.name}</TableCell>
                            <TableCell>{((el.height * el.width * el.width) * el.count).toFixed(2)}</TableCell>
                            <TableCell><Input className={'w-20'}
                                              min={1}
                                              type={'number'}
                                              onChange={(e) => changeItemCount(el, e.target.value)}
                                              defaultValue={el.count}/></TableCell>
                            <TableCell><Trash2 onClick={() => deleteItem(el)} className={'h-6 xDefault'}/></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>}
            </section>
        </Form>


    </div>
}