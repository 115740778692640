import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {Company} from "utils/types/primary/Company";
import useTokenStore from "utils/zustand/useTokenStore";

export default async function createCompany(company: Company) {
    const token = useTokenStore.getState().token;

    try {
        const res = await fetch(ApiHost + `api/v1/company`, {
            method: 'POST',
            body: JSON.stringify({...company}),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })

        if (res.ok) {
            const data: Company = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}