import getString, {ElementTag} from "utils/stringHandlers/getString";
import { Customer } from "utils/types/primary/Customer";
import {PaymentTransaction} from "utils/types/primary/PaymentTransaction";

export function getCustomerName(transaction: PaymentTransaction, customers: Customer[] | undefined) {

    if (customers) {
        const customer = customers.filter(el => el.id === transaction.userId)
        if (customer[0]) {
            const customerName = getString([{
                element: customer[0].firstName,
                tag: ElementTag.name
            }, {element: customer[0].lastName, tag: ElementTag.name}])
            return customerName
        }
    }
}