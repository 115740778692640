import DashboardSheet from "cn/custom/dashboard/dashboardSheet";
import jwt from "jwt-decode";
import {ChevronDown} from "lucide-react";
import {useEffect, useState} from "react";
import {defaultDimensions} from "utils/config/defaultDimensions";
import {Token} from "utils/types/suibsidiary/Token";
import useTokenStore from "utils/zustand/useTokenStore";
import useViewport from "utils/zustand/useViewport";
import {ReactComponent as MarcoLogo} from "../../media/marcoLogo.svg";
import {ReactComponent as TriplePod} from "../../media/triplePod.svg";

export default function Header() {
    const [username, setUsername] = useState<string>('')
    const {token} = useTokenStore();
    const {width} = useViewport();
    const [isOpen, setOpen] = useState<boolean>(false)

    useEffect(() => {
        if (token) {
            const user: Token = jwt(token);
            setUsername(user.full_name)
        }
    }, [token]);

    return <div className={'px-4 h-16 border-b flex justify-between items-center'}>
        <div className={'flex gap-2 place-items-center'}>
            <DashboardSheet isOpen={isOpen} setOpen={setOpen}/>
            {width <= defaultDimensions.lg &&
                <div
                    onClick={() => setOpen(true)}
                    className={'py-1 px-1 rounded-full cursor-pointer itemHover'}><TriplePod/></div>}
            <MarcoLogo/>
        </div>

        <div className={'flex place-items-center gap-[0.613rem] cursor-pointer itemHover py-2 px-2'}>
            <p className={'font-inter text-marcoDefaultTextColor text-sm font-semibold'}>{username}</p>
            <ChevronDown className={'h-4'}/>
        </div>


    </div>
}