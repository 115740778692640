import {TSMap} from "typescript-map";
import {create} from 'zustand'

interface State {
    customers: string[]
}

interface Actions {
    setCustomers: (customers: (string|null)[]) => void
    resetCustomers: () => void
}

const useCustomersMap = create<State & Actions>(
    (set, get) => ({
        customers: [],

        setCustomers: (customers: (string|null)[]) => {
            const uniqueCustomers = new TSMap<string, string>()

            customers.forEach(el => {
                if (typeof el === 'string') {
                    uniqueCustomers.set(el, el)
                }
            })

            set(
                (state) => (
                    {
                        customers: uniqueCustomers.values(),
                    }
                )
            )
        },

        resetCustomers: () => {
            set(
                (state) => (
                    {
                        customers: [],
                    }
                )
            )
        }
    })
)

export default useCustomersMap;