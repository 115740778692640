import * as z from "zod";

const memberSchema = z.object({
    companyId: z.string().min(1),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    phone: z.string().min(1),
    role: z.string().min(1),
});

export default memberSchema