import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import { Order } from "utils/types/primary/Order";
import {OrderItem} from "utils/types/primary/OrderItem";
import useTokenStore from "utils/zustand/useTokenStore";

export default async function updateOrderItems(order: Order, orderItems: OrderItem[]) {
    const token = useTokenStore.getState().token
    const postItems = orderItems.map(el => {
        return {id: el.itemId, count: el.count}
    })

    try {
        const res: Response = await fetch(ApiHost + encodeURI(`api/v1/${order?.id}/items`), {
            method: 'POST',
            body: JSON.stringify(postItems),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            return {res: 'ok'}
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}