import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import { Order } from "utils/types/primary/Order";
import useTokenStore from "utils/zustand/useTokenStore";

export default async function deleteOrderItems(order: Order, itemsIds: string[]) {
    const token = useTokenStore.getState().token

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/${order?.id}/items`), {
                method: 'DELETE',
                body: JSON.stringify(itemsIds),
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            }
        )

        if (res.ok) {
            return {res: 'ok'}
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}