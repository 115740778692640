import {PaymentTable, TableBody, TableCell, TableRow} from "cn/components/ui/paymentTable";
import { Invoice } from "utils/types/primary/Invoice";
import { Order } from "utils/types/primary/Order";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";

interface Props {
    order: Order | undefined
}

export default function Payment({order}: Props) {
    if (order) {
        return <div
            key={'order-payment'}
            className={'border rounded-lg mt-4'}>
            <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
                <h3 className={'text-sm font-semibold '}>Payment</h3>
                <div className={'grid place-items-center xDefault'}>
                    <Dots/>
                </div>
            </div>

            {(order && order?.invoices.reduce((previousValue, currentValue, currentIndex, array) => {
                return previousValue + currentValue.count
            }, 0) > 0) && <div className={'px-4 pb-4'}>
                <PaymentTable>
                    <TableBody>
                        {order.invoices
                            .filter(
                                (invoice: Invoice) => invoice.count > 0
                            )
                            .map((invoice: Invoice) => (
                                <TableRow key={invoice.type}
                                          className="relative w-fit [font-family:'Inter-Regular',Helvetica] font-normal text-[#303030] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
                                    <TableCell
                                        className={"text-left"}>{(invoice.type.charAt(0) + invoice.type.substring(1).toLowerCase()).replace("_", " ")}</TableCell>
                                    <TableCell
                                        className={"text-right"}>{`$` + invoice.count.toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        <TableRow>
                            <TableCell
                                className={"text-left text-zinc-800 text-sm font-semibold font-['Inter'] leading-tight"}>Total</TableCell>
                            <TableCell
                                className={"text-right text-zinc-800 text-sm font-semibold font-['Inter'] leading-tight"}>{`$` + order.invoices.reduce((sum, current) => sum + current.count, 0).toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </PaymentTable>

            </div>}
        </div>
    } else return null
}