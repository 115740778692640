import {Separator} from "cn/components/ui/separator";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import { Order } from "utils/types/primary/Order";
import useSheet, {ElementType} from "utils/zustand/useSheet";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";

interface Props {
    order: Order | undefined
    mutateOrder: () => void
}

export default function Services({order, mutateOrder}: Props) {
    const {open} = useSheet()

    return <div
        key={'order-services'}
        className={'border rounded-lg'}>
        <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
            <h3 className={'text-sm font-semibold '}>Services</h3>
            <div
                onClick={() => order && open(ElementType.Services, order, 'Edit services', mutateOrder)}
                className={'grid place-items-center xDefault'}>
                <Dots/>
            </div>
        </div>

        {((order && order.services.CARBON) || (order && order.services.CARBON)) && <>
            <Separator/>

            <ul className={'p-2 flex gap-2'}>
                {order?.services.CARBON && <li key={'services-carbon'}
                                               className={'rounded-full bg-gray-100 py-2 px-4 text-xs font-semibold'}>{'Carbon: ' + order.services.CARBON.toLowerCase()}</li>}
                {order?.services.PACKING && <li key={'services-packing'}
                                                className={'rounded-full bg-gray-100 py-2 px-4 text-xs font-semibold'}>{formatEnumValueToString(order.services.PACKING) + ' packing'}</li>}
            </ul>
        </> }
    </div>
}