export default function getUnitSize(unitSize: string) {
    switch (unitSize) {
        case "UNIT_5x5": {
            return "unit size: 5`x 5`"
        }

        case "UNIT_5x10": {
            return "unit size: 5`x 10`"
        }

        case "UNIT_10x10": {
            return "unit size: 10`x 10`"
        }

        case "UNIT_10x15": {
            return "unit size: 10`x 15`"
        }

        case "UNIT_10x20": {
            return "unit size: 10`x 20`"
        }

        case "UNIT_10x25": {
            return "unit size: 10`x 25`"
        }

        case "UNIT_10x30": {
            return "unit size: 10`x 30`"
        }
    }
}