export default function getPropertySize(propertySize: string) {
    switch (propertySize) {
        case "STUDIO": {
            return "studio"
        }

        case "ONE_BEDROOM": {
            return "1 bedroom"
        }

        case "TWO_BEDROOMS": {
            return "2 bedrooms"
        }

        case "THREE_BEDROOMS": {
            return "3 bedrooms"
        }

        case "FOUR_BEDROOMS": {
            return "4 bedrooms"
        }
    }
}