import {Button} from "cn/components/ui/button";
import {Checkbox} from "cn/components/ui/checkbox";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "cn/components/ui/dropdown-menu";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import Pagination from "cn/custom/pagination";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import {ArrowDownWideNarrow, ArrowUpNarrowWide} from "lucide-react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Company} from "utils/types/primary/Company";
import {IData} from "utils/types/primary/IData";
import useCompanies from "utils/zustand/tables/useCompanies";

export default function Companies() {
    const navigate = useNavigate();

    const {
        companiesList,
        setCompaniesList,

        pageIndex,
        setPageIndex,

        searchBar,
        setSearchBar,

        sort,
        setSort,

        pageSize,
        setPageSize,
        resetPagination,

        isAnySelected,
        checkAllRows,
        resetAllRows,
        currentSelectedRows,

        checkRow,
        isRowChecked
    } = useCompanies()

    const {
        data: companySource,
        isLoading,
        error,
        mutate: mutateCompaniesSource
    }
        = useSWR<IData<Company>>(ApiHost + encodeURI(`api/v1/company` +
        `?filter={q:"${searchBar}"}
        &sort=[${sort}]
        &range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]`),
        fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate('/'))
        }
    }, [error]);

    useEffect(() => {
        if (!error && companySource) {
                setCompaniesList(companySource.content);
        }
    }, [companySource]);

    return <div className={'flex flex-col gap-4 h-full'}>
        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Companies</h1>

        <section className={'flex-none text-sm flex justify-between place-items-center'}>
            <div className={'flex grow gap-4 place-items-center'}>
                <Input
                    className={"px-3 py-2 w-[17.25rem] h-8 relative bg-[#ffffff] rounded-lg border border-solid font-subtle font-[number:var(--subtle-font-weight)] text-[#717171] text-[length:var(--subtle-font-size)] tracking-[var(--subtle-letter-spacing)] leading-[var(--subtle-line-height)] whitespace-nowrap [font-style:var(--subtle-font-style)] caret-black"}
                    defaultValue={searchBar}
                    placeholder={`Search company...`}
                    onChange={
                        (event) => {
                            setSearchBar(event.currentTarget.value)
                        }
                    }/>
            </div>
            <Button className={'flex-none'} size={'sm'} onClick={() => navigate(routes.createCompany)}>Add
                company</Button>
        </section>

        <div className={'flex-1 grow'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-11">
                            <Checkbox checked={isAnySelected()}
                                      onCheckedChange={() => checkAllRows(companiesList)}/>
                        </TableHead>

                        <TableHead className={'tableCellHover w-80'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Company</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`companyName,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`companyName,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-80'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Contact name</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`firstName,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`firstName,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-80'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Email</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`email,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`email,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead>Members</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className={`${(isLoading || companySource?.content.length === 0) && 'h-[31.3rem]'}`}>
                    {!isLoading
                        ? companiesList.map((company) => (
                            <TableRow
                                key={company.id}
                                onClick={(e) => navigate(`${routes.companyDetails}/${company.id}`)}
                                className={'cursor-pointer'}
                            >
                                <TableCell>
                                    <Checkbox checked={isRowChecked(company)}
                                              onCheckedChange={() => checkRow(company)}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                              }}
                                    />
                                </TableCell>

                                <TableCell className={'tableCellHover'} onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    navigate(`${routes.companyDetails}/${company.id}`)
                                }}>{getString([{element: company.legalName, tag: ElementTag.name}])}</TableCell>

                                <TableCell>
                                    {getString([
                                        {element: company.firstName, tag: ElementTag.name},
                                        {element: company.lastName, tag: ElementTag.name}])}</TableCell>

                                <TableCell>{getString([{element: company.email, tag: ElementTag.email}])}</TableCell>

                                <TableCell>{company.members.length}</TableCell>

                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(pageSize)} tableRowsCount={4}/>}
                </TableBody>
            </Table>
        </div>

        {!isLoading && <section className={'flex-none flex justify-between'}>
            <p className={'text-sm text-marcoGrayTextColor'}> {currentSelectedRows.length} of {companySource?.totalElements} selected</p>
            <Pagination data={companySource} pageSize={pageSize} pageIndex={pageIndex} setPageSize={setPageSize}
                        setPageIndex={setPageIndex}/>
        </section>}
    </div>
}