import {Button} from "cn/components/ui/button";
import {Checkbox} from "cn/components/ui/checkbox";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "cn/components/ui/dropdown-menu";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import Pagination from "cn/custom/pagination";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import {ArrowDownWideNarrow, ArrowUpNarrowWide} from "lucide-react";
import {getCompanyName} from "pages/main/tables/members/getCompanyName";
import MemberStatusFilter from "pages/main/tables/members/memberStatusFilter";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Company} from "utils/types/primary/Company";
import {IData} from "utils/types/primary/IData";
import {Member} from "utils/types/primary/Member";
import useCompaniesMap from "utils/zustand/primary/useCompaniesMap";
import useMembers from "utils/zustand/tables/useMembers";
import useSheet, {ElementType} from "utils/zustand/useSheet";

export default function Members() {
    const navigate = useNavigate();

    const {
        memberList,
        setMemberList,

        textFilter,

        pageIndex,
        setPageIndex,

        searchBar,
        setSearchBar,

        sort,
        setSort,

        pageSize,
        setPageSize,
        resetPagination,

        isAnySelected,
        checkAllRows,
        resetAllRows,
        currentSelectedRows,

        checkRow,
        isRowChecked
    } = useMembers()

    const {
        data: memberSource,
        mutate: mutateMemberSource,
        error,
        isLoading
    } = useSWR<IData<Member>>(ApiHost + encodeURI(`api/v1/member` +
        `?filter={${textFilter ? `${textFilter}, q:"${searchBar}"` : `q:"${searchBar}"`}}
        &sort=[${sort}]
        &range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]`),
        fetcher);

    const {companies, setCompanies, resetCompanies} = useCompaniesMap()
    const {
        data: companySource,
        error: companySourceError,
        isLoading: companySourceLoading
    }
        = useSWR<IData<Company>>(companies.length > 0 ? ApiHost + encodeURI(`api/v1/company?filter={id:[${companies}]}&range=[0, 29]`) : null, fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate('/'))
        }
    }, [error]);

    useEffect(() => {
        if (companySourceError) {
            handleResponseError(companySourceError, () => navigate('/'))
        }
    }, [companySourceError]);

    useEffect(() => {
        if (!error && memberSource) {
            setMemberList(memberSource.content);
            const companies = memberSource.content.map(el => el.companyId);
            setCompanies(companies)
        }
    }, [memberSource]);

    const {open} = useSheet()

    return <div className={'flex flex-col gap-4 h-full'}>
        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Members</h1>

        <section className={'flex-none text-sm flex justify-between place-items-center'}>
            <div className={'flex grow gap-4 place-items-center'}>
                <Input
                    className={"px-3 py-2 w-[17.25rem] h-8 relative bg-[#ffffff] rounded-lg border border-solid font-subtle font-[number:var(--subtle-font-weight)] text-[#717171] text-[length:var(--subtle-font-size)] tracking-[var(--subtle-letter-spacing)] leading-[var(--subtle-line-height)] whitespace-nowrap [font-style:var(--subtle-font-style)] caret-black"}
                    defaultValue={searchBar}
                    placeholder={`Search member...`}
                    onChange={
                        (event) => {
                            setSearchBar(event.currentTarget.value)
                        }
                    }/>
                <MemberStatusFilter/>
            </div>
            <Button className={'flex-none'} size={'sm'} onClick={() => open(ElementType.member, null, 'Create member', mutateMemberSource)}>Create member</Button>
        </section>

        <div className={'flex-1 grow'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-11">
                            <Checkbox checked={isAnySelected()}
                                      onCheckedChange={() => checkAllRows(memberList)}/>
                        </TableHead>

                        <TableHead className={'tableCellHover w-96'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Name</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`firstName,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`firstName,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-96'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Email</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`email,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`email,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-96'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Company</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`company,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`company,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p className={'tableCellHover'}>Role</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`role,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`role,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className={`${isLoading || memberSource?.content.length === 0 && 'h-[31.3rem]'}`}>
                    {!isLoading
                        ? memberList.map((member) => (
                            <TableRow
                                key={member.id}
                                onClick={(e) => navigate(`${routes.memberDetails}/${member.id}`)}
                                className={'cursor-pointer'}
                            >
                                <TableCell>
                                    <Checkbox checked={isRowChecked(member)}
                                              onCheckedChange={() => checkRow(member)}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                              }}
                                    />
                                </TableCell>

                                <TableCell className={'tableCellHover'} onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    navigate(`${routes.memberDetails}/${member.id}`)
                                }}>
                                    {getString([{
                                        element: member.firstName,
                                        tag: ElementTag.name
                                    }, {element: member.lastName, tag: ElementTag.name}])}
                                </TableCell>

                                <TableCell>
                                    {getString([{element: member.email, tag: ElementTag.name}])}
                                </TableCell>

                                <TableCell className={'tableCellHover'}
                                           onClick={(e) => {
                                               e.stopPropagation();
                                               e.preventDefault();
                                               navigate(`${routes.companyDetails}/${member.companyId}`)
                                           }
                                           }>
                                    {getCompanyName(member, companySource?.content)}
                                </TableCell>

                                <TableCell>{formatEnumValueToString(member.role)}</TableCell>

                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(pageSize)} tableRowsCount={4}/>}
                </TableBody>
            </Table>
        </div>

        {!isLoading && <section className={'flex-none flex justify-between'}>
            <p className={'text-sm text-marcoGrayTextColor'}> {currentSelectedRows.length} of {memberSource?.totalElements} selected</p>
            <Pagination data={memberSource} pageSize={pageSize} pageIndex={pageIndex} setPageSize={setPageSize}
                        setPageIndex={setPageIndex}/>
        </section>}
    </div>
}