import paginationDefaultValues from "utils/config/paginationDefaultValues";
import {PaymentTransaction} from "utils/types/primary/PaymentTransaction";
import {create} from 'zustand'

interface State {
    transactionList: PaymentTransaction[]

    sort: string

    searchBar: string

    pageSize: string
    pageIndex: number

    currentSelectedRows: PaymentTransaction[]
}

interface Actions {
    setTransactionList: (transactionList: PaymentTransaction[]) => void

    setSort: (value: string) => void

    setSearchBar: (value: string) => void

    setPageSize: (pageSize: string) => void
    setPageIndex: (pageIndex: number) => void
    resetPagination: () => void

    isAnySelected: () => boolean
    checkAllRows: (transactions: PaymentTransaction[]) => void
    resetAllRows: () => void

    checkRow: (transaction: PaymentTransaction) => void
    isRowChecked: (transaction: PaymentTransaction) => boolean

    resetFinance: () => void
}

const useFinances = create<State & Actions>(
    (set, get) => ({
        transactionList: [],

        sort: 'createOn, DESC',

        searchBar: '',

        pageIndex: paginationDefaultValues.pageIndex,
        pageSize: paginationDefaultValues.pageSize,

        currentSelectedRows: [],

        resetFinance: () => {
            set(
                (state) => (
                    {
                        transactionList: [],

                        sort: 'createOn, DESC',

                        searchBar: '',

                        pageIndex: paginationDefaultValues.pageIndex,
                        pageSize: paginationDefaultValues.pageSize,
                    }
                )
            )
        },

        resetAllRows: () => {
            set(
                (state) => (
                    {
                        currentSelectedRows: []
                    }
                )
            )
        },

        isAnySelected: () => {
            const currentSelected = useFinances.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        isRowChecked: (transaction: PaymentTransaction) => {
            const currentSelected = useFinances.getState().currentSelectedRows.filter(el => el.id === transaction.id);
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        checkAllRows: (transactions: PaymentTransaction[]) => {
            const currentSelected = useFinances.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                set(
                    (state) => (
                        {
                            currentSelectedRows: []
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, ...transactions]
                        }
                    )
                )
            }
        },

        checkRow: (transaction: PaymentTransaction) => {
            const currentSelected = useFinances.getState().currentSelectedRows.filter(el => el.id === transaction.id);

            if (currentSelected.length > 0) {
                const updatedArr = useFinances.getState().currentSelectedRows.filter(el => el.id !== transaction.id)
                set(
                    (state) => (
                        {
                            currentSelectedRows: updatedArr
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, transaction]
                        }
                    )
                )
            }
        },

        setSort: (value: string) => {
            set(
                (state) => (
                    {
                        sort: value,
                    }
                )
            );
        },

        setTransactionList: (transactionList: PaymentTransaction[]) => {
            set(
                (state) => (
                    {
                        transactionList: transactionList,
                    }
                )
            );
        },

        setSearchBar: (value: string) => {
            set(
                (state) => (
                    {
                        searchBar: value
                    }
                )
            )
        },

        setPageSize: (pageSize: string) => {
            set(
                (state) => (
                    {pageSize: pageSize}
                )
            )
        },

        setPageIndex: (pageIndex: number) => {
            set(
                (state) => (
                    {pageIndex: pageIndex}
                )
            )
        },

        resetPagination: () => {
            set(
                (state) => (
                    {
                        pageSize: paginationDefaultValues.pageSize,
                        pageIndex: paginationDefaultValues.pageIndex
                    }
                )
            )
        }
    })
)

export default useFinances;
