import {FilterItem} from "utils/types/suibsidiary/filterItem";

const memberStatus: FilterItem[] = [
    {
        checked: false,
        filterItemKey: "\"role\":",
        filterItemValue: "[OWNER]",
        filterItemTitle: "Owner",
    },
    {
        checked: false,
        filterItemKey: "\"role\":",
        filterItemValue: "[MANAGER]",
        filterItemTitle: "Manager",
    },
    {
        checked: false,
        filterItemKey: "\"role\":",
        filterItemValue: "[DRIVER]",
        filterItemTitle: "Driver",

    },
]

export default memberStatus