import otherError from "utils/errorHandler/fetchErrors/otherError";
import {ErrorDefined} from "utils/types/primary/ErrorDefined";
import {ErrorMessage} from "utils/types/primary/errorMessage";

type Error401 = {
    code: number
    message: string
    error: string
}

export default async function getDefinedError(response: Response) {
    switch (response.status) {
        case 400:
        case 404: {
            const res: ErrorDefined = await response.json();
            const errorMessage: ErrorMessage = {
                status: res.status.toString(),
                message: res.detail
            }

            console.log(`Error ${res.status} at ${res.instance}. ${res.detail}`)
            return errorMessage
        }

        case 401: {
            const res: Error401 = await response.json();
            const errorMessage: ErrorMessage = {
                status: res.code.toString(),
                message: res.error
            }

            console.log(`Error ${errorMessage.status}: ${errorMessage.message.toLowerCase()}`)
            return errorMessage
        }

        case 500: {
            const res: ErrorDefined = await response.json();

            const errorMessage: ErrorMessage = {
                status: res.status.toString(),
                message: res.detail
            }

            console.log(`Error ${res.status} at ${res.instance}. ${res.detail}`)
            return errorMessage
        }

        default: {
            const errorMessage: ErrorMessage = {
                status: response.status.toString(),
                message: 'Failed to fetch'
            }

            console.log(`Error ${errorMessage.status}. Failed to fetch.`)
            return otherError
        }
    }
}