import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent,
    AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "cn/components/ui/alertDialog";
import {Button} from "cn/components/ui/button";
import {Separator} from "cn/components/ui/separator";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {produce} from "immer";
import {X} from "lucide-react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import updateOrder from "utils/API/updateOrder";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {Company} from "utils/types/primary/Company";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Member} from "utils/types/primary/Member";
import {Order} from "utils/types/primary/Order";
import useSheet, {ElementType} from "utils/zustand/useSheet";
import {ReactComponent as Copy} from "../../../../../icons/copy.svg";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";

interface Props {
    order: Order | undefined
    mutateOrder: () => {}
}

export default function OrderDriver({order, mutateOrder}: Props) {
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)

    const {
        data: memberSource,
        isLoading: memberLoading,
        mutate: mutateMemberSource,
        error: memberSourceError,
    } = useSWR<Member>((order && order.carrierId) ? ApiHost + encodeURI(`api/v1/member/${order.carrierId}`) : null, fetcher);

    useEffect(() => {
        if (memberSourceError) {
            handleResponseError(memberSourceError, () => navigate('/'))
        }
    }, [memberSourceError]);

    const {open} = useSheet();

    async function deleteDriver() {
        if (order) {
            setLoading(true)
            const updatedOrder = produce(
                order,
                (draft) => {
                    if (draft) {
                        draft.carrierId = null
                        draft.invoices = []
                        draft.transactions = []
                    }
                }
            )

            const res = await updateOrder(updatedOrder);

            if (isInstanceOf<ErrorMessage>(res, 'message')){
                handleResponseError(res, () => navigate('/'))
            } else {
                toast({
                    description: 'Request fetched'
                })
            }
            mutateOrder();
            setLoading(false)
        }
    }

    return <section key={'driver '} className={'flex flex-col border rounded-lg'}>

        <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
            <h3 className={'text-sm font-semibold '}>Driver</h3>

            {memberSource
                ? <AlertDialog>
                    <AlertDialogTrigger asChild>
                        <X className={'xDefault h-6 text-marcoSecondaryErrorColor'}/>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                            <AlertDialogDescription>
                                This action cannot be undone. This will delete driver from order.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel disabled={isLoading}>Cancel</AlertDialogCancel>
                            <Button variant={'destructive'} disabled={isLoading} onClick={deleteDriver}>{isLoading ? <ButtonLoading/> : 'Continue'}</Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>

                : <div
                    onClick={() => {
                        if (order) {
                            if (order.carrierCompanyId) {
                                open(ElementType.Driver, order, `Assign driver`, mutateOrder)
                            } else open(ElementType.Company, order, `Assign company and driver`, mutateOrder)
                        }
                    }}
                    className={'grid place-items-center xDefault'}>
                    <Dots/>
                </div>}
        </div>

        {memberSource && <>
            <Separator/>
            <div className={'p-2 flex flex-col text-sm'}>
                <p>{memberSource.firstName}</p>
                <div className={'flex justify-between place-items-center pb-[0.375rem]'}>
                    <p className={'text-marcoLinks'}>{memberSource.email}</p>
                    <div
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${memberSource.email}`
                            );
                            toast({
                                description: 'Email copied to clipboard'
                            })
                        }}
                        className={'xDefault grid place-items-center'}>
                        <Copy/>
                    </div>
                </div>
                <p>{memberSource.phone}</p>
            </div>
        </>}
    </section>
}