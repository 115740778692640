import * as z from "zod";

const companySchema = z.object({
    id: z.string(),

    members: z.array(z.object({
        id: z.string(),
        companyID: z.string(),
        firstName: z.string().min(2),
        lastName: z.string().min(2),
        email: z.string().email(),
        phone: z.string().min(5),
        createOn: z.string(),
        lastUpdate: z.string(),
    })),

    stripeAccount: z.string(),
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    email: z.string().email(),
    phone: z.string().min(5),
    legalName: z.string().min(2),
    companyName: z.string().min(2),
    address1: z.string().min(2),
    address2: z.string().min(1),
    city: z.string().min(2),
    state: z.string().min(2),
    country: z.string().min(2),
    addressPhone: z.string().min(5),
    addressFirstName: z.string().min(2),
    addressLastName: z.string().min(2),
    createOn: z.string(),
    lastUpdate: z.string(),
    dot: z.string().min(7).max(7),
    fmsc: z.string().min(5).max(10),
    ein: z.string().min(10).max(10),
})

export default companySchema