import {Progress} from "cn/components/ui/progress";
import {Toaster} from "cn/components/ui/toaster";
import {toast} from "cn/components/ui/use-toast";
import Dashboard from "cn/custom/dashboard/dashboard";
import Header from "cn/custom/Header";
import jwt from "jwt-decode";
import SheetWrapper from "cn/custom/sheet/sheetWrapper";
import ScreenView from "pages/main/details/orderDetails/sections/media/ScreenView";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import revalidateToken from "utils/API/revalidateToken";
import {defaultDimensions} from "utils/config/defaultDimensions";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Token} from "utils/types/suibsidiary/Token";
import useMenubar from "utils/zustand/tables/useMenubar";
import useProgressBar from "utils/zustand/primary/useProgressBar";
import useTokenStore from "utils/zustand/useTokenStore";
import useViewport from "utils/zustand/useViewport";

interface Props {
    children: React.ReactNode,
}

export default function Layout({children}: Props) {
    const navigate = useNavigate();
    const [flag, setFlag] = useState<boolean>(false);
    const {token, resetToken} = useTokenStore();
    const {width, setDimensions} = useViewport();
    const {checkCurrentURL} = useMenubar();
    const {progressValue} = useProgressBar();

    useEffect(() => {
        if (token) {
            revalidateToken()
                .then(value => {
                    if (isInstanceOf<ErrorMessage>(value, 'message')) {
                        resetToken();
                        navigate('/')
                    } else {
                        const user: Token = jwt(token);
                        const userName = getString([
                            {element: user.full_name, tag: ElementTag.name},
                        ])
                        toast({
                            description: `Authorized as ${userName}`,
                        });
                        setFlag(true)
                    }
                })
        } else {
            resetToken();
            navigate('/')
        }
    }, []);

    useEffect(() => {
        window.addEventListener("resize", setDimensions);
        return () => window.removeEventListener("resize", setDimensions);
    }, []);

    useEffect(() => {
        checkCurrentURL(window.location.pathname)
    }, [children]);

    return <>
        {
            flag && <div className={'h-[100dvh] w-full flex flex-col'}>
                <ScreenView/>

                <div className={'fixed z-10 w-full bg-white'}>
                    <Progress className={"z-50"} value={progressValue}/>
                    <Header/>
                </div>

                <section
                    className={"grow flex"}>
                    {width >= defaultDimensions.lg && <Dashboard/>}
                    <div
                        className={`grow px-6 py-6 mt-[4rem] ${width >= defaultDimensions.lg ? 'ml-60' : ''}`}>
                        <SheetWrapper/>
                        {children}
                    </div>
                </section>
            </div>
        }
        <Toaster/>
    </>
}