export default function getSquareFootage(squareFootage: string) {
    switch (squareFootage) {
        case "SQ_FT_500_1000": {
            return "500-1000 sq. ft."
        }

        case "SQ_FT_1000_2000": {
            return "1000-2000 sq. ft."
        }

        case "SQ_FT_2000_3000": {
            return "2000-3000 sq. ft."
        }

        case "SQ_FT_3000_PLUS": {
            return "3000 sq. ft. and more"
        }
    }
}