import {Button} from "cn/components/ui/button";
import {Checkbox} from "cn/components/ui/checkbox";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "cn/components/ui/dropdown-menu";
import {Input} from "cn/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "cn/components/ui/popover";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import OrderStatusBadge from "cn/custom/badges/orderStatusBadge";
import PaymentStatusBadge from "cn/custom/badges/paymentStatusBadge";
import AlertOnOrderDeleteDialog from "cn/custom/orderActionsContent/AlertOnOrderDeleteDialog";
import Pagination from "cn/custom/pagination";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import {ArrowDownWideNarrow, ArrowUpNarrowWide, ChevronDown} from "lucide-react";
import OrderActionsContent from "cn/custom/orderActionsContent/orderActionsContent";
import {getCompanyName} from "pages/main/tables/ordersTables/components/getCompanyName";
import {getCustomerName} from "pages/main/tables/ordersTables/components/getCustomerName";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import getFixedTwoNumber from "utils/stringHandlers/getFixedTwoNumber";
import stringToFormatDateYear from "utils/stringHandlers/stringToFormatDateYear";
import {Company} from "utils/types/primary/Company";
import {Customer} from "utils/types/primary/Customer";
import {IData} from "utils/types/primary/IData";
import {Order} from "utils/types/primary/Order";
import useCompaniesMap from "utils/zustand/primary/useCompaniesMap";
import useCustomersMap from "utils/zustand/primary/useCustomersMap";
import useOrders from "utils/zustand/tables/useOrders";

export default function Orders() {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const {
        orderList,
        setOrderList,

        pageIndex,
        setPageIndex,

        textFilter,

        searchBar,
        setSearchBar,

        sort,
        setSort,

        pageSize,
        setPageSize,
        resetPagination,

        isAnySelected,
        checkAllRows,
        resetAllRows,
        currentSelectedRows,

        checkRow,
        isRowChecked
    } = useOrders()

    const {customers, setCustomers, resetCustomers} = useCustomersMap();
    const {companies, setCompanies, resetCompanies} = useCompaniesMap()

    const {
        data: orderSource,
        mutate: mutateOrderSource,
        error: orderSourceError,
        isLoading: orderSourceLoading
    }
        = useSWR<IData<Order>>(ApiHost + encodeURI(`api/v1/order` +
        `?filter={status:[DRAFT], q:"${searchBar}"}
        &sort=[${sort}]
        &range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]`),
        fetcher);


    const {
        data: customerSource,
        error: customerSourceError,
        isLoading: customerSourceLoading
    }
        = useSWR<IData<Customer>>(customers.length > 0 ? ApiHost + encodeURI(  `api/v1/customer?filter={id:[${customers}]}&range=[0, 29]` ): null, fetcher);

    const {
        data: companySource,
        error: companySourceError,
        isLoading: companySourceLoading
    }
        = useSWR<IData<Company>>(companies.length > 0 ? ApiHost + encodeURI(  `api/v1/company?filter={id:[${companies}]}&range=[0, 29]` ): null, fetcher);

    useEffect(() => {
        if (orderSourceError) {
            handleResponseError(orderSourceError, () => navigate('/'))
        }
    }, [orderSourceError]);

    useEffect(() => {
        if (customerSourceError) {
            handleResponseError(customerSourceError, () => navigate('/'))
        }
    }, [customerSourceError]);

    useEffect(() => {
        if (companySourceError) {
            handleResponseError(companySourceError, () => navigate('/'))
        }
    }, [companySourceError]);

    useEffect(() => {
        if (!orderSourceError && orderSource) {
            setOrderList(orderSource.content);

            const customers = orderSource.content.map(el => el.user);
            setCustomers(customers)

            const companies = orderSource.content.map(el => el.carrierCompanyId);
            setCompanies(companies)
        }
    }, [orderSource]);

    return <div className={'flex flex-col gap-4 h-full'}>
        <AlertOnOrderDeleteDialog resetOrders={() => resetAllRows()} mutateOrderSource={() => mutateOrderSource()}
                                  orders={currentSelectedRows} setOpen={setDeleteDialogOpen}
                                  isOpen={isDeleteDialogOpen}/>

        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Drafts</h1>

        <section className={'flex-none text-sm flex justify-between place-items-center'}>
            <div className={'flex grow gap-4 place-items-center'}>
                <Input
                    className={"px-3 py-2 w-[17.25rem] h-8 relative bg-[#ffffff] rounded-lg border border-solid font-subtle font-[number:var(--subtle-font-weight)] text-[#717171] text-[length:var(--subtle-font-size)] tracking-[var(--subtle-letter-spacing)] leading-[var(--subtle-line-height)] whitespace-nowrap [font-style:var(--subtle-font-style)] caret-black"}
                    defaultValue={searchBar}
                    placeholder={`Search order...`}
                    onChange={
                        (event) => {
                            setSearchBar(event.currentTarget.value)
                        }
                    }/>
            </div>

            <Popover open={isOpen}>
                <PopoverTrigger>
                    <Button
                        onClick={() => {
                            if (isAnySelected()) {
                                {
                                    setOpen(true)
                                }
                            }
                        }}
                        variant={'outline'}
                        size="sm"
                        className={`${isAnySelected() ? 'text-marcoDefaultTextColor' : 'text-marcoGrayTextColor'} h-8 flex gap-1`}>
                        <span>More actions</span>
                        <ChevronDown className={'h-4'}/>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0" align="end">
                    <OrderActionsContent resetOrders={() => resetAllRows()}
                                         mutateOrderSource={() => mutateOrderSource()}
                                         setDialogOpen={setDeleteDialogOpen} setOpen={setOpen}
                                         orders={currentSelectedRows}/>
                </PopoverContent>
            </Popover>
        </section>

        <div className={'flex-1 grow'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-11">
                            <Checkbox checked={isAnySelected()}
                                      onCheckedChange={() => checkAllRows(orderList)}/>
                        </TableHead>

                        <TableHead className="w-[90px]">
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p className={'tableCellHover'}>Order</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`sId,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`sId,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className="w-[150px]">
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p className={'tableCellHover'}>Date</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`createOn,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide
                                        className={'h-4'}/> <p>Ascending</p></DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`createOn,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>
                        <TableHead className="w-[180px]">Customer</TableHead>
                        <TableHead className="w-[150px]">Estimate</TableHead>
                        <TableHead className="w-[150px]">
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p className={'tableCellHover'}>Distance</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`distance,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide
                                        className={'h-4'}/> <p>Ascending</p></DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`distance,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className="w-[150px]">
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p className={'tableCellHover'}>Volume</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`volume,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide
                                        className={'h-4'}/> <p>Ascending</p></DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`volume,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className="w-[150px]">
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p className={'tableCellHover'}>Payment status</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`paymentStatus,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide
                                        className={'h-4'}/> <p>Ascending</p></DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`paymentStatus,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className="w-[150px]">
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p className={'tableCellHover'}>Order status</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`status,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide
                                        className={'h-4'}/> <p>Ascending</p></DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`status,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead>Service provider </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className={`${(orderSourceLoading || orderSource?.content.length === 0) && 'h-[31.3rem]'}`}>
                    {!orderSourceLoading
                        ? orderList.map((order) => (
                            <TableRow
                                key={order.id}
                                className={'cursor-pointer'}
                                onClick={() => navigate(`${routes.orderDetails}/${order.id}`)}
                            >
                                <TableCell>
                                    <Checkbox checked={isRowChecked(order)}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                              }}
                                              onCheckedChange={() => checkRow(order)}
                                    />
                                </TableCell>
                                <TableCell
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`${routes.orderDetails}/${order.id}`)
                                    }}
                                    className={'tableCellHover'}
                                >#{order.sid}</TableCell>
                                <TableCell>{stringToFormatDateYear(order.createOn)}</TableCell>
                                <TableCell onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`${routes.customerDetails}/${order.user}`)
                                }}
                                           className={'tableCellHover'}>{customerSourceLoading ? <p className={'tableSkeletonCell animate-pulse'}/> : getCustomerName(order, customerSource?.content)}</TableCell>
                                <TableCell>
                                    {"$" + order.invoices.reduce((sum, current) => sum + current.count, 0).toFixed(2)}
                                </TableCell>
                                <TableCell>{getFixedTwoNumber(order.distance) + " mi"}</TableCell>
                                <TableCell>{getFixedTwoNumber(order.volume) + " cu.ft"}</TableCell>
                                <TableCell><PaymentStatusBadge status={order.paymentStatus}/></TableCell>
                                <TableCell><OrderStatusBadge status={order.status}/></TableCell>
                                <TableCell>
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (order.carrierCompanyId) {
                                                navigate(`${routes.companyDetails}/${order.carrierCompanyId}`)
                                            }
                                        }}
                                        className={'tableCellHover'}>
                                        {companySourceLoading ? <p className={'tableSkeletonCell animate-pulse'}/> : getCompanyName(order, companySource?.content)}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(pageSize)} tableRowsCount={9}/>}
                </TableBody>
            </Table>
        </div>

        {!orderSourceLoading && <section className={'flex-none flex justify-between'}>
            <p className={'text-sm text-marcoGrayTextColor'}> {currentSelectedRows.length} of {orderSource?.totalElements} selected</p>
            <Pagination data={orderSource} pageSize={pageSize} pageIndex={pageIndex} setPageSize={setPageSize}
                        setPageIndex={setPageIndex}/>
        </section>}
    </div>
}