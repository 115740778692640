interface Props {
    content: string
}

export default function NoOrders({content}: Props) {
    return <div className={'border rounded-lg w-full py-4 px-4 text-sm'}>
        <div className={'flex flex-col gap-2 pb-4'}>
            <h2 className={'font-semibold'}>No orders yet</h2>
            <p>{content}</p>
        </div>
    </div>
}