import {
    AlertDialog, AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "cn/components/ui/alertDialog";
import {Button} from "cn/components/ui/button";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {useState} from "react";
import deleteOrder from "utils/API/deleteOrder";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import useProgressBar from "utils/zustand/primary/useProgressBar";

interface Props {
    isOpen: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    orders: Order[]
    mutateOrderSource: () => void
    resetOrders: () => void
}

export default function AlertOnOrderDeleteDialog({isOpen, setOpen, orders, mutateOrderSource, resetOrders}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false)
    const {setProgressValue, resetProgressBar} = useProgressBar()

    async function onDeleteHandle() {
        setLoading(true)
        const errorMessage: ErrorMessage[] = []
        const dataLoadPace = Number((100 / orders.length).toFixed(0));

        for (let i = 0; i < orders.length;) {
            const res = await deleteOrder(orders[i]);

            if (res !== 200) {
                errorMessage.push(res)
            }

            setProgressValue(useProgressBar.getState().progressValue + dataLoadPace)
            i++
        }

        if (errorMessage.length > 0) {
            toast({
                variant: 'destructive',
                description: <section className={'flex flex-col gap-2'}>{errorMessage.map(el => <div
                    className={'flex flex-col text-xs font-inter'}>
                    <p>Error {el.status}:</p>
                    <p>{el.message}</p>
                </div>)}
                </section>
            })
        } else {
            toast({
                description: 'Request fetched'
            })
        }
        resetProgressBar();
        resetOrders();
        await mutateOrderSource();
        setLoading(false);
        setOpen(false);
    }

    return <AlertDialog open={isOpen}>
        <AlertDialogContent>
            <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                    This action cannot be undone. This will permanently delete orders and remove data.
                </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel disabled={isLoading} onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
                <Button disabled={isLoading} variant={'destructive'} onClick={onDeleteHandle}>{isLoading ?
                    <ButtonLoading/> : 'Continue'}</Button>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
}