import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {Order} from "utils/types/primary/Order";
import useTokenStore from "utils/zustand/useTokenStore";

export default async function deleteOrder(order: Order) {
    const token = useTokenStore.getState().token

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/${order.id}`), {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            return 200
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}