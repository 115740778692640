import {Button} from "cn/components/ui/button";
import {Checkbox} from "cn/components/ui/checkbox";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "cn/components/ui/dropdown-menu";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import Pagination from "cn/custom/pagination";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import {ArrowDownWideNarrow, ArrowUpNarrowWide} from "lucide-react";
import moment from "moment/moment";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {Application} from "utils/types/primary/Application";
import {IData} from "utils/types/primary/IData";
import useApplications from "utils/zustand/tables/useApplications";


export default function Applications() {
    const navigate = useNavigate();

    const {
        applicationsList,
        setApplicationsList,

        pageIndex,
        setPageIndex,

        searchBar,
        setSearchBar,

        sort,
        setSort,

        pageSize,
        setPageSize,
        resetPagination,

        isAnySelected,
        checkAllRows,
        currentSelectedRows,

        checkRow,
        isRowChecked
    } = useApplications()

    const {
        data: applicationsSource,
        isLoading,
        error,
        mutate: mutateApplicationSource
    }
        = useSWR<IData<Application>>(ApiHost + encodeURI(`api/v1/application` +
        `?filter={q:"${searchBar}"}
        &sort=[${sort}]
        &range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]`),
        fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate('/'))
        }
    }, [error]);

    useEffect(() => {
        if (!error && applicationsSource) {
            setApplicationsList(applicationsSource.content);
        }
    }, [applicationsSource]);


    return <div className={'flex flex-col gap-4 h-full'}>
        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Applications</h1>

        <section className={'flex-none text-sm flex justify-between place-items-center'}>
            <div className={'flex grow gap-4 place-items-center'}>
                <Input
                    className={"px-3 py-2 w-[17.25rem] h-8 relative bg-[#ffffff] rounded-lg border border-solid font-subtle font-[number:var(--subtle-font-weight)] text-[#717171] text-[length:var(--subtle-font-size)] tracking-[var(--subtle-letter-spacing)] leading-[var(--subtle-line-height)] whitespace-nowrap [font-style:var(--subtle-font-style)] caret-black"}
                    defaultValue={searchBar}
                    placeholder={`Search company...`}
                    onChange={
                        (event) => {
                            setSearchBar(event.currentTarget.value)
                        }
                    }/>
            </div>
        </section>

        <div className={'flex-1 grow'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-11">
                            <Checkbox checked={isAnySelected()}
                                      onCheckedChange={() => checkAllRows(applicationsList)}/>
                        </TableHead>

                        <TableHead className={'tableCellHover w-80'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Company name</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`firstName, ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`firstName,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead>First name</TableHead>
                        <TableHead>Last name</TableHead>
                        <TableHead>Email</TableHead>
                        <TableHead>Phone</TableHead>
                        <TableHead>mc</TableHead>
                        <TableHead>dot</TableHead>
                        <TableHead className={'tableCellHover w-80'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Created on</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`createOn, ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`createOn, DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                    </TableRow>
                </TableHeader>
                <TableBody className={`${(isLoading || applicationsSource?.content.length === 0) && 'h-[31.3rem]'}`}>
                    {!isLoading
                        ? applicationsList.map((application) => (
                            <TableRow
                                key={application.id}
                                onClick={(e) => navigate(`${routes.applications}/${application.id}`)}
                                className={'cursor-pointer'}
                            >
                                <TableCell>
                                    <Checkbox checked={isRowChecked(application)}
                                              onCheckedChange={() => checkRow(application)}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                              }}
                                    />
                                </TableCell>

                                <TableCell>{application.companyName}</TableCell>
                                <TableCell>{application.firstName}</TableCell>
                                <TableCell>{application.lastName}</TableCell>
                                <TableCell>{application.email}</TableCell>
                                <TableCell>{application.phone}</TableCell>
                                <TableCell>{application.mc}</TableCell>
                                <TableCell>{application.dot}</TableCell>
                                <TableCell>{moment(application.createdOn).format('DD.MM.YYYY')}</TableCell>

                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(pageSize)} tableRowsCount={4}/>}
                </TableBody>
            </Table>
        </div>

        {!isLoading && <section className={'flex-none flex justify-between'}>
            <p className={'text-sm text-marcoGrayTextColor'}> {currentSelectedRows.length} of {applicationsSource?.totalElements} selected</p>
            <Pagination data={applicationsSource} pageSize={pageSize} pageIndex={pageIndex} setPageSize={setPageSize}
                        setPageIndex={setPageIndex}/>
        </section>}
    </div>
}