import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Company} from "utils/types/primary/Company";
import {Member} from "utils/types/primary/Member";

export function getCompanyName(member: Member, companies: Company[] | undefined) {
    if (companies) {
        const company = companies.filter(el => el.id === member.companyId)
        if (company[0]) {
            const companyName = getString([{element: company[0].legalName, tag: ElementTag.name}])
            return companyName
        }
    }
}