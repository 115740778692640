import * as z from "zod";

const addressSchema = z.object({
    id: z.string(),
    orderId: z.string(),
    userId: z.string(),
    direction: z.string(),
    address: z.string().min(2),
    errors: z.string().optional(),
    state: z.string().optional(),
    city: z.string().optional(),
    zip: z.string().optional(),
    country: z.string().optional(),
    unitNumber: z.string().optional(),


    floorNumber: z.string(),
    access: z.string().min(2, {message: "Mandatory field"}),
    propertyType: z.string().nullable(),
    numberStories: z.string().nullable(),
    squareFootage: z.string().nullable(),
    unitSize: z.string().nullable(),
    propertySize: z.string().nullable(),
})

export default addressSchema