import { FilterItem } from "utils/types/suibsidiary/filterItem"

const ratesFilterValues: FilterItem[] = [
    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[AK]",
        filterItemTitle: "Alaska",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[Al]",
        filterItemTitle: "Alabama",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[AR]",
        filterItemTitle: "Arkansas",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[AZ]",
        filterItemTitle: "Arizona",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[CA]",
        filterItemTitle: "California",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[CO]",
        filterItemTitle: "Colorado",

    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[CT]",
        filterItemTitle: "Connecticut",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[DE]",
        filterItemTitle: "Delaware",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[DC]",
        filterItemTitle: "District of Columbia",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[FL]",
        filterItemTitle: "Florida",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[GA]",
        filterItemTitle: "Georgia",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[GU]",
        filterItemTitle: "Guam",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[HI]",
        filterItemTitle: "Hawaii",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[ID]",
        filterItemTitle: "Idaho",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[IL]",
        filterItemTitle: "Illinois",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[IN]",
        filterItemTitle: "Indiana",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[IA]",
        filterItemTitle: "Iowa",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[KS]",
        filterItemTitle: "Kansas",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[KY]",
        filterItemTitle: "Kentucky",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[LA]",
        filterItemTitle: "Louisiana",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[ME]",
        filterItemTitle: "Maine",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[MD]",
        filterItemTitle: "Maryland",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[MA]",
        filterItemTitle: "Massachusetts",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[MI]",
        filterItemTitle: "Michigan",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[MN]",
        filterItemTitle: "Minnesota",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[MS]",
        filterItemTitle: "Mississippi",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[MO]",
        filterItemTitle: "Missouri",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[MT]",
        filterItemTitle: "Montana",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[NE]",
        filterItemTitle: "Nebraska",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[NV]",
        filterItemTitle: "Nevada",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[NH]",
        filterItemTitle: "New Hampshire",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[NJ]",
        filterItemTitle: "New Jersey",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[NM]",
        filterItemTitle: "New Mexico",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[NY]",
        filterItemTitle: "New York",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[NC]",
        filterItemTitle: "North Carolina",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[ND]",
        filterItemTitle: "North Dakota",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[OH]",
        filterItemTitle: "Ohio",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[OK]",
        filterItemTitle: "Oklahoma",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[OR]",
        filterItemTitle: "Oregon",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[PA]",
        filterItemTitle: "Pennsylvania",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[PR]",
        filterItemTitle: "Puerto Rico",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[RI]",
        filterItemTitle: "Rhode Island",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[SC]",
        filterItemTitle: "South Carolina",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[SD]",
        filterItemTitle: "South Dakota",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[TN]",
        filterItemTitle: "Tennessee",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[TX]",
        filterItemTitle: "Texas",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[UT]",
        filterItemTitle: "Utah",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[VT]",
        filterItemTitle: "Vermont",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[VI]",
        filterItemTitle: "Virgin Islands",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[VA]",
        filterItemTitle: "Virginia",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[WA]",
        filterItemTitle: "Washington",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[WV]",
        filterItemTitle: "West Virginia",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[WI]",
        filterItemTitle: "Wisconsin",
    },

    {
        checked: false,
        filterItemKey: "\"state\":",
        filterItemValue: "[WY]",
        filterItemTitle: "Wyoming",
    },

]

export default ratesFilterValues